// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../components/estateBrokerHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SettleTable from "../../../../components/Tables/SettleTable";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import { useHistory } from "react-router-dom";

const GET_ESTATEBROKEROFFICES = gql`
  query GET_ESTATEBROKEROFFICES($id: ID!) {
    realEstateBroker(id: $id) {
      name
      offices {
        id
        name
        address {
          fullAddress
        }
        cvr
        email
        realEstateBroker {
          id
        }
      }
    }
  }
`;

function RealEstateBrokerOffices() {
  // Chakra color mode
  const history = useHistory();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { estateBrokerId } = useParams();
  const { loading, error, data } = useQuery(GET_ESTATEBROKEROFFICES, {
    variables: { id: estateBrokerId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Adresse",
      accessor: "address.fullAddress",
      onClick: rowClicked,
    },
    {
      header: "Email",
      accessor: "email",
      onClick: rowClicked,
    },
    {
      header: "CVR",
      accessor: "cvr",
      onClick: rowClicked,
    },
    {
      header: "realEstateBrokerId",
      accessor: "realEstateBroker.id",
      hidden: true,
    },
  ];

  function rowClicked(obj) {
    const estateBrokerId = obj["realEstateBroker.id"];
    const estateBrokerOfficeId = obj.id;
    history.push("/admin/ejendomsmaegler/" + estateBrokerId + "/office/" + estateBrokerOfficeId + "/info");
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.realEstateBroker.name}
        description={"Butikker"}
        view={"offices"}
        estateBrokerId={estateBrokerId}
      />
      <Card px="0px">
        <CardBody>
          <SettleTable data={data.realEstateBroker.offices} columnsData={columns} />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default RealEstateBrokerOffices;
