// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Spacer,
  Button,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import IconBox from "./../../../../components/Icons/IconBox";
import { DocumentIcon } from "./../../../../components/Icons/Icons";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { TbTrashX } from "react-icons/tb";
import React, { useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";

const uploadFileGql = gql`
  mutation uploadAffiliatePartnerProductDoc($input: UploadAffiliatePartnerProductDocInput!) {
    uploadAffiliatePartnerProductDoc(input: $input)
  }
`;

const deleteFileGql = gql`
  mutation deleteAffiliatePartnerProductDoc($input: DeleteAffiliatePartnerProductDocInput!) {
    deleteAffiliatePartnerProductDoc(input: $input)
  }
`;

const AffiliatePartnerProductDocument = ({ data, forceRerender }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [deleteDocId, setdeleteDocId] = useState("");
  const [uploadFile, { loading: loadingUpload, data: dataUpload }] = useMutation(uploadFileGql);
  const [deleteFile, { loading: loadingDelete, data: dataDelete }] = useMutation(deleteFileGql);
  const inputRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue("linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)", "gray.800");
  const iconBoxInside = useColorModeValue("white", "white");

  if (!loadingUpload && dataUpload?.uploadAffiliatePartnerDoc) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  if (!loadingDelete && dataDelete) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleDeleteDocClick = (id) => {
    setdeleteDocId(id);
    onOpen();
  };

  const deleteDoc = () => {
    onClose();

    const inputData = {
      affiliatePartnerProductId: data.id,
      docId: deleteDocId,
    };

    deleteFile({ variables: { input: inputData } });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  async function handleFileChange(e) {
    if (!e.target.files) {
      return;
    }

    const fileBase64 = await toBase64(e.target.files[0]);

    const inputData = {
      affiliatePartnerProductId: data.id,
      documentType: "AGREEMENT",
      fileName: e.target.files[0].name,
      fileType: e.target.files[0].type,
      FileContentBase64: fileBase64,
    };

    uploadFile({ variables: { input: inputData } });
  }

  const downloadDoc = (docUrl) => {
    window.open(docUrl);
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Fuldmagt ved bestilling
        </Text>
        <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleUploadClick()}>
          Upload
        </Text>
        <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        {data.powerOfAttorneyDocument && (
          <Stack direction="column" spacing="24px" w="100%" style={{ paddingTop: "10px" }}>
            <Flex align="center" w="100%">
              <Flex align="center">
                <IconBox as="box" h={"40px"} w={"40px"} bg={bgButton} me="18px">
                  <DocumentIcon h={"20px"} w={"20px"} color={iconBoxInside} />
                </IconBox>
                <Flex direction="column" cursor="pointer" onClick={() => downloadDoc(data.powerOfAttorneyDocument.orgDocStorageUrl)}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    {data.powerOfAttorneyDocument.filename}
                  </Text>
                  <Text color="gray.400" fontSize="xs">
                    {data.powerOfAttorneyDocument.filename}
                  </Text>
                </Flex>
              </Flex>
              <Spacer />
              <Button variant="transparent-with-icon" px="0px">
                <Icon as={TbTrashX} color="gray.400" w="30px" h="30px" cursor="pointer" onClick={() => handleDeleteDocClick(data.powerOfAttorneyDocument.id)} />
              </Button>
            </Flex>
          </Stack>
        )}
      </CardBody>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteDoc}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet dokument
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteDoc} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default AffiliatePartnerProductDocument;
