import { __rest } from "tslib";
import classNames from 'classnames';
import './typography.global.scss';
import React, { forwardRef } from 'react';
const Typography = forwardRef((_a, ref) => {
    var { children, tag = 'span', tagStyle, upperCase, subjectColor, className, elipsis, alignRight } = _a, props = __rest(_a, ["children", "tag", "tagStyle", "upperCase", "subjectColor", "className", "elipsis", "alignRight"]);
    const Tag = tag;
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    React.createElement(Tag //@ts-ignore
    , Object.assign({ ref: ref, className: classNames(tagStyle, className, subjectColor, {
            'align-right': alignRight,
            elipsis,
            uppercase: upperCase,
        }) }, props), children));
});
Typography.displayName = 'Typography';
export default Typography;
