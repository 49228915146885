// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React, { useState } from "react";
import LeadInfoHeader from "./../components/leadInfoHeader";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import LeadCustomerInfo from "./../components/leadCustomerInfo";
import LeadInfo from "./../components/leadInfo";
import LeadLog from "./../components/leadLog";
import LeadDocuments from "./../components/leadDocuments";
import LeadNotes from "./../components/leadNotes";
import Editor from "./../../../../components/Editor/Editor";

const GET_LEAD = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      status
      processingCount
      nextProcessingDate
      createdAt
      updatedAt
      externalRef
      externalOrderId
      note
      affiliatePartnerProduct {
        name
        affiliatePartner {
          name
        }
      }
      billingItem {
        id
      }
      movingDetails {
        id
        customerType
        moveToAddress {
          fullAddress
        }
        takeoverDate
      }
      customer {
        #customerType
        cvr
        email
        externalId
        id
        isCompany
        #moveToAddress{
        #    fullAddress
        #}
        name
        phone
        #takeoverDate
      }
      documents {
        id
        filename
        orgDocStorageUrl
      }
      logs {
        id
        created
        activity
        type
      }
    }
  }
`;

const declineLeadGql = gql`
  mutation cancelLead($input: CancelLeadInput!) {
    cancelLead(input: $input)
  }
`;

const approveLeadGql = gql`
  mutation approveLead($input: ApproveLeadInput!) {
    approveLead(input: $input)
  }
`;

const updateLeadGql = gql`
  mutation updateLead($input: UpdateLead!) {
    updateLead(input: $input) {
      id
    }
  }
`;

function Info() {
  // Chakra color mode
  const { isOpen: declineLeadIsOpen, onOpen: declineLeadOnOpen, onClose: declineLeadOnClose } = useDisclosure();
  const { isOpen: approveLeadIsOpen, onOpen: approveLeadOnOpen, onClose: approveLeadOnClose } = useDisclosure();
  const { isOpen: sentUpdateLeadIsOpen, onOpen: sentUpdateLeadOnOpen, onClose: sentUpdateLeadOnClose } = useDisclosure();
  const [sentMailToPartner, setSentMailToPartner] = useState(false);

  const [mailText, setmailText] = useState('');
  //const editorRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { leadId } = useParams();
  const [declineLeadMutation] = useMutation(declineLeadGql, {
    refetchQueries: [GET_LEAD],
    variables: { id: leadId },
  });
  const [approveLeadMutation] = useMutation(approveLeadGql, {
    refetchQueries: [GET_LEAD],
    variables: { id: leadId },
  });
  const [updateLeadMutation] = useMutation(updateLeadGql, {
    refetchQueries: [GET_LEAD],
    variables: { id: leadId },
  });
  const { loading, error, data, refetch } = useQuery(
    GET_LEAD,
    {
      variables: { id: leadId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  function forceRerender() {
    refetch();
  }

  const handelApproveLeadCliced = () => {
    approveLeadOnOpen();
  };

  const handelDeclineLeadClicked = () => {
    setSentMailToPartner(false);
    declineLeadOnOpen();
  };

  const approveLead = () => {
    approveLeadOnClose();

    let inputData = {};
    inputData.leadId = data.lead.id;

    approveLeadMutation({ variables: { input: inputData } });

    //setTimeout(() => {
    //  location.reload()
    //}, 1000);
  };

  const declineLead = () => {
    declineLeadOnClose();

    let inputData = {};
    inputData.leadId = data.lead.id;
    inputData.sentMailToPartner = sentMailToPartner;
    inputData.partnerMailText = mailText; // editorRef.current?.state.editorHtml;

    declineLeadMutation({ variables: { input: inputData } });

    //setTimeout(() => {
    //  location.reload()
    //}, 1000);
  };

  const sentUpdateLeadClicked = () => {
    sentUpdateLeadOnOpen();
  };

  const sentUpdateLead = () => {
    sentUpdateLeadOnClose();

    let inputData = {};
    inputData.id = data.lead.id;
    inputData.status = "UPDATED";

    updateLeadMutation({ variables: { input: inputData } });
  };

  var timeout = null;
  const updateText = (text) => {
    clearTimeout(timeout);

    if (text.trim() !== "") {
      timeout = setTimeout(function () {
        setmailText(text);
      }, 2000);
    }
  };

  const mailTemplate =
    `<p>Hej  ` +
    data?.lead?.affiliatePartnerProduct?.affiliatePartner.name +
    `</p>
  <p>&nbsp;</p>
  <p>Vi har modtaget en henvendelse om at annullere følgende lead:</p>
  <p>
  <b>Produkt:</b> ` +
    data?.lead?.affiliatePartnerProduct?.name +
    `<br/>
  <b>Kunde navn:</b> ` +
    data?.lead?.customer?.name +
    `<br/>
  <b>Adresse:</b> ` +
    data?.lead?.movingDetails?.moveToAddress.fullAddress +
    `<br/>
  <b>E-mail:</b> ` +
    data?.lead?.customer?.email +
    `<br/>
  <b>Reference:</b> ` +
    data?.lead?.externalOrderId +
    `
  </p>

  <p>Vil i søge for at annullere den hos jer også?</p>
  <br/>
  <p><a href="https://admin.settle.dk/#/handlers/LeadCanceledConfirmation/` +
    data?.lead?.id +
    `">Bekræft venligst ved at klikke her</a></p>
  <br/>
  
  <p>På forhånd tak.</p>

  <p><span style='color:#999999'>Med venlig hilsen</span><br/>
  <span style='color:#999999'>Settle - Support</span></p>
   <p><span style='font-size:13.5pt;color:#00B050'>Settle</span><br/>
  <span style='color:#999999'>Kannikegade 4-6, 2.sal | 8000 AArhus</span></p>
`;

if (mailText === ''){
  setmailText(mailTemplate);
}

  return (
    <Flex direction="column">
      <LeadInfoHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={"Lead - " + data.lead.customer.name}
        description={"Overblik"}
        view={"info"}
        acceptLead={handelApproveLeadCliced}
        declineLead={handelDeclineLeadClicked}
        sentUpdateLead={sentUpdateLeadClicked}
      />

      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(4, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap="22px"
      >
        <GridItem w="100%" colSpan={2} rowSpan={1}>
          <LeadInfo data={data.lead} />
        </GridItem>
        <GridItem w="100%" colSpan={2} rowSpan={1}>
          <LeadCustomerInfo data={data.lead} forceRerender={forceRerender} />
        </GridItem>
        <GridItem w="100%" colSpan={2} rowSpan={1}>
          <LeadDocuments leadData={data.lead} forceRerender={forceRerender} />
        </GridItem>
        <GridItem w="100%" colSpan={2} rowSpan={1}>
          <LeadNotes data={data.lead} />
        </GridItem>
        <GridItem w="100%" colSpan={4} rowSpan={1}>
          <LeadLog data={data.lead} />
        </GridItem>
      </Grid>

      <Modal onClose={declineLeadOnClose} size="xl" isOpen={declineLeadIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Annullere lead</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" style={{ width: "100%", height: "100%" }}>
              <Flex align="center" mb="18px">
                Du er ved at annullere et lead, er du sikke på at du ønsker at gøre dette? (Ved at annullere lead´et, bliver faktureringsgrundlaget
                fjernet.)
                <br />
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
                  Send mail til Partner:{" "}
                </Text>
                <Checkbox onChange={(e) => setSentMailToPartner(e.target.checked)}></Checkbox>
                {sentMailToPartner}
              </Flex>
              {sentMailToPartner && (
                <Flex align="center" mb="18px">
                  <Editor value={mailTemplate} changed={(e) => (updateText(e))} />
                </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={declineLead}>Annullere lead</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={approveLeadOnClose} size="xl" isOpen={approveLeadIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Godkend lead</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" style={{ width: "100%", height: "100%" }}>
              <Flex align="center" mb="18px">
                Du er ved at godkende et lead, er du sikke på at du ønsker at gøre dette? (Ved at godkende lead´et, bliver det tilføjet til
                faktureringsgrundlag for denne måned.)
                <br />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={approveLead}>Godkend lead</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={sentUpdateLeadOnClose} size="xl" isOpen={sentUpdateLeadIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gensend lead</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" style={{ width: "100%", height: "100%" }}>
              <Flex align="center" mb="18px">
                Du er ved at gensende lead´et til {data?.lead?.affiliatePartnerProduct?.affiliatePartner.name}, er du sikke på at du ønsker at gøre
                dette?
                <br />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={sentUpdateLead}>Gensend lead</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
export default Info;
