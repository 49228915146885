import { Flex, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import Header from "./../../../../components/Headers/Header";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import MovingPlannerCategoriesContainer from "./MovingPlannerItemContainer";
import { useParams } from "react-router-dom";
import { GET_PLANNER_ITEMS_GQL, CREATE_ITEM_GQL, DELETE_ITEM_GQL, UPDATE_ITEM_SORTING_GQL } from "./graphQL";
import { useHistory } from "react-router-dom";

function MovingPlannerItemRenderer() {
  const { categoryId } = useParams();
  const history = useHistory();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { loading, error, data } = useQuery(GET_PLANNER_ITEMS_GQL, {
    variables: { id: categoryId },
    fetchPolicy: "network-only",
  });

  const [createItem] = useMutation(CREATE_ITEM_GQL, { refetchQueries: [GET_PLANNER_ITEMS_GQL] });
  const [deleteItem] = useMutation(DELETE_ITEM_GQL, { refetchQueries: [GET_PLANNER_ITEMS_GQL] });
  const [updateItemSorting] = useMutation(UPDATE_ITEM_SORTING_GQL, { refetchQueries: [GET_PLANNER_ITEMS_GQL] });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting data from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting data from server (2)</p>;
  }

  const createNewItemfn = (itemName) => {
    createItem({
      variables: {
        input: {
          heading: itemName,
          subHeading: "",
          text: "",
          plannerItemsCategoryId: categoryId,
        },
      },
    });
  };

  const deleteItemfn = (itemId) => {
    deleteItem({
      variables: {
        id: itemId,
      },
    });
  };

  const updateSortingfn = (items) => {
    var result = items.map((item) => ({ id: item.id, sorting: item.sorting }));
    updateItemSorting({
      variables: {
        input: result,
      },
    });
  };

  const editItemfn = (itemId) => {
    history.push("/admin/flytteplanner/" + categoryId + "/punkt/" + itemId);
  };

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Flytte-planner - Elementer"}
        description={"Flytte-planner pr. ejendomsmægler kæde"}
      />

      <MovingPlannerCategoriesContainer
        data={data}
        updateSorting={updateSortingfn}
        deleteItem={deleteItemfn}
        createNewItem={createNewItemfn}
        editItem={editItemfn}
      ></MovingPlannerCategoriesContainer>
    </Flex>
  );
}

export default MovingPlannerItemRenderer;
