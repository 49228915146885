// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useHistory } from "react-router-dom";

const GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL = gql`
  query GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL($id: ID!) {
    plannerItemProductCategory(id: $id) {
      id
      links {
        id
        text
        url
        sorting
      }
    }
  }
`;

const CREATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL = gql`
  mutation createPlannerItemProductCategoryLink($input: CreatePlannerItemProductCategoryLinkInput!) {
    createPlannerItemProductCategoryLink(input: $input) {
      id
    }
  }
`;

const DELETE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL = gql`
  mutation deletePlannerItemProductCategoryLink($id: ID!) {
    deletePlannerItemProductCategoryLink(id: $id)
  }
`;

const UPDATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_SORTING_GQL = gql`
  mutation updatePlannerItemProductCategoryLinkSorting($input: [updatePlannerItemProductCategoryLinkSorting]) {
    updatePlannerItemProductCategoryLinkSorting(input: $input)
  }
`;

const MovingPlannerItemProductCategoryLinks = ({ plannerCategoryId, plannerItemId, plannerItemProductCategoryId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [seletedRow, setSeletedRow] = React.useState("");
  const cancelRefCreateDialog = React.useRef();
  const cancelRefDeleteDialog = React.useRef();
  const newLinkTextRef = React.useRef();
  const newLinkUrlRef = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const history = useHistory();

  const [createPlannerItemLink] = useMutation(CREATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL, {
    refetchQueries: [GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL],
  });
  const [deletePlannerItemLink] = useMutation(DELETE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL, {
    refetchQueries: [GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL],
  });
  const [updatePlannerItemLinkSorting] = useMutation(UPDATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_SORTING_GQL, {
    refetchQueries: [GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL],
  });

  const { loading, error, data } = useQuery(
    GET_PLANNER_ITEM_PRODUCT_CATEGORY_GQL,
    {
      variables: { id: plannerItemProductCategoryId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting item links from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting item links from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Tekst",
      accessor: "text",
      onClick: rowClicked,
    },
    {
      header: "Url",
      accessor: "url",
      onClick: rowClicked,
    },
    {
      header: "Redigere",
      accessor: "1",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: EditClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: DeleteClicked,
    },
  ];

  function rowClicked(obj) {
    EditClicked(obj);
  }

  function EditClicked(obj) {
    history.push(
      "/admin/flytteplanner/" + plannerCategoryId + "/punkt/" + plannerItemId + "/productCategory/" + plannerItemProductCategoryId + "/link/" + obj.id
    );
  }

  function DeleteClicked(obj) {
    setSeletedRow(obj);
    onOpenDeleteDialog();
  }

  const deleteItemLink = () => {
    deletePlannerItemLink({
      variables: {
        id: seletedRow.id,
      },
    });
    onCloseDeleteDialog();
  };

  const setTableData = (obj) => {
    var tableData = obj(data.plannerItemProductCategory.links);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((link) => ({ id: link.id, sorting: link.sorting }));
    updatePlannerItemLinkSorting({
      variables: {
        input: result,
      },
    });
  };

  const showCreateModal = () => {
    onOpenCreateDialog();
  };

  const createPlannerItemLinkClicked = () => {
    let inputData = {};
    inputData.text = newLinkTextRef.current.value;
    inputData.url = newLinkUrlRef.current.value;
    inputData.plannerItemProductCategoryId = plannerItemProductCategoryId;

    createPlannerItemLink({
      variables: { input: inputData },
    });

    onCloseCreateDialog();
  };

  return (
    <div style={{ width: "100%" }}>
      <Card p="16px" my={{ sm: "24px", xl: "20px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Links
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize="lg"
              color="gray.400"
              cursor="pointer"
              mb="6px"
              onClick={() => {
                showCreateModal();
              }}
            >
              Tilføj
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <SettleTable data={data.plannerItemProductCategory.links} columnsData={columns} setDragData={setTableData} />
        </CardBody>
      </Card>

      <AlertDialog isOpen={isOpenDeleteDialog} leastDestructiveRef={cancelRefDeleteDialog} onClose={onCloseDeleteDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet link.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefDeleteDialog} onClick={onCloseDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteItemLink} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret nyt link.
            </AlertDialogHeader>
            <AlertDialogBody>
              Du er ved at oprette et nyt link.
              <br />
              <br />
              Angiv navn:
              <Input ref={newLinkTextRef} type="text" name="text" defaultValue="" />
              <br />
              Angiv url:
              <Input ref={newLinkUrlRef} type="text" name="url" defaultValue="" />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={createPlannerItemLinkClicked} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default MovingPlannerItemProductCategoryLinks;
