// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateRealEstateBrokerOfficeInfoGql = gql`
  mutation updateRealEstateBrokerOfficeInfo($input: UpdateRealEstateBrokerOffice!) {
    updateRealEstateBrokerOffice(input: $input) {
      id
    }
  }
`;

const EstateBrokerOfficeBankAccountInfo = ({ data }) => {
  const toast = useToast();
  const [updateRealEstateBrokerOfficeInfo] = useMutation(updateRealEstateBrokerOfficeInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (realEstateBrokerOfficeId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = realEstateBrokerOfficeId;
    updateRealEstateBrokerOfficeInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Mæglerkæde
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Bank:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.realEstateBrokerOffice.bankName}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "bankName", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Reg. Nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="phone"
              defaultValue={data.realEstateBrokerOffice.bankRegistrationNumber}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "bankRegistrationNumber", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Konto Nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="email"
              defaultValue={data.realEstateBrokerOffice.bankAccountNumber}
              onBlur={(e) => updateData(data.realEstateBrokerOffice.id, "bankAccountNumber", e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EstateBrokerOfficeBankAccountInfo;
