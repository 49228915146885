// Chakra imports
import { Box, Button, Flex, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { FaCube, FaPenFancy, FaRegEnvelope, FaSignInAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const Header = ({ backgroundHeader, backgroundProfile, name, description, view, estateBrokerId, estateBrokerOfficeId, onSentWellcomesMessageClicked, loginAsUrl }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue("white", "rgba(255, 255, 255, 0.31)");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  let state = {
    overview: true,
    teams: false,
    offices: false,
  };

  switch (view) {
    case "info":
      state.overview = true;
      state.teams = false;
      state.offices = false;
      break;
    case "offices":
      state.overview = false;
      state.teams = false;
      state.offices = true;
      break;
    default:
      break;
  }

  const history = useHistory();

  const navigationLead = function (realestatebrokerofficeid) {
    history.push("/admin/leadsoffice/" + realestatebrokerofficeid);
  };

  const navigation = (estateBrokerId, estateBrokerOfficeId, view) => {
    console.log("We need to get the details for ", estateBrokerId);
    history.push("/admin/ejendomsmaegler/" + estateBrokerId + "/office/" + estateBrokerOfficeId + "/" + view);
  };

  return (
    <Box
      mb={{ sm: "205px", md: "75px", xl: "70px" }}
      borderRadius="15px"
      px="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        bgImage={backgroundHeader}
        w="100%"
        h="200px"
        borderRadius="25px"
        bgPosition="50%"
        bgRepeat="no-repeat"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx="1.5rem"
          maxH="330px"
          w={{ sm: "90%", xl: "95%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align="center"
          backdropFilter="saturate(200%) blur(50px)"
          position="absolute"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="2px solid"
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p="24px"
          borderRadius="20px"
          transform={{
            sm: "translateY(45%)",
            md: "translateY(70%)",
            lg: "translateY(70%)",
          }}
        >
          <Flex
            align="center"
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}
          >
            <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
              <Text fontSize={{ sm: "lg", lg: "xl" }} color={textColor} fontWeight="bold" ms={{ sm: "8px", md: "0px" }}>
                {name}
              </Text>
              <Text fontSize={{ sm: "sm", md: "md" }} color={emailColor} fontWeight="semibold">
                {description}
              </Text>
            </Flex>
          </Flex>
          <Flex direction={{ sm: "column", lg: "row" }} w={{ sm: "100%", md: "50%", lg: "auto" }}>
            <Button p="0px" bg="transparent" _hover={{ bg: "none" }} variant="transparent-with-icon">
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                bg={state.overview ? "hsla(0,0%,100%,.7)" : null}
                boxShadow={state.overview ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null}
                borderColor={state.overview ? "black" : null}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() => navigation(estateBrokerId, estateBrokerOfficeId, "info")}
              >
                <Icon as={FaCube} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Overblik
                </Text>
              </Flex>
            </Button>

            <Button p="0px" bg="transparent" _hover={{ bg: "none" }} variant="transparent-with-icon">
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                bg={state.offices ? "hsla(0,0%,100%,.7)" : null}
                boxShadow={state.offices ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)" : null}
                borderColor={state.offices ? "black" : null}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() => navigationLead(estateBrokerOfficeId)}
              >
                <Icon as={FaPenFancy} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Leads
                </Text>
              </Flex>
            </Button>
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button p="0px" bg="transparent" _hover={{ bg: "none" }} variant="transparent-with-icon">
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "135px" }}
                  bg={null}
                  boxShadow={null}
                  borderColor={null}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                >
                  <a href={loginAsUrl} target="_blank" rel="noreferrer" style={{ display: 'inline-flex' }}>
                    <Icon as={FaSignInAlt} me="6px" />
                    <Text fontSize="xs" color={textColor} fontWeight="bold">
                      Login som mægler
                </Text>
                  </a>
                </Flex>
              </Button>
            </Flex>
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button p="0px" bg="transparent" _hover={{ bg: "none" }} variant="transparent-with-icon">
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "220px" }}
                  bg={null}
                  boxShadow={null}
                  borderColor={null}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={() =>
                    onSentWellcomesMessageClicked()
                  }
                >
                  <Icon as={FaRegEnvelope} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    Send velkomstbesked
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
