import { __awaiter } from "tslib";
import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import ItemCollapsible from './components/itemCollapsible/itemCollapsible';
import ItemContent from './components/itemContent/itemContent';
import ProductCategoryChooser from './components/productCategoryChooser/productCategoryChooser';
import { MapToInternalTypes } from '../../typesMapper';
//import CustomerItemAdd from './components/customerItemAdd/customerItemAdd'
//import CustomerItem from './components/customerItem/customerItem'
import DataValidator from './components/DataValidator/DataValidator';
import { cancelLeadsGql, 
//createCustomerItemGql,
createLeadsGql, 
//updateCustomerItemGql,
updateCustomerMovingDetailsGql, updatePlannerItemGql, } from './graphQL';
import TopBanner from './components/TopBanner/TopBanner';
//import RootCollapsibleV2 from './components/rootCollapsibleV2/RootCollapsibleV2'
import ConsentOverlay from './components/consentOverlay/consentOverlay';
import ProductOrderedOverlay from './components/productOrderedOverlay/productOrderedOverlay';
import RootMenu from './components/rootMenu/RootMenu';
import Footer from './components/footer/Footer';
import { datastore } from '../../dataStore';
const Settle_Planner = (props) => {
    const [selectedRealEstateCase, setselectedRealEstateCase] = useState(0);
    const [userDataId, setUserDataId] = useState(null);
    const [showConsentOverlay, setShowConsentOverlay] = useState(false);
    const [showProductOrderedOverlay, setShowProductOrderedOverlay] = useState(false);
    const [orderedProducts, setOrderedProducts] = useState([]);
    const [rootCollapsibleStates, setRootCollapsibleStates] = useState(null);
    //const [createCustomerItemMutation, { error: createCustomerItemError }] = useMutation(createCustomerItemGql, {})
    //const [updateCustomerItemMutation, { error: updateCustomerItemError }] = useMutation(updateCustomerItemGql, {})
    const [createLeadsMutation] = useMutation(createLeadsGql, {});
    const [cancelLeadMutation] = useMutation(cancelLeadsGql, {});
    const [updatePlannerItemMutation] = useMutation(updatePlannerItemGql, {});
    const [updateCustomerMovingDetailsMutation] = useMutation(updateCustomerMovingDetailsGql, {});
    //hack to set left timeline with the correct position and size
    setInterval(() => {
        //const timeLineElm = document.querySelector('.timeline') as HTMLDivElement
        /*
        if (window.innerWidth > 1023) {
          const itemCollapsibleCheckBoxContainerElm = document.querySelector(
            '.itemCollapsibleCheckBoxContainer',
          ) as HTMLDivElement
          timeLineElm.style.display = ''
          const itemsContainerElm = document.querySelector('.itemsContainer') as HTMLDivElement
    
          //left
          timeLineElm.style.left = itemCollapsibleCheckBoxContainerElm.getBoundingClientRect().left + 9 + 'px'
    
          //top
          timeLineElm.style.top = itemsContainerElm.getBoundingClientRect().top + 40 + window.scrollY + 'px'
    
          //height
          timeLineElm.style.height = itemsContainerElm.getBoundingClientRect().height - 200 + 'px'
        } else {
          //console.log('display none')
          timeLineElm.style.display = 'none'
        }
        */
        const firstMenuItemX = document
            .getElementsByClassName('rootCollapsibleCheckBoxContainer')[0]
            .getBoundingClientRect().x;
        const lastMenuItemNo = document.getElementsByClassName('rootCollapsibleCheckBoxContainer').length - 1;
        const lastMenuItem = document.getElementsByClassName('rootCollapsibleCheckBoxContainer')[lastMenuItemNo];
        const lastMenuItemX = lastMenuItem.getBoundingClientRect().x;
        const lineLength = lastMenuItemX - firstMenuItemX;
        document.getElementsByClassName('masterline')[0].style.width = lineLength + 'px';
    }, 10);
    //hack to support prop change from adminsite preview
    if (props.userData.id !== userDataId) {
        setRootCollapsibleStates(null);
    }
    const data = MapToInternalTypes(props.userData);
    if (!rootCollapsibleStates) {
        setUserDataId(props.userData.id);
        setRootCollapsibleStates(data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.plannerCategories);
    }
    if (!rootCollapsibleStates) {
        return null;
    }
    const DateChanged = (field, value) => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.id = data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.id;
        inputData[field] = value;
        yield updateCustomerMovingDetailsMutation({ variables: { input: inputData } });
        props.forceReRender();
    });
    const handleEstateChange = (selectedEstateNo) => __awaiter(void 0, void 0, void 0, function* () {
        setselectedRealEstateCase(selectedEstateNo);
        setRootCollapsibleStates(data.realEstateBrokerCases[selectedEstateNo].movingDetails.plannerCategories);
    });
    const handleItemCollapsibleChange = (id, isOpen) => {
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            rootCollapsibleStates[r].isEditMode = false;
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                if (rootCollapsibleStates[r].items[i].id == id) {
                    rootCollapsibleStates[r].items[i].isOpen = isOpen;
                    if (isOpen) {
                        localStorage.setItem('menuItem', id);
                    }
                    else {
                        if (localStorage.getItem('menuItem')) {
                            localStorage.removeItem('menuItem');
                        }
                    }
                }
                else {
                    rootCollapsibleStates[r].items[i].isOpen = false;
                }
            }
        }
        setRootCollapsibleStates([...rootCollapsibleStates]);
    };
    const handleRootItemCollapsibleChange = (id, isOpen) => {
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            rootCollapsibleStates[r].isEditMode = false;
            if (rootCollapsibleStates[r].id == id) {
                rootCollapsibleStates[r].isOpen = isOpen;
                if (isOpen) {
                    localStorage.setItem('menuRootItem', id);
                }
                else {
                    if (localStorage.getItem('menuRootItem')) {
                        localStorage.removeItem('menuRootItem');
                    }
                }
            }
            else {
                rootCollapsibleStates[r].isOpen = false;
            }
        }
        setRootCollapsibleStates([...rootCollapsibleStates]);
    };
    const handleProductCategoryItemDoneChange = (id, isCompleted) => __awaiter(void 0, void 0, void 0, function* () {
        //console.log('id: ' + id + ' - isOpen: ' + isCompleted)
        let currentRootItemId = '';
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                for (let p = 0; p < rootCollapsibleStates[r].items[i].productCategories.length; p++) {
                    if (rootCollapsibleStates[r].items[i].productCategories[p].id == id) {
                        currentRootItemId = rootCollapsibleStates[r].items[i].id;
                        rootCollapsibleStates[r].items[i].productCategories[p].completed = isCompleted;
                    }
                }
            }
        }
        let allCompleted = true;
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                for (let p = 0; p < rootCollapsibleStates[r].items[i].productCategories.length; p++) {
                    if (rootCollapsibleStates[r].items[i].productCategories[p].completed == false &&
                        rootCollapsibleStates[r].items[i].id == currentRootItemId) {
                        allCompleted = false;
                    }
                }
            }
        }
        if (allCompleted) {
            handleItemCollapsibleDoneChange(currentRootItemId, true);
        }
        const inputData = {};
        inputData.productCategoryId = id;
        inputData.movingDetailsId = data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.id;
        inputData.completed = isCompleted;
        const result = yield updatePlannerItemMutation({ variables: { input: inputData } });
        if (result) {
            setRootCollapsibleStates([...rootCollapsibleStates]);
        }
    });
    const handleItemCollapsibleDoneChange = (id, isCompleted) => __awaiter(void 0, void 0, void 0, function* () {
        //console.log('id: ' + id + ' - isOpen: ' + isCompleted)
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                if (rootCollapsibleStates[r].items[i].id == id) {
                    rootCollapsibleStates[r].items[i].completed = isCompleted;
                }
            }
        }
        const inputData = {};
        inputData.plannerItemId = id;
        inputData.movingDetailsId = data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.id;
        inputData.completed = isCompleted;
        const result = yield updatePlannerItemMutation({ variables: { input: inputData } });
        if (result) {
            setRootCollapsibleStates([...rootCollapsibleStates]);
        }
    });
    /*
    const handleCustomItemCollapsibleDoneChange = async (id: string, isCompleted: boolean) => {
      //console.log('id: ' + id + ' - isOpen: ' + isCompleted)
      for (let r = 0; r < rootCollapsibleStates.length; r++) {
        for (let i = 0; i < rootCollapsibleStates[r].customerItems.length; i++) {
          if (rootCollapsibleStates[r].customerItems[i].id == id) {
            rootCollapsibleStates[r].customerItems[i].completed = isCompleted
          }
        }
      }
   
      const inputData: any = {}
      inputData.customerPlannerItemCustomId = id
      inputData.completed = isCompleted
   
      const result = await updateCustomerItemMutation({ variables: { input: inputData } })
   
      if (result) {
        setRootCollapsibleStates([...rootCollapsibleStates])
      }
    }
   
    const handleRootCollapsibleEditChange = (id: string, isEditMode: boolean) => {
      for (let i = 0; i < rootCollapsibleStates.length; i++) {
        if (rootCollapsibleStates[i].id == id) {
          rootCollapsibleStates[i].isEditMode = isEditMode
        } else {
          rootCollapsibleStates[i].isEditMode = false
        }
   
        for (let a = 0; a < rootCollapsibleStates[i].items.length; a++) {
          rootCollapsibleStates[i].items[a].isOpen = false
        }
   
        localStorage.removeItem('menuItem')
      }
   
      setRootCollapsibleStates([...rootCollapsibleStates])
    }
    */
    const handleItemCollapsibleEditChange = (id, isRemoved) => __awaiter(void 0, void 0, void 0, function* () {
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                if (rootCollapsibleStates[r].items[i].id == id) {
                    rootCollapsibleStates[r].items[i].isRemoved = isRemoved;
                }
            }
        }
        const inputData = {};
        inputData.plannerItemId = id;
        inputData.customerId = data.id;
        inputData.estateCaseId = data.realEstateBrokerCases[selectedRealEstateCase].id;
        inputData.removed = isRemoved;
        const result = yield updatePlannerItemMutation({ variables: { input: inputData } });
        if (result) {
            setRootCollapsibleStates([...rootCollapsibleStates]);
        }
    });
    /*
    const handleCustomItemCollapsibleEditChange = async (id: string, isRemoved: boolean) => {
      for (let r = 0; r < rootCollapsibleStates.length; r++) {
        for (let i = 0; i < rootCollapsibleStates[r].customerItems.length; i++) {
          if (rootCollapsibleStates[r].customerItems[i].id == id) {
            rootCollapsibleStates[r].customerItems[i].isRemoved = isRemoved
          }
        }
      }
   
      const inputData: any = {}
      inputData.customerPlannerItemCustomId = id
      inputData.removed = isRemoved
   
      const result = await updateCustomerItemMutation({ variables: { input: inputData } })
   
      if (result) {
        setRootCollapsibleStates([...rootCollapsibleStates])
      }
    }
    */
    /*
    const handleUpdateCustomerItem = async (heading: string, text: string, id: string) => {
      for (let r = 0; r < rootCollapsibleStates.length; r++) {
        for (let i = 0; i < rootCollapsibleStates[r].customerItems.length; i++) {
          if (rootCollapsibleStates[r].customerItems[i].id == id) {
            rootCollapsibleStates[r].customerItems[i].heading = heading
            rootCollapsibleStates[r].customerItems[i].text = text
          }
        }
      }
   
      const inputData: any = {}
      inputData.customerPlannerItemCustomId = id
      inputData.heading = heading
      inputData.text = text
   
      const result = await updateCustomerItemMutation({ variables: { input: inputData } })
   
      if (result) {
        setRootCollapsibleStates([...rootCollapsibleStates])
      }
    }
    */
    /*
    const handleCreateCustomerItem = async (heading: string, text: string, categoryId: string) => {
      const inputData: any = {}
      inputData.heading = heading
      inputData.text = text
      inputData.customerMovingDetailsId = data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.id
      inputData.plannerItemsCategoryId = categoryId
   
      const newCustomerItem = await createCustomerItemMutation({ variables: { input: inputData } })
   
      if (newCustomerItem) {
        const customerItem = {
          id: newCustomerItem.data.createCustomerPlannerItemCustom.id,
          heading: newCustomerItem.data.createCustomerPlannerItemCustom.heading,
          text: newCustomerItem.data.createCustomerPlannerItemCustom.text,
          completed: newCustomerItem.data.createCustomerPlannerItemCustom.completed,
          isRemoved: newCustomerItem.data.createCustomerPlannerItemCustom.removed,
        } as CustomerItemType
   
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
          if (rootCollapsibleStates[r].id == categoryId) {
            rootCollapsibleStates[r].customerItems.push(customerItem)
            setRootCollapsibleStates([...rootCollapsibleStates])
            break
          }
        }
      }
    }
    */
    const handleConsentOverlayCloseClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowConsentOverlay(false);
    });
    const handleProductOrderedOverlayCloseClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowProductOrderedOverlay(false);
    });
    const handleDisabkedItemClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        //console.log('showing overlay')
        setShowConsentOverlay(true);
    });
    const handleCancelLeads = (leadId) => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.leadId = leadId;
        inputData.movingDetailsId = data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.id;
        const leadCanceled = yield cancelLeadMutation({ variables: { input: inputData } });
        if (leadCanceled) {
            for (let pc = 0; pc < rootCollapsibleStates.length; pc++) {
                for (let i = 0; i < rootCollapsibleStates[pc].items.length; i++) {
                    for (let proC = 0; proC < rootCollapsibleStates[pc].items[i].productCategories.length; proC++) {
                        for (let p = 0; p < rootCollapsibleStates[pc].items[i].productCategories[proC].products.length; p++) {
                            if (rootCollapsibleStates[pc].items[i].productCategories[proC].products[p].leadId == leadId) {
                                rootCollapsibleStates[pc].items[i].productCategories[proC].products[p].ordered = false;
                            }
                        }
                    }
                }
            }
        }
    });
    const handleNextClicked = (id) => {
        /*
        for (let pc = 0; pc < rootCollapsibleStates.length; pc++) {
          for (let i = 0; i < rootCollapsibleStates[pc].items.length; i++) {
            if (rootCollapsibleStates[pc].items[i].id == id) {
              if (i < rootCollapsibleStates[pc].items.length - 1) {
                //find next visible/active item
                for (let a = i + 1; a < rootCollapsibleStates[pc].items.length; a++) {
                  console.log(
                    'item name: ' +
                      rootCollapsibleStates[pc].items[a].heading +
                      ' - removed:' +
                      rootCollapsibleStates[pc].items[a].isRemoved,
                  )
                  if (rootCollapsibleStates[pc].items[a].isRemoved == false) {
                    console.log(
                      'FOUND - item name: ' +
                        rootCollapsibleStates[pc].items[a].heading +
                        ' - removed:' +
                        rootCollapsibleStates[pc].items[a].isRemoved,
                    )
                    handleItemCollapsibleChange(rootCollapsibleStates[pc].items[a].id, true)
                    break
                  }
                }
              }
            }
          }
        }
        */
        handleItemCollapsibleDoneChange(id, true);
    };
    const islastItem = (id) => {
        let isLast = true;
        for (let pc = 0; pc < rootCollapsibleStates.length; pc++) {
            for (let i = 0; i < rootCollapsibleStates[pc].items.length; i++) {
                if (rootCollapsibleStates[pc].items[i].id == id) {
                    if (i < rootCollapsibleStates[pc].items.length - 1) {
                        isLast = false;
                    }
                }
            }
        }
        return isLast;
    };
    const handleCreateLeads = (productIds) => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.estateCaseId = data.realEstateBrokerCases[selectedRealEstateCase].id;
        inputData.customerId = data.id;
        inputData.productIds = productIds;
        const leads = yield createLeadsMutation({ variables: { input: inputData } });
        const orderedProducts = [];
        if (leads) {
            for (let l = 0; l < leads.data.createLeads.length; l++) {
                for (let pc = 0; pc < rootCollapsibleStates.length; pc++) {
                    for (let i = 0; i < rootCollapsibleStates[pc].items.length; i++) {
                        for (let proC = 0; proC < rootCollapsibleStates[pc].items[i].productCategories.length; proC++) {
                            for (let p = 0; p < rootCollapsibleStates[pc].items[i].productCategories[proC].products.length; p++) {
                                if (rootCollapsibleStates[pc].items[i].productCategories[proC].products[p].id ==
                                    leads.data.createLeads[l].productId) {
                                    rootCollapsibleStates[pc].items[i].productCategories[proC].products[p].ordered = true;
                                    rootCollapsibleStates[pc].items[i].productCategories[proC].products[p].leadId =
                                        leads.data.createLeads[l].id;
                                    orderedProducts.push(rootCollapsibleStates[pc].items[i].productCategories[proC].products[p]);
                                }
                            }
                        }
                    }
                }
            }
            setOrderedProducts(orderedProducts);
            setShowProductOrderedOverlay(true);
        }
    });
    //Set last item open (start where you left)
    const itemStateId = localStorage.getItem('menuItem');
    if (itemStateId) {
        for (let r = 0; r < rootCollapsibleStates.length; r++) {
            for (let i = 0; i < rootCollapsibleStates[r].items.length; i++) {
                if (rootCollapsibleStates[r].items[i].id == itemStateId && rootCollapsibleStates[r].items[i].isOpen == false) {
                    handleItemCollapsibleChange(itemStateId, true);
                }
            }
        }
    }
    const findOpenRootItem = () => {
        for (let pc = 0; pc < rootCollapsibleStates.length; pc++) {
            if (rootCollapsibleStates[pc].isOpen) {
                return rootCollapsibleStates[pc];
            }
        }
        rootCollapsibleStates[0].isOpen = true;
        return rootCollapsibleStates[0];
    };
    const d = findOpenRootItem();
    //console.log('d', d)
    return (React.createElement(React.Fragment, null,
        React.createElement(Fragment, null,
            React.createElement(DataValidator, { data: data }),
            showConsentOverlay && React.createElement(ConsentOverlay, { data: data, closeClicked: handleConsentOverlayCloseClicked }),
            showProductOrderedOverlay && (React.createElement(ProductOrderedOverlay, { products: orderedProducts, closeClicked: handleProductOrderedOverlayCloseClicked })),
            React.createElement(TopBanner, { dateChanged: DateChanged, estateChanged: handleEstateChange, selectedEstateNo: selectedRealEstateCase, data: data, imageUrlDesktop: datastore.data.topBannerUrl }),
            React.createElement("div", { className: 'rootItem', key: 'rootItem' },
                React.createElement(RootMenu, { data: rootCollapsibleStates, onChange: handleRootItemCollapsibleChange }, d.items.map((item) => (React.createElement(ItemCollapsible, { isEditMode: d.isEditMode, data: item, key: item.id, onChange: handleItemCollapsibleChange, onDoneChange: handleItemCollapsibleDoneChange, onEditChange: handleItemCollapsibleEditChange, marketingConsent: data.hasConsent, onDisabledItemClicked: handleDisabkedItemClicked, onNext: () => {
                        handleNextClicked(item.id);
                    }, handoverId: data.realEstateBrokerCases[selectedRealEstateCase].movingDetails.handover.id },
                    React.createElement(ItemContent, { data: item, onNext: () => {
                            handleNextClicked(item.id);
                        } },
                        React.createElement(ProductCategoryChooser, { title: item.productCategoriesTitle, data: item.productCategories, onCreateLeads: handleCreateLeads, onCancelLead: handleCancelLeads, onNext: () => {
                                handleNextClicked(item.id);
                            }, onProductCategoryChange: handleProductCategoryItemDoneChange, lastItem: islastItem(item.id), customerId: data.id, estateCaseId: data.realEstateBrokerCases[selectedRealEstateCase].id }))))))),
            React.createElement("div", { className: 'timeline' }),
            React.createElement(Footer, null))));
};
/*
{d.customerItems.map((customerItems) => (
  <CustomerItem
    data={customerItems}
    key={customerItems.id}
    isEditMode={d.isEditMode}
    onDoneChange={handleCustomItemCollapsibleDoneChange}
    onEditChange={handleCustomItemCollapsibleEditChange}
    itemChanged={handleUpdateCustomerItem}
  />
))}
<CustomerItemAdd itemAdded={handleCreateCustomerItem} categoryId={d.id} />
*/
export default Settle_Planner;
