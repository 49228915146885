import { gql } from '@apollo/client';
const updateCustomerCaseInfoGql = gql `
  mutation updateCustomer($input: updateCustomerInput!) {
    updateCustomer(input: $input)
  }
`;
const closeCustomerAccountGql = gql `
  mutation closeCustomerAccount($input: closeCustomerCustomerInput!) {
    closeCustomerAccount(input: $input)
  }
`;
const updateCustomerItemGql = gql `
  mutation updateCustomerPlannerItemCustom($input: updateCustomerPlannerItemCustomInput!) {
    updateCustomerPlannerItemCustom(input: $input)
  }
`;
const createCustomerItemGql = gql `
  mutation createCustomerPlannerItemCustom($input: createCustomerPlannerItemCustomInput!) {
    createCustomerPlannerItemCustom(input: $input) {
      id
      heading
      text
      removed
      completed
    }
  }
`;
const cancelLeadsGql = gql `
  mutation cancelLead($input: cancelLeadInput!) {
    cancelLead(input: $input)
  }
`;
const createLeadsGql = gql `
  mutation createLeads($input: createLeadsInput!) {
    createLeads(input: $input) {
      id
      productId
    }
  }
`;
const createPowerOfAttorneyDocGql = gql `
  mutation createPowerOfAttorneyDoc($input: createPowerOfAttorneyDocInput!) {
    createPowerOfAttorneyDoc(input: $input) {
      id
      url
    }
  }
`;
const updatePlannerItemGql = gql `
  mutation updateCustomerPlannerItem($input: updateCustomerPlannerItemInput!) {
    updateCustomerPlannerItem(input: $input)
  }
`;
const updateCustomerMovingDetailsGql = gql `
  mutation updateCustomerMovingDetails($input: UpdateCustomerMovingDetailsInput!) {
    updateCustomerMovingDetails(input: $input) {
      id
    }
  }
`;
//}
export { updateCustomerItemGql, createCustomerItemGql, cancelLeadsGql, createLeadsGql, updatePlannerItemGql, updateCustomerMovingDetailsGql, updateCustomerCaseInfoGql, createPowerOfAttorneyDocGql, closeCustomerAccountGql, };
