import React from 'react';
import Typography from '../../../../components/atoms/typography/typography';
import './RootMenu.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
const RootMenu = ({ children, data, onChange }) => {
    // const [selectedItem, setSelectedItem] = useState<RootCollapsibleType>(selected)
    const handleRootItemClicked = (id, htmlElm) => {
        document
            .getElementsByClassName('renderRootCallapsibleColumnClass')[0]
            .scrollTo({ left: htmlElm.offsetLeft - 70, behavior: 'smooth' });
        onChange(id, true);
    };
    const isRootItemComplete = (rootItem) => {
        let isComplete = true;
        for (let i = 0; i < rootItem.items.length; i++) {
            if (!rootItem.items[i].isRemoved) {
                if (rootItem.items[i].completed == false) {
                    isComplete = false;
                    break;
                }
            }
        }
        return isComplete;
    };
    const getTitleHtml = (rootItem) => {
        let title = rootItem.title;
        const splitTitle = rootItem.title.split(' ');
        for (let i = 0; i < splitTitle.length; i++) {
            if (i == 0) {
                title = '<b>' + splitTitle[i] + '</b>';
            }
            else {
                title = title + ' ' + splitTitle[i];
            }
        }
        let completedItems = 0;
        for (let s = 0; s < rootItem.items.length; s++) {
            if (rootItem.items[s].completed) {
                completedItems = completedItems + 1;
            }
        }
        title = title + '<div class="rootCallapsibleStatus">' + completedItems + '/' + rootItem.items.length + '</div>';
        return title;
    };
    const itemClass = (itemNo) => {
        if (itemNo == 0) {
            return 'itemFirst';
        }
        if (itemNo == data.length - 1) {
            return 'itemLast';
        }
        return 'item';
    };
    return (React.createElement(Container, { className: 'rootCollapsibleTimeLine', paddingTopReset: true, style: { paddingBottom: '0', position: 'relative' } },
        React.createElement(Row, { className: 'RootCallapsibleRow' },
            React.createElement(Column, { className: 'renderRootCallapsibleColumnClass', offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 6 } },
                React.createElement("div", { className: 'rootMenu' }, data.length == 1 ? (React.createElement("div", { className: 'singleitem', key: data[0].id },
                    React.createElement("div", { className: 'rootCollapsibleCheckBoxContainer', style: { marginTop: '4px' }, key: data[0].id },
                        React.createElement("div", { className: 'iconSpacing' }, isRootItemComplete(data[0]) ? (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                            React.createElement("rect", { width: '32', height: '32', rx: '16', className: 'svg-color' }),
                            React.createElement("path", { d: 'M24 11.8133L22.12 9.91992L13.3333 18.7066L9.89333 15.2799L8 17.1599L13.3333 22.4799L18.6667 17.1466L24 11.8133Z', fill: 'white' }),
                            React.createElement("path", { d: 'M22.12 9.91992L24 11.8133L18.6667 17.1466L13.3333 22.4799L8 17.1599L9.89333 15.2799L13.3333 18.7066L22.12 9.91992Z', fill: 'white' }))) : (React.createElement(React.Fragment, null, data[0].isOpen ? (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                            React.createElement("rect", { x: '1', y: '1', width: '25', height: '25', rx: '15', className: 'svg-color-stroke', strokeWidth: '1' }),
                            React.createElement("rect", { x: '7.5', y: '7.5', width: '12', height: '12', rx: '15', className: 'svg-color', strokeWidth: '2' }))) : (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                            React.createElement("rect", { x: '7.5', y: '7.5', width: '12', height: '12', rx: '15', fill: '#58595e', strokeWidth: '2' }))))))),
                    React.createElement("div", null,
                        React.createElement(Typography, { tag: 'p', tagStyle: 'bodyLarge', style: { margin: '0', fontSize: '20px', whiteSpace: 'nowrap' }, dangerouslySetInnerHTML: { __html: getTitleHtml(data[0]) } }),
                        React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', style: { margin: '0' }, dangerouslySetInnerHTML: { __html: data[0].description } })))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'masterline' }),
                    data.map((item, index) => (React.createElement("div", { key: item.id, className: 'itemContainer' },
                        React.createElement("div", { className: itemClass(index), key: item.id, onClick: (e) => {
                                handleRootItemClicked(item.id, e.currentTarget);
                            } },
                            React.createElement("div", null,
                                React.createElement(Typography, { tag: 'p', tagStyle: 'bodyLarge', style: { margin: '0', fontSize: '20px', whiteSpace: 'nowrap' }, dangerouslySetInnerHTML: { __html: getTitleHtml(item) } }),
                                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', style: { margin: '0' }, dangerouslySetInnerHTML: { __html: item.description } })),
                            React.createElement("div", { className: 'rootCollapsibleCheckBoxContainer', key: item.id },
                                React.createElement("div", { className: 'iconSpacing' },
                                    isRootItemComplete(item) ? (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                                        React.createElement("rect", { width: '32', height: '32', rx: '16', className: 'svg-color' }),
                                        React.createElement("path", { d: 'M24 11.8133L22.12 9.91992L13.3333 18.7066L9.89333 15.2799L8 17.1599L13.3333 22.4799L18.6667 17.1466L24 11.8133Z', fill: 'white' }),
                                        React.createElement("path", { d: 'M22.12 9.91992L24 11.8133L18.6667 17.1466L13.3333 22.4799L8 17.1599L9.89333 15.2799L13.3333 18.7066L22.12 9.91992Z', fill: 'white' }))) : (React.createElement(React.Fragment, null, item.isOpen ? (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                                        React.createElement("rect", { x: '1', y: '1', width: '25', height: '25', rx: '15', className: 'svg-color-stroke', strokeWidth: '1' }),
                                        React.createElement("rect", { x: '7.5', y: '7.5', width: '12', height: '12', rx: '15', className: 'svg-color', strokeWidth: '2' }))) : (React.createElement("svg", { width: '27', height: '27', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                                        React.createElement("rect", { x: '7.5', y: '7.5', width: '12', height: '12', rx: '15', fill: '#58595e', strokeWidth: '2' }))))),
                                    item.isOpen ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: 'itemMarkerSpacer' }),
                                        React.createElement("div", { className: 'itemMarker' }))) : (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: 'itemMarkerSpacer' }),
                                        React.createElement("div", { className: 'itemMarkerHidden' })))))),
                        index != data.length - 1 && React.createElement("div", { className: 'line' }))))))))),
        data.length > 1 && (React.createElement(Row, { style: { marginTop: '0px' } },
            React.createElement(Column, { offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 6 } },
                React.createElement("div", { className: 'row itemCallapsibleRow', style: { paddingTop: '10px', paddingBottom: '10px' } })))),
        React.createElement(Row, { style: { marginTop: '-19px' } },
            React.createElement(Column, { offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 6 } },
                React.createElement("div", { className: 'itemsContainer' },
                    children,
                    React.createElement("br", null),
                    React.createElement("br", null))))));
};
export default RootMenu;
