// Chakra imports
import { Flex, Grid, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useQuery, gql } from "@apollo/client";
// assets

// Custom icons
import { CartIcon, DocumentIcon, GlobeIcon, WalletIcon } from "./../../../components/Icons/Icons.js";

import MiniStatistics from "./components/MiniStatistics";
import LeadsByEstateBroker from "./components/LeadsByEstateBroker";
import LeadsCurrentWeek from "./components/LeadsCurrentWeek";
import LeadprMonth from "./components/LeadprMonth";

const GET_DASHBOARD_INFO = gql`
  query GET_DASHBOARD_INFO {
    newLeadsToDay
    leadsAwaitingApproval
    leadsApprovedOneMonthBack
    leadsDeclinedOneMonthBack
    leadsInErrorState
    leadsPrRealEstateBrokerOfficeOneMonthBack {
      name
      count
    }
    leadsReceivedPrMonth {
      month
      count
    }
    leadsCurrentWeek {
      date
      count
    }
  }
`;

const Dashboard = () => {
  const iconBoxInside = useColorModeValue("white", "white");
  const { loading, error, data } = useQuery(GET_DASHBOARD_INFO, {
    variables: {},
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }
  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }


  console.log('process.env.PUBLIC_URL:',process.env.REACT_APP_API_URL)


  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing="24px">
        <MiniStatistics
          title={"Nye leads idag"}
          amount={data.newLeadsToDay}
          percentage={null}
          icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Godkendte leads den sidste måned"}
          amount={data.leadsApprovedOneMonthBack}
          percentage={null}
          icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Afviste leads den sidste måned"}
          amount={data.leadsDeclinedOneMonthBack}
          percentage={null}
          icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Leads afventer godkendelse"}
          amount={data.leadsAwaitingApproval}
          percentage={null}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Leads der er fejlet"}
          amount={data.leadsInErrorState}
          percentage={null}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>

      <Grid templateColumns={{ sm: "2fr", xl: "repeat(2, 2fr)" }} gap="24px" my="26px" mb={{ lg: "26px" }}>
        <LeadprMonth data={data.leadsReceivedPrMonth} />
        <LeadsCurrentWeek data={data.leadsCurrentWeek} />
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr" }} templateRows={{ sm: "1fr", md: "1fr", lg: "1fr" }} gap="24px">
        <LeadsByEstateBroker data={data.leadsPrRealEstateBrokerOfficeOneMonthBack} />
      </Grid>
    </Flex>
  );
};
export default Dashboard;
