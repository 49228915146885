// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast, Input, Text } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "./movingPlannerCategoryItemHeader";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL, UPDATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL } from "./MovingPlannerItemProductCategoryLinkGraphQL";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import MovingPlannerItemProductCategoryLinkLogoUpload from "./MovingPlannerItemProductCategoryLinkLogoUpload";
import { useHistory } from "react-router-dom";

function MovingPlannerItemLinkEdit() {
  // Chakra color mode
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const history = useHistory();

  const { categoryId, itemId,productCategoryId, productCategoryLinkId } = useParams();
  const [updatePlannerItemLink] = useMutation(UPDATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL, { refetchQueries: [GET_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL] });
  const { loading, error, data, refetch } = useQuery(GET_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL, {
    variables: { id: productCategoryLinkId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting planner item from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting planner item from server (2)</p>;
  }

  const updateData = (id, field, data) => {
    let inputData = JSON.parse('{"' + field + '":""}');

    inputData[field] = data;
    inputData.id = id;
    updatePlannerItemLink({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  /*
  var timeout = null;
  const updateText = (text) => {
    clearTimeout(timeout);

    timeout = setTimeout(function () {
      updateData(itemId, "text", text);
    }, 2000);
  };
  */

  function backClicked() {
    history.push("/admin/flytteplanner/" + categoryId + "/punkt/" + itemId + "/productkategory/" + productCategoryId);
  }


  function forceRerender() {
    refetch();
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.plannerItemProductCategoryLink.text}
        description={""}
        view={"info"}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} gap="22px">
        <GridItem w="100%" colSpan={0} rowSpan={0}>
          <Text fontSize="sm" color={textColor} onClick={backClicked} style={{cursor: "pointer", paddingLeft: "20px" }}>
            Tilbage til punktet
          </Text>
          <br/>
          <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Link
              </Text>
              
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Tekst:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="heading"
                    defaultValue={data.plannerItemProductCategoryLink.text}
                    onBlur={(e) => updateData(data.plannerItemProductCategoryLink.id, "text", e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Url:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="subHeading"
                    defaultValue={data.plannerItemProductCategoryLink.url}
                    onBlur={(e) => updateData(data.plannerItemProductCategoryLink.id, "url", e.target.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <MovingPlannerItemProductCategoryLinkLogoUpload data={data.plannerItemProductCategoryLink} forceRerender={forceRerender} />
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default MovingPlannerItemLinkEdit;
