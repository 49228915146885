import {
  Button,
  Flex,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { DraggableTableRow } from "./DraggableTableRow";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { CSVLink } from "react-csv";

function SettleTable({ columnsData, data, setDragData, csvData }) {
  //const [dragActiveId, setDragActiveId] = useState();

  //console.log("SettleTable csvData 1", csvData);
  if (!csvData) {
    csvData = [];
  }
  //console.log("SettleTable csvData 2", csvData);

  //const data = useMemo(() => tableData, []);
  const columns = useMemo(() => columnsData, [columnsData]);
  const items = useMemo(() => data?.map(({ id }) => id), [data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  function handleDragStart() {
    //setDragActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setDragData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    //setDragActiveId(null);
  }

  function handleDragCancel() {
    //setDragActiveId(null);
  }

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;

  let enableSorting = false;
  if (setDragData) {
    enableSorting = true;
  }

  return (
    <>
      <Flex direction="column" w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex justify="space-between" align="center" w="100%" px="22px">
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: "4px", md: "12px" }}
            align="center"
            me="12px"
            my="24px"
            minW={{ sm: "100px", md: "200px" }}
          >
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              color="gray.500"
              size="sm"
              borderRadius="12px"
              maxW="75px"
              cursor="pointer"
            >
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
            </Select>
            <Text fontSize="xs" color="gray.400" fontWeight="normal">
              antal viste pr. side
            </Text>
          </Stack>
          <div>
            {csvData.length > 0 ? (
              <CSVLink
                filename="export.csv"
                data={csvData}
                separator={";"}
                style={{
                  border: "1px solid var(--chakra-colors-teal-300)",
                  borderRadius: "5px",
                  padding: "9px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginRight: "20px",
                  fontSize: "15px",
                }}
              >
                Download
              </CSVLink>
            ) : (
              <div></div>
            )}
            <Input
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              _focus={{ borderColor: "teal.300" }}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </Flex>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => {
                return (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {enableSorting && <Th style={{ width: "45px", paddingLeft: "0px" }}></Th>}
                    {headerGroup.headers.map((column, index) => {
                      if (headerGroups[0].headers[index].hidden === false || headerGroups[0].headers[index].hidden === undefined) {
                        return (
                          <Th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            pe="0px"
                            key={index}
                            style={headerGroups[0].headers[index].icon && { width: "110px" }}
                          >
                            <Flex
                              justifyContent={headerGroups[0].headers[index].alignHeader ? headerGroups[0].headers[index].alignHeader : "left"}
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("header")}

                              {headerGroups[0].headers[index].icon ? (
                                <div style={{ width: "18px", display: "inline-block" }}>&nbsp;</div>
                              ) : (
                                <Icon
                                  w={{ sm: "10px", md: "14px" }}
                                  h={{ sm: "10px", md: "14px" }}
                                  color={columns.isSorted ? "gray.500" : "gray.400"}
                                  float="right"
                                  as={column.isSorted ? (column.isSortedDesc ? TiArrowSortedDown : TiArrowSortedUp) : TiArrowUnsorted}
                                />
                              )}
                            </Flex>
                          </Th>
                        );
                      }
                      return null
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {page.map((row) => {
                  prepareRow(row);
                  return <DraggableTableRow key={row.original.id} headerGroups={headerGroups} row={row} enableSorting={enableSorting} />;
                })}
              </SortableContext>
            </Tbody>
          </Table>
        </DndContext>
        <Flex direction={{ sm: "column", md: "row" }} justify="space-between" align="center" w="100%" px={{ md: "22px" }}>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-hover"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="#fff"
              border="1px solid lightgray"
              display={pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"}
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {pageSize === 5 ? (
              <NumberInput max={pageCount - 1} min={1} w="75px" mx="6px" defaultValue="1" onChange={(e) => gotoPage(e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-hover"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="160px"
                    bg={pageNumber === pageIndex + 1 ? "teal.300" : "#fff"}
                    border="1px solid lightgray"
                    _hover={{
                      bg: "gray.200",
                      opacity: "0.7",
                      borderColor: "gray.500",
                    }}
                    key={index}
                  >
                    <Text fontSize="xs" color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-hover"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="160px"
              bg="#fff"
              border="1px solid lightgray"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SettleTable;
