// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateRealEstateBrokerOfficeInfoGql = gql`
  mutation updateRealEstateBrokerOfficeInfo($input: UpdateRealEstateBrokerOffice!) {
    updateRealEstateBrokerOffice(input: $input) {
      id
    }
  }
`;

const EstateBrokerOfficeAddress = ({ data }) => {
  const toast = useToast();
  const [updateRealEstateBrokerOfficeInfo] = useMutation(updateRealEstateBrokerOfficeInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (estateBrokerId) => {
    const roadNameAndNumber = document.getElementsByName("roadNameAndNumber")[0].value;
    const zipcodeAndCity = document.getElementsByName("zipCodeAndCity")[0].value;

    if (roadNameAndNumber !== "" && roadNameAndNumber !== "") {
      let inputData = JSON.parse('{"roadNameAndNumber":"' + roadNameAndNumber + '", "zipCodeAndCity":"' + zipcodeAndCity + '"}');

      inputData.id = estateBrokerId;

      updateRealEstateBrokerOfficeInfo({ variables: { input: inputData } })
        .then(() => {
          toast({
            title: "Ændringen er blevet gemt.",
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: "Ændringen er ikke blevet gemt.",
            description: "Adressen er ikke valid.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Adresse
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
              Vejnavn og nr.:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="roadNameAndNumber"
              defaultValue={data.realEstateBrokerOffice.address.roadNameAndNumber}
              onBlur={() => updateData(data.realEstateBrokerOffice.id)}
            />
          </Flex>

          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
              Post nr. og by:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="zipCodeAndCity"
              defaultValue={data.realEstateBrokerOffice.address.zipCode + " " + data.realEstateBrokerOffice.address.city}
              onBlur={() => updateData(data.realEstateBrokerOffice.id)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EstateBrokerOfficeAddress;
