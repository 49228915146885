import { __awaiter } from "tslib";
import React from 'react';
import './PowerOfAttorneyInfoOverlay.scss';
import Button from '../../../../components/atoms/button/button';
import { createPowerOfAttorneyDocGql } from '../../graphQL';
import { useMutation } from '@apollo/client';
import Typography from '../../../../components/atoms/typography/typography';
const PowerOfAttorneyInfoOverlay = (props) => {
    var _a;
    const [createPowerOfAttorneyDoc, { loading: isLoading }] = useMutation(createPowerOfAttorneyDocGql, {});
    const handleOkClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const inputData = {};
        inputData.productId = (_b = props.product) === null || _b === void 0 ? void 0 : _b.id;
        inputData.customerId = props.customerId;
        inputData.estateCaseId = props.estateCaseId;
        const signResponse = yield createPowerOfAttorneyDoc({ variables: { input: inputData } });
        document.location = signResponse.data.createPowerOfAttorneyDoc.url;
    });
    return (React.createElement("div", { className: 'powerOfAttorneyInfo' },
        React.createElement("div", { className: 'overlay' }),
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'box' }, isLoading ? (React.createElement("div", { className: 'centerText', style: { height: '300px' } },
                React.createElement("span", { className: 'loader' }),
                React.createElement("div", null,
                    React.createElement("br", null),
                    React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', className: 'unknownUserConsent', style: { margin: '0' }, dangerouslySetInnerHTML: {
                            __html: 'Øjeblik<br/>Opretter fuldmagt.',
                        } })))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'header' },
                    "Bestil - ", (_a = props.product) === null || _a === void 0 ? void 0 :
                    _a.name),
                React.createElement("div", { className: 'description' },
                    "Du er i f\u00E6rd med at bestille et produkt.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "For at bestille dette produkt kr\u00E6ves det, at du afgiver en fuldmagt, som bemyndiger Settle til at foretage bestillingen p\u00E5 dine vegne.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "S\u00E5fremt du, imod forventning, \u00F8nsker at tilbagekalde din bestilling, er det muligt indtil levering finder sted.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("b", null, "Er du sikker p\u00E5, at du \u00F8nsker at forts\u00E6tte?")),
                React.createElement("div", { className: 'bottom' },
                    React.createElement("div", { className: 'buttons' },
                        React.createElement(Button, { onClick: props.onCancel, text: 'Fortryd', grey: true }),
                        React.createElement(Button, { onClick: handleOkClicked, text: 'Fors\u00E6t' })))))))));
};
export default PowerOfAttorneyInfoOverlay;
