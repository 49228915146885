// Chakra imports
import { Flex, Image,Box, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";


const PlatformSettings = ({ title, description,image, onclick }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
 
  return (
    <Card p='16px'  style={{cursor:'pointer'}} onClick={onclick}>
      <Box mb='20px' position='relative' borderRadius='15px'>
          <Image src={image} borderRadius='15px' style={{objectFit: 'cover',height: '151px', width: '100%'}} />
          <Box
            w='100%'
            h='100%'
            position='absolute'
            top='0'
            borderRadius='15px'
            bg='linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)'></Box>
        </Box>

      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column'>
          <Text fontSize='sm' color='gray.500' fontWeight='600' mb='20px' style={{whiteSpace: 'break-spaces'}}>
            {description}
          </Text>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PlatformSettings;