import { gql } from "@apollo/client";

export const GET_PLANNERCATEGORIES = gql`
  query GET_PLANNERCATEGORIES($realEstateBrokerId: ID!) {
    plannerItemsCategories(realEstateBrokerId: $realEstateBrokerId) {
      id
      heading
      subHeading
      sorting
    }
    realEstateBrokers {
      id
      name
    }
  }
`;

export const CREATE_CATEGORY_GQL = gql`
  mutation createPlannerItemCategory($heading: String!, $subHeading: String!, $realEstateBrokerId: ID!) {
    createPlannerItemCategory(heading: $heading, subHeading: $subHeading, realEstateBrokerId: $realEstateBrokerId) {
      id
    }
  }
`;

export const DELETE_CATEGORY_GQL = gql`
  mutation deletePlannerItemCategory($id: ID!) {
    deletePlannerItemCategory(id: $id)
  }
`;

export const UPDATE_CATEGORY_SORTING_GQL = gql`
  mutation updatePlannerItemCategorySorting($input: [updatePlannerItemCategorySorting]) {
    updatePlannerItemCategorySorting(input: $input)
  }
`;

export const UPDATE_CATEGORY_GQL = gql`
  mutation updatePlannerItemCategory($id: ID!, $heading: String!, $subHeading: String!) {
    updatePlannerItemCategory(id: $id, heading: $heading, subHeading: $subHeading) {
      id
    }
  }
`;
