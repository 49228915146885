// Chakra imports
import { Select, Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import React, { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker"


const DataSelectorCardPartnerReport = ({ updatefn, fromDate, toDate, affiliatePartners, affiliatePartnerSelected }) => {
  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectedAffiliatePartner, setSelectedAffiliatePartner] = useState(affiliatePartnerSelected);


  const updateDates = (dates) => {
    if (dates.length === 2 && dates[1] != null) {
      const fromIsoDate = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate, toIsoDate, affiliatePartnerSelected);
    }
  }

  const updateAffiliatePartner = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - (selectedDates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - (selectedDates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate, toIsoDate, event.target.value);
    }
  }

  return (
    <Card p='16px' >
      <Grid templateColumns='repeat(4, 1fr)' gap={6}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e) => { setSelectedDates(e); updateDates(e); }}

            configs={{
              dateFormat: 'yyyy-MM-dd',

            }}
            propsConfigs={{

              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: 'yellow.400',
                  }
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                }
              },

            }}
          />

        </GridItem>


        <GridItem>
          <Text>Vælg partner:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select multiple={false} value={selectedAffiliatePartner} onChange={(e) => { setSelectedAffiliatePartner(e.target.value); updateAffiliatePartner(e) }}>
            <option key={'affiliatePartners_all'} value=''>Alle</option>
            {affiliatePartners.map((option) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </Select>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default DataSelectorCardPartnerReport;