import React, { useEffect, useState } from 'react';
import './handoverElectricity.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Button from '../../../../components/atoms/button/button';
import DatePicker from '../../../../components/molecules/date-picker/date-picker';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HANDOVER_GQL, UPDATE_ELECTRICITY_INFO_GQL } from './graphQL';
//import UploadImage from '../uploadImage/uploadImage'
import UploadImageSmall from '../uploadImageSmall/uploadImageSmall';
const HandoverElectricity = ({ handoverId, onNext }) => {
    const { loading, error, data } = useQuery(GET_HANDOVER_GQL, {
        variables: { id: handoverId },
    });
    const [updateElectricityInfo, { data: updateData, error: updateError }] = useMutation(UPDATE_ELECTRICITY_INFO_GQL, {
        refetchQueries: [{ query: GET_HANDOVER_GQL, variables: { id: handoverId } }],
    });
    const [validate, setValidate] = useState(false);
    const [componentData, setComponentData] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        if (!loading && !error && data) {
            setComponentData({
                meterNo: data.getHandover.electricity.meterNo,
                kWh: data.getHandover.electricity.kWh,
                readingDate: data.getHandover.electricity.readingDate
                    ? new Date(data.getHandover.electricity.readingDate)
                    : new Date(),
                imageUrl: data.getHandover.electricity.imageUrl,
                supplier: data.getHandover.electricity.supplier,
                notes: data.getHandover.electricity.notes,
                status: data.getHandover.status,
            });
        }
    }, [loading, error, data]);
    if (loading) {
        return React.createElement("p", null);
    }
    if (error) {
        console.log('get data Error:' + error.message);
        return React.createElement("p", null);
    }
    if (componentData == null) {
        return React.createElement("p", null);
    }
    if (updateError) {
        console.log('update Error:' + updateError.message);
    }
    if (updateData) {
        onNext();
    }
    const handleNextClicked = () => {
        setValidate(true);
        let isvalid;
        isvalid = true;
        for (const key of Object.keys(componentData)) {
            const fieldValue = componentData[key];
            if (fieldValue === '' &&
                key !== 'notes' &&
                key !== 'imageMimeType' &&
                key !== 'imageFilename' &&
                key !== 'imageUrl' &&
                key !== 'imageBase64' &&
                key !== 'supplier') {
                isvalid = false;
                console.log('Validate - Invalid field:', key);
            }
        }
        if (componentData.imageBase64 === '' && componentData.imageUrl === '') {
            isvalid = false;
            console.log('Validate - No image');
        }
        if (isvalid) {
            const ElectricityObj = {
                handoverId: handoverId,
                kWh: componentData.kWh,
                meterNo: componentData.meterNo,
                notes: componentData.notes,
                readingDate: componentData.readingDate,
                supplier: componentData.supplier,
            };
            if (componentData.imageBase64 !== '') {
                ElectricityObj.imageBase64 = componentData.imageBase64;
                ElectricityObj.mimeType = componentData.imageMimeType;
                ElectricityObj.filename = componentData.imageFilename;
            }
            setValidate(false);
            updateElectricityInfo({
                variables: {
                    input: ElectricityObj,
                },
            });
        }
        else {
            console.log('Validate - Not all fields are valid');
            forceUpdate();
        }
    };
    const imageChanged = (imgBase64, imgFileName, imgMineType) => {
        const tmp = componentData;
        tmp['imageBase64'] = imgBase64;
        tmp['imageMimeType'] = imgMineType;
        tmp['imageFilename'] = imgFileName;
        tmp['imageUrl'] = '';
        setComponentData(Object.assign({}, tmp));
    };
    const updateField = (fieldName, fieldValue) => {
        const tmp = componentData;
        tmp[fieldName] = fieldValue;
        setComponentData(Object.assign({}, componentData));
    };
    const handleDaySelect = (fieldName, date) => {
        //console.log('fieldName:' + fieldName + ' - value: ' + date)
        if (date) {
            const tmp = componentData;
            tmp[fieldName] = date;
            setComponentData(Object.assign({}, tmp));
        }
    };
    const takeoverDateValidateError = false;
    const imageValidateError = validate && componentData.imageBase64 === '' && componentData.imageUrl === '' ? true : false;
    const isLocked = componentData.status === 'DONE';
    return (React.createElement(Container, { className: 'handoverElectricity', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
        React.createElement(React.Fragment, null,
            React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Indtast m\u00E5lenummer:"),
                            React.createElement("input", { type: 'text', disabled: isLocked, placeholder: 'M\u00E5lernr er angivet under stregkoden', defaultValue: componentData.meterNo, className: validate && componentData.meterNo == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('meterNo', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Afl\u00E6st til:"),
                            React.createElement("label", { className: 'inputLabel' },
                                React.createElement("input", { type: 'text', disabled: isLocked, defaultValue: componentData.kWh, className: validate && componentData.kWh == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('kWh', e.target.value) }),
                                React.createElement("div", { className: 'inputUnit' }, "kWh"))),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Dato for afl\u00E6sning:"),
                            React.createElement(DatePicker, { disabled: isLocked, selected: componentData.readingDate, fieldName: 'readingDate', onSelect: handleDaySelect, error: takeoverDateValidateError })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Bem\u00E6rkninger:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.notes, className: 'fieldinput', onChange: (e) => updateField('notes', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement(UploadImageSmall, { disabled: isLocked, uploadTypeName: '', imageChanged: imageChanged, imageUrl: componentData.imageUrl, validateError: imageValidateError }))))),
            React.createElement(Row, null,
                React.createElement(Column, { width: { tablet: 8 }, style: { paddingRight: '38px' }, className: 'linksContainer' },
                    React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                        React.createElement(Button, { text: 'Næste', width: 180, grey: true, onClick: handleNextClicked })))))));
};
/*
              <div className='field'>
                <div className='fieldHeading'>Leverandør:</div>
                <input
                  type='text'
                  defaultValue={componentData!.supplier}
                  className={validate && componentData!.supplier == '' ? 'fieldinput-error' : 'fieldinput'}
                  onChange={(e) => updateField('supplier', (e.target as HTMLInputElement).value)}
                />
              </div>
              */
export default HandoverElectricity;
