// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SettleTable from "./../../../../components/Tables/SettleTable";
import MovingPlannerItemConditions from "./MovingPlannerItemConditions";

const GET_PLANNER_ITEM_PRODUCTS_GQL = gql`
  query GET_PLANNER_ITEM_PRODUCTS_GQL($id: ID!) {
    plannerItemProductCategory(id: $id) {
      id
      name
      products {
        id
        name
        sorting

        conditions {
          id
          conditionType
          field {
            id
            name
            fieldType
          }
          operator
          value
        }
      }
    }
    affiliatePartnerProducts {
      id
      name
    }
    conditionFields {
      id
      name
      fieldType
      values
    }
  }
`;

const ADD_ITEM_PRODUCT_GQL = gql`
  mutation addPlannerItemProductCategoryProduct($input: addPlannerItemProductCategoryProductInput!) {
    addPlannerItemProductCategoryProduct(input: $input) {
      id
    }
  }
`;

const REMOVE_PRODUCT_GQL = gql`
  mutation removePlannerItemProductCategoryProduct($id: ID!) {
    removePlannerItemProductCategoryProduct(id: $id)
  }
`;

const UPDATE_PRODUCT_SORTING_GQL = gql`
  mutation updatePlannerItemProductCategoryProductSorting($input: [updatePlannerItemProductCategoryProductSorting]) {
    updatePlannerItemProductCategoryProductSorting(input: $input)
  }
`;

const MovingplannerItemProductCategoryProducts = ({ plannerItemProductCategoryId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [seletedRow, setSeletedRow] = React.useState("");
  const [seletedProduct, setSeletedProduct] = React.useState("");
  const cancelRefAddDialog = React.useRef();
  const cancelRefRemoveDialog = React.useRef();
  const { isOpen: isOpenAddDialog, onOpen: onOpenAddDialog, onClose: onCloseAddDialog } = useDisclosure();
  const { isOpen: isOpenRemoveDialog, onOpen: onOpenRemoveDialog, onClose: onCloseRemoveDialog } = useDisclosure();
  const { isOpen: isConditionDialogOpen, onOpen: onConditionDialogOpen, onClose: onConditionDialogClose } = useDisclosure();

  const [addPlannerItemProductCategoryProduct] = useMutation(ADD_ITEM_PRODUCT_GQL, { refetchQueries: [GET_PLANNER_ITEM_PRODUCTS_GQL] });

  const [removePlannerItemProductCategoryProduct] = useMutation(REMOVE_PRODUCT_GQL, { refetchQueries: [GET_PLANNER_ITEM_PRODUCTS_GQL] });

  const [updatePlannerItemProductCategoryProductSorting] = useMutation(UPDATE_PRODUCT_SORTING_GQL, {
    refetchQueries: [GET_PLANNER_ITEM_PRODUCTS_GQL],
  });

  const { loading, error, data, refetch } = useQuery(
    GET_PLANNER_ITEM_PRODUCTS_GQL,
    {
      variables: { id: plannerItemProductCategoryId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting partner products from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting partner products from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Betingelser",
      accessor: "3",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: conditonClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: removeClicked,
    },
  ];

  function rowClicked(obj) {
    console.log("rowClicked - obj:", obj);
  }

  function removeClicked(obj) {
    setSeletedRow(obj);
    onOpenRemoveDialog();
  }

  function conditonClicked(obj) {
    for (let i = 0; i < data.plannerItemProductCategory.products.length; i++) {
      if (data.plannerItemProductCategory.products[i].id === obj.id) {
        setSeletedRow(data.plannerItemProductCategory.products[i]);
        console.log("OBJ:", data.plannerItemProductCategory.products[i]);
      }
    }

    onConditionDialogOpen();
  }

  const forceRerender = () => {
    setTimeout(() => {
      console.log("Refetch 22");
      refetch();
    }, 400);
  };

  const removeProduct = () => {
    removePlannerItemProductCategoryProduct({
      variables: {
        id: seletedRow.id,
      },
    });
    onCloseRemoveDialog();
  };

  const setTableData = (obj) => {
    var tableData = obj(data.plannerItemProductCategory.products);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((productCategoryProduct) => ({ id: productCategoryProduct.id, sorting: productCategoryProduct.sorting }));
    updatePlannerItemProductCategoryProductSorting({
      variables: {
        input: result,
      },
    });
  };

  const showAddModal = () => {
    onOpenAddDialog();
  };

  const addPlannerItemProductCategoryProductClicked = () => {
    let inputData = {};
    inputData.plannerItemProductCategoryId = plannerItemProductCategoryId;
    inputData.affiliatePartnerProductId = seletedProduct;

    addPlannerItemProductCategoryProduct({
      variables: { input: inputData },
    });

    onCloseAddDialog();
  };

  if (seletedRow) {
    for (let i = 0; i < data.plannerItemProductCategory.products.length; i++) {
      if (data.plannerItemProductCategory.products[i].id === seletedRow.id) {
        if (seletedRow === data.plannerItemProductCategory.products[i]) {
          console.log("Objecterne er ens");
        } else {
          console.log("Objecterne er FORSKELLIGE");
          setSeletedRow(data.plannerItemProductCategory.products[i]);
        }
      }
    }
  }

let products = [...data.affiliatePartnerProducts];

products.sort(function(a, b) {
    return a.name.localeCompare(b.name);
 });


  return (
    <div style={{ width: "100%" }}>
      <Card p="16px" my={{ sm: "24px", xl: "20px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Produkter
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize="lg"
              color="gray.400"
              cursor="pointer"
              mb="6px"
              onClick={() => {
                showAddModal();
              }}
            >
              Tilføj
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <SettleTable data={data.plannerItemProductCategory.products} columnsData={columns} setDragData={setTableData} />
        </CardBody>
      </Card>

      <AlertDialog isOpen={isOpenRemoveDialog} leastDestructiveRef={cancelRefRemoveDialog} onClose={onCloseRemoveDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Fjern produkt.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefRemoveDialog} onClick={onCloseRemoveDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={removeProduct} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenAddDialog} leastDestructiveRef={cancelRefAddDialog} onClose={onCloseAddDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Tilføj produkt.
            </AlertDialogHeader>
            <AlertDialogBody>
              Du er ved at tilføje et produkt.
              <br />
              <br />
              Vælg product:
              <Select
                multiple={false}
                onChange={(e) => {
                  setSeletedProduct(e.target.value);
                }}
              >
                <option key={""} value="">
                  Vælg produkt
                </option>
                {data.affiliatePartnerProducts.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <br />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefAddDialog}
                onClick={() => {
                  onCloseAddDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={addPlannerItemProductCategoryProductClicked} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isConditionDialogOpen} onClose={onConditionDialogClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="1000px">
          <ModalBody>
            <MovingPlannerItemConditions
              key={seletedRow.id}
              conditionFields={data.conditionFields}
              conditions={seletedRow.conditions}
              forceRerender={forceRerender}
              type={"Product"}
              refId={seletedRow.id}
              productCategoryRefId={plannerItemProductCategoryId}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onConditionDialogClose}>Luk</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MovingplannerItemProductCategoryProducts;
