import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      water {
        meterNo
        meterValue
        readingDate
        supplier
        sewageDiversionPaymentTo
        notes
        imageUrl
      }
    }
  }
`;
const UPDATE_WATER_INFO_GQL = gql `
  mutation updateWater($input: UpdateWaterInput!) {
    updateWater(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_WATER_INFO_GQL };
