import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
} from "@chakra-ui/react";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import SettleTable from "./../../../../components/Tables/SettleTable";
import React from "react";

function MovingPlannerItemContainer({ data, createNewItem, deleteItem, updateSorting, editItem }) {
  const { isOpen: deleteItemIsOpen, onOpen: deleteItemOnOpen, onClose: deleteItemOnClose } = useDisclosure();
  const { isOpen: createItemIsOpen, onOpen: createItemOnOpen, onClose: createItemOnClose } = useDisclosure();
  const cancelRef = React.useRef();
  const inpItemNameRef = React.useRef(null);
  const [seletedRow, setSeletedRow] = React.useState("");
  const textColor = useColorModeValue("gray.700", "white");

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
    },
    {
      header: "Overskrift",
      accessor: "heading",
      onClick: rowClicked,
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Redigere",
      accessor: "1",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: EditClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: DeleteClicked,
    },
  ];

  const setTableData = (obj) => {
    var tableData = obj(data.plannerItemsCategory.plannerItems);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    updateSorting(tmptableData);
  };

  function rowClicked(obj) {
    EditClicked(obj);
  }

  function EditClicked(obj) {
    editItem(obj.id);
  }

  function DeleteClicked(obj) {
    setSeletedRow(obj);
    deleteItemOnOpen();
  }

  function CreateItemClicked() {
    createItemOnOpen();
  }

  const CreateItem = () => {
    createNewItem(inpItemNameRef.current.value);
    createItemOnClose();
  };

  const DeleteItem = () => {
    deleteItem(seletedRow.id);
    deleteItemOnClose();
  };

  return (
    <div>
      <Flex direction="column" pt={{ sm: "125px", lg: "0px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="24px" justifyContent="space-between">
            <Flex direction="column">
              <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                Punkter under "{data.plannerItemsCategory.heading}"
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Liste over punkter.
              </Text>
            </Flex>
            <Flex direction="column">
              <Text
                fontSize="lg"
                color="gray.400"
                cursor="pointer"
                mb="6px"
                onClick={() => {
                  CreateItemClicked();
                }}
              >
                Tilføj
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <SettleTable data={data.plannerItemsCategory.plannerItems} columnsData={columns} setDragData={setTableData} />
          </CardBody>
        </Card>
      </Flex>

      <AlertDialog isOpen={deleteItemIsOpen} leastDestructiveRef={cancelRef} onClose={deleteItemOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet punktet: {seletedRow.heading}
            </AlertDialogHeader>
            <AlertDialogBody>Er du sikker?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={deleteItemOnClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={DeleteItem} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal onClose={createItemOnClose} size="xl" isOpen={createItemIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret ny punkt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprettet et nyt punkt, udfyld nedenstående og klik opret.</Text>
            <br />
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Navn:{" "}
                </Text>
                <Input ref={inpItemNameRef} variant="flushed" type="text" name="name" />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={createItemOnClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={CreateItem} ml={3}>
              Opret
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default MovingPlannerItemContainer;
