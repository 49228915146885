import { __awaiter } from "tslib";
import React, { useState } from 'react';
import './consentOverlay.scss';
import { useMutation } from '@apollo/client';
import { updateCustomerCaseInfoGql } from './../../graphQL';
import Button from '../../../../components/atoms/button/button';
import { datastore } from '../../../../dataStore';
const ConsentOverlay = (props) => {
    const [updateCustomerCaseInfo] = useMutation(updateCustomerCaseInfoGql, {});
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const handleCheckBoxClicked = () => {
        setIsConsentChecked(!isConsentChecked);
    };
    const handlecloseClicked = () => {
        props.closeClicked();
    };
    const handleSaveClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.id = props.data.id;
        inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[0].id;
        inputData.hasConsent = isConsentChecked;
        inputData.consentText = datastore.data.marketingConsentText;
        yield updateCustomerCaseInfo({ variables: { input: inputData } });
        document.location.reload();
    });
    return (React.createElement("div", { className: 'consentOverlay' },
        React.createElement("div", { className: 'overlay', onClick: handlecloseClicked }),
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'box' },
                React.createElement("div", { className: 'header' }, "Samtykke til markedsf\u00F8ring"),
                React.createElement("div", { className: 'description', id: 'consentText', dangerouslySetInnerHTML: { __html: datastore.data.marketingConsentIntoText } }),
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'field', onClick: handleCheckBoxClicked },
                        React.createElement("div", { className: 'checkbox' }, isConsentChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                            React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                            React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#000', strokeWidth: '2' },
                                React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                        React.createElement("div", { className: 'checkboxLabel' }, datastore.data.marketingConsentText)),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'buttons' }, isConsentChecked ? (React.createElement(Button, { onClick: handleSaveClicked, text: 'Gem' })) : (React.createElement(Button, { onClick: handlecloseClicked, text: 'Luk', grey: true })))))))));
};
export default ConsentOverlay;
