// Chakra imports
import { Grid, GridItem, Text } from "@chakra-ui/react";
import Select from "react-select";
import "../components/topBar.css";

const TopBar = ({ customers, realEstateBroker, selectedCustomerId, onCustomerChange }) => {
  const customerArray = customers.map((obj) => ({
    value: obj.externalId,
    label: obj.name,
  }));

  document.title = realEstateBroker.name;

  const onInputChange = (inputValue) => {
    //console.log('inputValue:',inputValue.value);
    onCustomerChange(inputValue.value);
  };

  let position = -1;
  for (let i = 0; i < customerArray.length; i++) {
    if (customerArray[i].value === selectedCustomerId) {
      position = i;
      break; // Exit the loop once the object is found
    }
  }

  return (
    <Grid templateColumns="repeat(1, 1fr)" width={"100%"} className="topBar" alignItems={"center"}>
      <GridItem>
        <Grid templateColumns="repeat(12, 1fr)" width={"100%"}>
          <GridItem colSpan={2}>&nbsp;</GridItem>
          <GridItem colSpan={2}>
            <Text>Vælg kunde:</Text>
          </GridItem>
          <GridItem colSpan={6} style={{ paddingTop: "14px", textAlign: "left" }}>
            <Select
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "grey",
                  primary: "black",
                  neutral10: "black",
                },
              })}
              className="react-select-container"
              classNamePrefix="react-select"
              isSearchable={true}
              multiple={false}
              options={customerArray}
              defaultValue={customerArray[position]}
              onChange={onInputChange}
            ></Select>
          </GridItem>
          <GridItem colSpan={2}>&nbsp;</GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default TopBar;
