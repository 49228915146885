import React from "react";
import { useParams } from "react-router-dom";
import TopBar from "./components/topBar";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Settle } from "settle_planner";
import "./PlannerPreview.css";
import { backendAPI } from "settle_planner/dist/esm/App";

const GET_CUSTOMERS = gql`
  query GET_CUSTOMERS($realEstateBrokerId: ID!) {
    customerByRealEstateBroker(realEstateBrokerId: $realEstateBrokerId) {
      externalId
      name
    }
    realEstateBroker(id: $realEstateBrokerId) {
      id
      name
      systemname
    }
  }
`;

export const updateCustomerInfoGql = gql`
  mutation updateCustomerByExternalId($input: updateCustomerByExternalIdInput!) {
    updateCustomerByExternalId(input: $input) {
      id
    }
  }
`;

export default function PlannerPreview() {
  const { realEstateBrokerId, customerId } = useParams();
  const [updateCustomerInfo] = useMutation(updateCustomerInfoGql);
  const { loading: getCustomersLoading, error: getCustomersError, data: getCustomersData } = useQuery(GET_CUSTOMERS, {
    variables: { realEstateBrokerId: realEstateBrokerId },
  });

  if (getCustomersLoading) return <p></p>; //loading

  if (getCustomersError) {
    return <p>Error getting customers from server (1)</p>;
  }

  if (!getCustomersData) {
    return <p>Error getting customers from server (2)</p>;
  }

  const handleCustomerChange = (customerExternalId) => {
    window.location.href = "#/plannerpreview/" + realEstateBrokerId + "/" + customerExternalId;
  };

  const handleConsentApproved = (consentText) => {
    let inputData = {};
    inputData["externalId"] = customerId;
    inputData["hasConsent"] = true;
    inputData["consentText"] = consentText;

    updateCustomerInfo({ variables: { input: inputData } });

    setTimeout(() => {
      window.location.reload();  
    }, 500);
  };

  let plannerEnv = backendAPI.live;
  switch (process.env.REACT_APP_ENV.toLocaleLowerCase()) {
    case "stage":
      plannerEnv = backendAPI.stage;
      break;
    case "live":
      plannerEnv = backendAPI.live;
      break;
    case "localhost":
      plannerEnv = backendAPI.localhost;
      break;
    default:
      plannerEnv = backendAPI.live;
      break;
  }

  return (
    <>
      <div>
        <TopBar
          realEstateBroker={getCustomersData.realEstateBroker}
          customers={getCustomersData.customerByRealEstateBroker}
          selectedCustomerId={customerId}
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <div>
        <Settle onConcentApproved={handleConsentApproved} backendApi={plannerEnv} userId={customerId} />
      </div>
    </>
  );
}
