// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Input,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React, { useRef } from "react";
import { gql, useMutation } from "@apollo/client";

const uploadFileGql = gql`
  mutation uploadPlannerItemProductCategoryMedia($input: UploadPlannerItemProductCategoryMediaInput!) {
    uploadPlannerItemProductCategoryMedia(input: $input)
  }
`;

const deleteFileGql = gql`
  mutation deletePlannerItemProductCategoryMedia($input: DeletePlannerItemProductCategoryMediaInput!) {
    deletePlannerItemProductCategoryMedia(input: $input)
  }
`;

const updateMediaGql = gql`
  mutation updateMedia($input: updateMediaInput!) {
    updateMedia(input: $input) {
      id
    }
  }
`;

const MovingPlannerItemProductCategoryMediaUpload = ({ data, forceRerender }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [uploadFile, { loading: loadingUpload, data: dataUpload }] = useMutation(uploadFileGql);
  const [deleteFile, { loading: loadingDelete, data: dataDelete }] = useMutation(deleteFileGql);
  const [updateMedia] = useMutation(updateMediaGql);

  const inputRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");

  if (!loadingUpload && dataUpload?.uploadAffiliatePartnerDoc) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  if (!loadingDelete && dataDelete) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const updateData = (id, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = id;
    updateMedia({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleDeleteClick = () => {
    onOpen();
  };

  const deleteMedia = () => {
    onClose();

    const inputData = {
      plannerItemProductCategoryId: data.id,
    };

    deleteFile({ variables: { input: inputData } });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  async function handleFileChange(e) {
    if (!e.target.files) {
      return;
    }

    const fileBase64 = await toBase64(e.target.files[0]);

    const inputData = {
      plannerItemProductCategoryId: data.id,
      filename: e.target.files[0].name,
      mimeType: e.target.files[0].type,
      fileContentBase64: fileBase64,
    };

    uploadFile({ variables: { input: inputData } });
  }

  const downloadMedia = (docUrl) => {
    window.open(docUrl);
  };

  let showFile = false;

  if (data.media) {
    showFile = true;
  }
  return (
    <Card p="16px" my={{ sm: "24px", xl: "24px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Media
        </Text>

        {!showFile ? (
          <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleUploadClick()}>
            Upload
          </Text>
        ) : (
          <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleDeleteClick()}>
            Slet
          </Text>
        )}
        <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        {showFile && (
          <Stack direction="column" spacing="24px" w="100%" style={{ paddingTop: "10px" }}>
            <Flex align="center" w="100%">
              <Flex align="center">
                <img
                  src={data.media.mediaUrl}
                  alt="Uploaded media"
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: "1px solid rgb(207 207 207)",
                    marginRight: "20px",
                  }}
                />

                <Flex direction="column" cursor="pointer" onClick={() => downloadMedia(data.media.mediaUrl)}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    {data.media.filename}
                  </Text>
                  <Text color="gray.400" fontSize="xs">
                    {data.media.filename}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" mb="18px">
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                Overskrift:{" "}
              </Text>
              <Input
                variant="flushed"
                type="text"
                name="heading"
                defaultValue={data.media.heading}
                onBlur={(e) => updateData(data.media.id, "heading", e.target.value)}
              />
            </Flex>
            <Flex align="center" mb="18px">
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                Text:{" "}
              </Text>
              <Input
                variant="flushed"
                type="text"
                name="text"
                defaultValue={data.media.text}
                onBlur={(e) => updateData(data.media.id, "text", e.target.value)}
              />
            </Flex>
          </Stack>
        )}
      </CardBody>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteMedia}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet media
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteMedia} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default MovingPlannerItemProductCategoryMediaUpload;
