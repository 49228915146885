import { __awaiter } from "tslib";
import React, { useState } from 'react';
import './EditDataOverlay.scss';
import DatePicker from '../../../../components/molecules/date-picker/date-picker';
import DawaAutocomplete2 from '../../../../components/molecules/dawa-autocomplete2';
import Button from '../../../../components/atoms/button/button';
import { updateCustomerCaseInfoGql, closeCustomerAccountGql } from './../../graphQL';
import { useMutation, useQuery } from '@apollo/client';
import { datastore } from '../../../../dataStore';
import { GET_LEADS_GQL } from './graphQL';
var TabMenuItem;
(function (TabMenuItem) {
    TabMenuItem[TabMenuItem["MovinData"] = 0] = "MovinData";
    TabMenuItem[TabMenuItem["Profile"] = 1] = "Profile";
})(TabMenuItem || (TabMenuItem = {}));
const EditDataOverlay = (props) => {
    const { data: leadsData } = useQuery(GET_LEADS_GQL, {
        variables: { id: props.data.id },
        fetchPolicy: 'no-cache',
    });
    const [selectTap, setSelectTap] = useState(TabMenuItem.MovinData);
    const [updateCustomerCaseInfo] = useMutation(updateCustomerCaseInfoGql, {});
    const [closeCustomerAccountInfo] = useMutation(closeCustomerAccountGql, {});
    const [takeoverDate, setTakeoverDate] = useState(new Date(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.takeoverDate));
    const [email, setEmail] = useState(props.data.email);
    const [phone, setPhone] = useState(props.data.phone);
    const [moveToAddress, setMoveToAddress] = useState(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.moveToAddress.fullAddress);
    const [moveFromAddress, setMoveFromAddress] = useState(props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.currentAddress.fullAddress);
    const [validate, setValidate] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(props.data.hasConsent);
    const [isCloseAccountChecked, setIsCloseAccountChecked] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const handleMoveToAddressChanged = (fulladdress) => {
        setMoveToAddress(fulladdress);
    };
    const handleMoveFromAddressChanged = (fulladdress) => {
        setMoveFromAddress(fulladdress);
    };
    const handleDaySelect = (date) => {
        if (date) {
            //console.log('field:', field)
            setTakeoverDate(new Date(date));
        }
    };
    const updateField = (fieldName, value) => {
        switch (fieldName) {
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };
    const handleConsentCheckBoxClicked = () => {
        setIsConsentChecked(!isConsentChecked);
    };
    const handleCheckBoxClicked = () => {
        setIsCloseAccountChecked(!isCloseAccountChecked);
    };
    const handleCloseAccountClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        const inputData = {};
        inputData.id = props.data.id;
        //inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id
        //inputData.hasConsent = false
        yield closeCustomerAccountInfo({ variables: { input: inputData } });
        localStorage.removeItem('state');
        document.location.href = '/';
    });
    const handleSaveClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        //alert(moveFromAddress)
        if (isCloseAccountChecked) {
            setShowDeleteWarning(true);
        }
        else {
            switch (selectTap) {
                case TabMenuItem.MovinData:
                    if (moveToAddress == '' ||
                        moveFromAddress == '' ||
                        email == '' ||
                        email.indexOf('@') == -1 ||
                        phone == '' ||
                        takeoverDate.toString() == new Date('1970-01-01').toString()) {
                        setValidate(true);
                    }
                    else {
                        const inputData = {};
                        inputData.id = props.data.id;
                        inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id;
                        inputData.moveToFullAddress = moveToAddress;
                        inputData.currentFullAddress = moveFromAddress;
                        inputData.takeoverDate = takeoverDate;
                        inputData.phone = phone;
                        inputData.email = email;
                        yield updateCustomerCaseInfo({ variables: { input: inputData } });
                        document.location.reload();
                    }
                    break;
                case TabMenuItem.Profile:
                    if (email == '' || email.indexOf('@') == -1 || phone == '') {
                        setValidate(true);
                    }
                    else {
                        const inputData = {};
                        inputData.id = props.data.id;
                        inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[props.selectedEstateNo].id;
                        inputData.hasConsent = isConsentChecked;
                        inputData.consentText = datastore.data.marketingConsentText;
                        yield updateCustomerCaseInfo({ variables: { input: inputData } });
                        document.location.reload();
                    }
                    break;
                default:
                    break;
            }
        }
    });
    const dawaFieldMoveToClass = validate && moveToAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const dawaFieldMoveFromClass = validate && moveFromAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const takeoverDateValidateError = validate && takeoverDate.toString() == new Date('1970-01-01').toString() ? true : false;
    const eMailFieldClass = validate && (email == '' || email.indexOf('@') == -1) ? 'fieldinput-error' : 'fieldinput';
    const phoneFieldClass = validate && phone == '' ? 'fieldinput-error' : 'fieldinput';
    //const consentFieldValidateError = validate && !isConsentChecked ? 'red' : '#cfcfcf'
    if (!leadsData) {
        return React.createElement("div", null);
    }
    //console.log('leadsData:', leadsData)
    /*
    {leadsData.getLeadByCustomerId.map((lead:any) => (
      <div>{lead.product.name}</div>
    ))}
    */
    return (React.createElement("div", { className: 'editDataOverlay' },
        React.createElement("div", { className: 'overlay' }),
        React.createElement("div", { className: 'container' },
            React.createElement("div", { id: 'mdiv', onClick: props.onClose },
                React.createElement("div", { className: 'mdiv' },
                    React.createElement("div", { className: 'md' }))),
            showDeleteWarning ? (React.createElement("div", { className: 'box' },
                React.createElement("div", { className: 'header' }, "Luk min konto"),
                React.createElement("div", { className: 'description' },
                    "Du er i f\u00E6rd med at lukke din profil og anmode om anonymisering af dine data hos Settle og dets partner, der har modtaget dine oplysninger.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Har du allerede indg\u00E5et en aftale med en partner, st\u00E5r aftalen ved magt. Du skal derfor selv kontakte partneren for at annullere din aftale.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("b", null, "Er du sikker?")),
                React.createElement("div", { className: 'bottom' },
                    React.createElement("div", { className: 'buttons' },
                        React.createElement(Button, { onClick: props.onClose, text: 'Nej, behold konto', grey: true }),
                        React.createElement("div", { style: { width: '35px' } }, "\u00A0"),
                        React.createElement(Button, { onClick: handleCloseAccountClicked, text: 'Ja, jeg er sikker' }))))) : (React.createElement("div", { className: 'box' },
                React.createElement("div", { className: 'tabMenu' },
                    React.createElement("div", { className: 'header tabMenuItem' }, "Dine persondata og samtykke")),
                selectTap === TabMenuItem.MovinData ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'description subText' }, "Her kan du tilpasse dine flytteoplysninger. Disse oplysninger anvendes af Settle og vores samaarbejdspartnere."),
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Din nuv\u00E6rende adresse"),
                            React.createElement(DawaAutocomplete2, { select: (selected) => {
                                    handleMoveFromAddressChanged(selected.tekst);
                                }, blur: (address) => {
                                    handleMoveFromAddressChanged(address);
                                }, inputValue: props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.currentAddress
                                    .fullAddress, classes: {
                                    root: dawaFieldMoveFromClass,
                                    input: 'text-field-dawa',
                                    ul: 'ul',
                                    li: 'li',
                                    selected: 'selected',
                                } })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Din nye adresse"),
                            React.createElement(DawaAutocomplete2, { select: (selected) => {
                                    handleMoveToAddressChanged(selected.tekst);
                                }, blur: (address) => {
                                    handleMoveToAddressChanged(address);
                                }, inputValue: props.data.realEstateBrokerCases[props.selectedEstateNo].movingDetails.moveToAddress.fullAddress, classes: {
                                    root: dawaFieldMoveToClass,
                                    input: 'text-field-dawa',
                                    ul: 'ul',
                                    li: 'li',
                                    selected: 'selected',
                                } })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Indflytningsdato"),
                            React.createElement(DatePicker, { selected: takeoverDate, fieldName: 'takeoverDate', onSelect: handleDaySelect, error: takeoverDateValidateError })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Din email"),
                            React.createElement("input", { type: 'text', value: email, className: eMailFieldClass, onChange: (e) => updateField('email', e.target.value) })),
                        React.createElement("div", { className: 'field', style: { paddingBottom: '20px' } },
                            React.createElement("div", { className: 'fieldHeading' }, "Dit telefonnummer"),
                            React.createElement("input", { type: 'tel', value: phone, className: phoneFieldClass, onChange: (e) => updateField('phone', e.target.value) }))),
                    React.createElement("div", null,
                        React.createElement("div", { className: 'edit-icon' },
                            React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 1125 1124.99995', preserveAspectRatio: 'xMidYMid meet', version: '1.0' },
                                React.createElement("defs", null,
                                    React.createElement("clipPath", { id: '242bc95391' },
                                        React.createElement("path", { d: 'M 17 17 L 1111.621094 17 L 1111.621094 1111.621094 L 17 1111.621094 Z M 17 17 ', clipRule: 'nonzero' }))),
                                React.createElement("g", { clipPath: 'url(#242bc95391)' },
                                    React.createElement("path", { fill: '#58595e', d: 'M 899.570312 27.953125 C 885.894531 14.277344 861.675781 14.277344 848 27.953125 L 105.273438 770.714844 C 105.238281 770.75 105.207031 770.78125 105.167969 770.820312 L 105.0625 770.925781 C 105 770.988281 104.984375 771.066406 104.925781 771.128906 C 101.964844 774.121094 99.746094 777.730469 97.960938 781.613281 C 97.527344 782.558594 97.117188 783.457031 96.769531 784.425781 C 96.457031 785.292969 95.945312 786.054688 95.695312 786.953125 L 18.585938 1065.148438 C 15.097656 1077.828125 18.65625 1091.394531 27.953125 1100.691406 C 34.898438 1107.636719 44.195312 1111.375 53.738281 1111.375 C 56.980469 1111.375 60.257812 1110.949219 63.496094 1110.058594 L 341.691406 1032.949219 C 342.589844 1032.699219 343.351562 1032.1875 344.21875 1031.875 C 345.191406 1031.527344 346.085938 1031.117188 347.03125 1030.683594 C 350.914062 1028.898438 354.527344 1026.679688 357.515625 1023.71875 C 357.578125 1023.660156 357.65625 1023.644531 357.71875 1023.582031 L 357.828125 1023.476562 C 357.863281 1023.4375 357.894531 1023.40625 357.929688 1023.371094 L 1100.691406 280.644531 C 1107.527344 273.808594 1111.375 264.53125 1111.375 254.859375 C 1111.375 245.191406 1107.527344 235.914062 1100.691406 229.074219 Z M 749.382812 229.71875 L 898.925781 379.261719 L 331.929688 946.226562 L 182.417969 796.714844 Z M 106.09375 1022.550781 L 149.296875 866.734375 L 261.910156 979.347656 Z M 950.5 327.691406 L 800.953125 178.144531 L 873.785156 105.308594 L 1023.335938 254.859375 Z M 950.5 327.691406 ', fillOpacity: '1', fillRule: 'nonzero' })))),
                        React.createElement("div", { className: 'edit-text', onClick: () => {
                                setSelectTap(TabMenuItem.Profile);
                            } }, "Se og redigerer dine persondata og samtykke")))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'description' }, "Her kan du se og tilpasse dine persondata, tilbagekalde samtykke samt udnytte \u201Cretten til at blive gemt\u201D."),
                    React.createElement("div", { className: 'description' },
                        React.createElement("h2", null, "Dine services"),
                        React.createElement("p", null, "Nedenfor kan du se de services, du har benyttet dig af i Settle. Du har til enhver tid mulighed for at tr\u00E6kke dit samtykke tilbage ved at klikke p\u00E5 \u2018Fortryd bestilling\u2019 under de enkelte kategorier i flytteplanneren, samme sted, du foretog bestillingen. Er der allerede indg\u00E5et en aftale, vil denne st\u00E5 ved magt, og du vil derfor selv skulle kontakte den p\u00E5g\u00E6ldende partner med \u00F8nsket om at fortryde."),
                        leadsData.getLeadByCustomerId.length > 0 && (React.createElement("div", { className: 'productContainer' }, leadsData.getLeadByCustomerId.map((lead) => (React.createElement("div", { className: 'productItem', key: lead.id },
                            React.createElement("div", { className: 'productPartnerLogoContainer' },
                                React.createElement("img", { src: lead.product.partnerLogo.mediaUrl, alt: 'partner logo', className: 'productPartnerLogo' })),
                            React.createElement("div", null, lead.product.name)))))),
                        React.createElement("h2", null, "Dit samtykke"),
                        React.createElement("p", { dangerouslySetInnerHTML: { __html: datastore.data.marketingConsentIntoText } }),
                        React.createElement("div", { className: 'field', style: { paddingTop: '0px', display: 'flex', alignItems: 'center' }, onClick: handleConsentCheckBoxClicked },
                            React.createElement("div", { className: 'checkbox' }, isConsentChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#cfcfcf', strokeWidth: '2' },
                                    React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                    React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                            React.createElement("div", { className: 'checkboxLabel' }, datastore.data.marketingConsentText)),
                        React.createElement("h2", null, "Ret til sletning - \u2018retten til at blive glemt\u2019"),
                        React.createElement("p", null, "Alle har som udgangspunkt ret til at blive slettet, hvis der ikke l\u00E6ngere er en god grund til at holde p\u00E5 dem, eller hvis samtykke tr\u00E6kkes tilbage. L\u00E6s mere om dine rettigheder i vores persondatapolitik. Skal uddybes mere med, hvad"),
                        React.createElement("div", { className: 'fields' },
                            React.createElement("div", { className: 'field', style: { paddingTop: '0px', display: 'flex', alignItems: 'center' }, onClick: handleCheckBoxClicked },
                                React.createElement("div", { className: 'checkbox' }, isCloseAccountChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                    React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                                    React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#cfcfcf', strokeWidth: '2' },
                                        React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                        React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                                React.createElement("div", { className: 'checkboxLabel' }, "Jeg \u00F8nsker at lukke min konto og f\u00E5 anonymiseret alle mine data jf. GDPR."))),
                        React.createElement("h2", { className: 'PolicyLinksHeading' }, "L\u00E6s vores politikker"),
                        React.createElement("div", { className: 'PolicyLinksContainer' },
                            React.createElement("a", { href: 'https://settle.dk/persondata.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Persondatapolitik"),
                            React.createElement("a", { href: 'https://settle.dk/cookiepolitik.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Cookiepolitik"),
                            React.createElement("a", { href: 'https://settle.dk/handelsbetingelser.html', target: '_blank', className: 'link', rel: 'noreferrer' }, "Handelsbetingelser"))))),
                React.createElement("div", { className: 'bottom' },
                    React.createElement("div", { className: 'buttons' },
                        React.createElement(Button, { onClick: handleSaveClicked, text: 'Gem' }))))))));
};
export default EditDataOverlay;
