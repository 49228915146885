// Chakra imports
import { Flex, Text, useColorModeValue, Input, Select, Textarea } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";

const AffiliatePartnerProductInfo = ({ data, dataUpdate }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} h="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Info
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"200px"}>
              Navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.affiliatePartnerProduct.name}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "name", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"200px"}>
              Beskrivelse:{" "}
            </Text>
            <Textarea
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.affiliatePartnerProduct.description}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "description", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"200px"}>
              Produkt navn i eksternt API:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="apiName"
              defaultValue={data.affiliatePartnerProduct.apiName}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "apiName", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"200px"}>
              Integration:{" "}
            </Text>
            <Select
              multiple={false}
              value={data.affiliatePartnerProduct.integrationType}
              onChange={(e) => {
                dataUpdate(data.affiliatePartnerProduct.id, "integrationType", e.target.value);
              }}
            >
              <option key={"NONE"} value="NONE">
                Ingen
              </option>
              <option key={"FORSKEL_EL"} value="FORSKEL_EL">
                Forskel - API
              </option>
              <option key={"HYPER"} value="HYPER">
                Hyper - API
              </option>
              <option key={"EMAIL"} value="EMAIL">
                Email
              </option>
            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default AffiliatePartnerProductInfo;
