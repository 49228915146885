// Chakra imports
import { Flex, Text, useColorModeValue, Input } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";

const EmailIntegrationInfo = ({ data, dataUpdate }) => {
  // Chakra color mode


  const updateFollowUpAfterNumberOfDays = (noOfDays) => {
    if (noOfDays === "") {
      noOfDays = 1;
    }
    if (noOfDays < 1) {
      noOfDays = 1;
    }
    dataUpdate(data.affiliatePartnerProduct.id, "followUpAfterNumberOfDays", parseInt(noOfDays))
  }

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Email integration
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Primær email:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="primaryEmail"
              defaultValue={data.affiliatePartnerProduct.primaryEmail}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "primaryEmail", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Email Cc:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="ccEmail"
              defaultValue={data.affiliatePartnerProduct.ccEmail}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "ccEmail", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
              Email Bcc:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="bccEmail"
              defaultValue={data.affiliatePartnerProduct.bccEmail}
              onBlur={(e) => dataUpdate(data.affiliatePartnerProduct.id, "bccEmail", e.target.value)}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"290px"}>
              Automatisk opfølgning efter antal dage:{" "}
            </Text>
            <Input
              variant="flushed"
              type="number"
              name="followUpAfterNumberOfDays"
              defaultValue={data.affiliatePartnerProduct.followUpAfterNumberOfDays}
              onBlur={(e) => updateFollowUpAfterNumberOfDays(e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default EmailIntegrationInfo;
