import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      session {
        cleanedProperly
        allKeysHandedOver
        allInstructionsForUsageHandedover
        estateReviewed
        notes
      }
    }
  }
`;
const UPDATE_SESSION_INFO_GQL = gql `
  mutation updateHandoverSession($input: UpdateHandoverSessionInput!) {
    updateHandoverSession(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_SESSION_INFO_GQL };
