import Typography from '../../../../components/atoms/typography/typography';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Moment from 'moment';
import './TopBanner.scss';
import React, { useState } from 'react';
import EditDataOverlay from '../EditDataOverlay/EditDataOverlay';
import { datastore } from '../../../../dataStore';
const TopBanner = (props) => {
    const { data, selectedEstateNo } = props;
    const [showingEdit, setShowingEdit] = useState(false);
    const estateAddress = data.realEstateBrokerCases[selectedEstateNo].address.fullAddress;
    const takeOverDate = Moment(new Date(data.realEstateBrokerCases[selectedEstateNo].movingDetails.takeoverDate)).format('DD.MM.yyyy');
    const editClicked = () => {
        setShowingEdit(true);
    };
    const closeEditClicked = () => {
        setShowingEdit(false);
    };
    //<EditDataOverlay data={data} />
    return (React.createElement(React.Fragment, null,
        showingEdit && React.createElement(EditDataOverlay, { data: data, onClose: closeEditClicked, selectedEstateNo: selectedEstateNo }),
        React.createElement(Container, { paddingTopReset: true, style: { paddingBottom: '20px' } },
            React.createElement("div", { className: 'topbanner' },
                React.createElement(Row, null,
                    React.createElement(Column, null,
                        React.createElement("div", { className: 'topbanner__picture-con' },
                            React.createElement("img", { className: 'topbanner__picture', src: props.imageUrlDesktop ? props.imageUrlDesktop : '' }),
                            datastore.data.realEstateBrokerLogoUrl === '' && React.createElement("div", { className: 'topbanner_logo' }, "Settle"),
                            React.createElement("div", { className: 'realEstateBroker_logo_container' },
                                React.createElement("img", { className: 'realEstateBroker_logo', src: datastore.data.realEstateBrokerLogoUrl })))),
                    React.createElement(Column, { className: 'topbanner-container', offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 4 } },
                        React.createElement("div", { className: 'topbanner-text' },
                            React.createElement("div", { className: 'topbanner-edit', onClick: editClicked },
                                React.createElement("div", { className: 'topbanner-edit-text' }, "Rediger"),
                                React.createElement("div", { className: 'topbanner-edit-icon' },
                                    React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 1125 1124.99995', preserveAspectRatio: 'xMidYMid meet', version: '1.0' },
                                        React.createElement("defs", null,
                                            React.createElement("clipPath", { id: '242bc95391' },
                                                React.createElement("path", { d: 'M 17 17 L 1111.621094 17 L 1111.621094 1111.621094 L 17 1111.621094 Z M 17 17 ', clipRule: 'nonzero' }))),
                                        React.createElement("g", { clipPath: 'url(#242bc95391)' },
                                            React.createElement("path", { fill: '#58595e', d: 'M 899.570312 27.953125 C 885.894531 14.277344 861.675781 14.277344 848 27.953125 L 105.273438 770.714844 C 105.238281 770.75 105.207031 770.78125 105.167969 770.820312 L 105.0625 770.925781 C 105 770.988281 104.984375 771.066406 104.925781 771.128906 C 101.964844 774.121094 99.746094 777.730469 97.960938 781.613281 C 97.527344 782.558594 97.117188 783.457031 96.769531 784.425781 C 96.457031 785.292969 95.945312 786.054688 95.695312 786.953125 L 18.585938 1065.148438 C 15.097656 1077.828125 18.65625 1091.394531 27.953125 1100.691406 C 34.898438 1107.636719 44.195312 1111.375 53.738281 1111.375 C 56.980469 1111.375 60.257812 1110.949219 63.496094 1110.058594 L 341.691406 1032.949219 C 342.589844 1032.699219 343.351562 1032.1875 344.21875 1031.875 C 345.191406 1031.527344 346.085938 1031.117188 347.03125 1030.683594 C 350.914062 1028.898438 354.527344 1026.679688 357.515625 1023.71875 C 357.578125 1023.660156 357.65625 1023.644531 357.71875 1023.582031 L 357.828125 1023.476562 C 357.863281 1023.4375 357.894531 1023.40625 357.929688 1023.371094 L 1100.691406 280.644531 C 1107.527344 273.808594 1111.375 264.53125 1111.375 254.859375 C 1111.375 245.191406 1107.527344 235.914062 1100.691406 229.074219 Z M 749.382812 229.71875 L 898.925781 379.261719 L 331.929688 946.226562 L 182.417969 796.714844 Z M 106.09375 1022.550781 L 149.296875 866.734375 L 261.910156 979.347656 Z M 950.5 327.691406 L 800.953125 178.144531 L 873.785156 105.308594 L 1023.335938 254.859375 Z M 950.5 327.691406 ', fillOpacity: '1', fillRule: 'nonzero' }))))),
                            React.createElement("h1", null,
                                "Hej ",
                                props.data.name),
                            React.createElement("div", { className: 'addressContainer' },
                                React.createElement("div", { className: 'estateAddress' },
                                    React.createElement("div", { className: 'estateAddress_icon' },
                                        React.createElement("svg", { width: '38px', height: '40px', viewBox: '0 0 100 100', xmlns: 'http://www.w3.org/2000/svg', "aria-hidden": 'true', role: 'img', preserveAspectRatio: 'xMidYMid meet' },
                                            React.createElement("path", { className: 'svg-color', d: 'M50.002 0C30.763 0 15 15.718 15 34.902c0 7.432 2.374 14.34 6.392 20.019L45.73 96.994c3.409 4.453 5.675 3.607 8.51-.235l26.843-45.683c.542-.981.967-2.026 1.338-3.092A34.446 34.446 0 0 0 85 34.902C85 15.718 69.24 0 50.002 0zm0 16.354c10.359 0 18.597 8.218 18.597 18.548c0 10.33-8.238 18.544-18.597 18.544c-10.36 0-18.601-8.215-18.601-18.544c0-10.33 8.241-18.548 18.6-18.548z' }))),
                                    React.createElement("div", { className: 'estateAddress_text' },
                                        React.createElement("div", { className: 'estateAddress_text1' }, "Din flytning til"),
                                        React.createElement("div", { className: 'estateAddress_text2' }, estateAddress)))),
                            React.createElement("div", { className: 'dateContainer' },
                                React.createElement("div", { className: 'dateContainer_text' }, "Dato for overtagelse:"),
                                React.createElement("div", { className: 'dateContainer_date' }, takeOverDate)),
                            React.createElement(Column, { offset: { tablet: 1 }, width: { tablet: 4 } },
                                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall' })))))))));
};
export default TopBanner;
