import { __rest } from "tslib";
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import './row.scss';
const Row = forwardRef((_a, ref) => {
    var { children, className, pageHeight, pushTop, pushBottom, spaceBetween, alignCenter, center, noAdjacentMargin } = _a, rest = __rest(_a, ["children", "className", "pageHeight", "pushTop", "pushBottom", "spaceBetween", "alignCenter", "center", "noAdjacentMargin"]);
    const classList = classNames('row', className, {
        alignCenter,
        center,
        noAdjacentMargin,
        pageHeight,
        pushBottom,
        pushTop,
        spaceBetween,
    });
    return (React.createElement("div", Object.assign({ ref: ref }, rest, { className: classList }), children));
});
Row.displayName = 'Row';
export default Row;
