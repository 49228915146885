import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import Settle_Planner from './pages/Planner/Settle_Planner';
import Unknown_User from './pages/UnknownUser/Unknown_User';
import './styles/app.scss';
import './styles/theming.scss';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import Typography from './components/atoms/typography/typography';
import { datastore } from './dataStore';
const GET_DATA = gql `
  query getCustomerByExternalId($id: String!) {
    getCustomerByExternalId(id: $id) {
      id
      name
      email
      phone
      hasConsent
      realEstateBrokerCases {
        id
        caseNo
        realEstateBrokerOffice {
          id
          realEstateBroker {
            id
            plannerTop {
              id
              mediaUrl
            }
            logo {
              id
              mediaUrl
            }
            mainColor
          }
        }
        primaryimageUrl
        address {
          fullAddress
        }
        movingDetails {
          id
          customerType
          dispositionDate
          moveInDate
          moveOutDate
          takeoverDate
          handover {
            id
          }
          moveToAddress {
            fullAddress
            zipCode
            city
          }
          currentAddress {
            fullAddress
          }
          plannerCategories {
            id
            heading
            subHeading
            plannerItemsCustom {
              id
              heading
              text
              removed
              completed
            }
            plannerItems {
              id
              heading
              subHeading
              text
              hasProducts
              removed
              completed
              itemType
              links {
                id
                text
                url
                sorting
                logo {
                  id
                  mediaUrl
                  mimeType
                }
              }
              icon {
                mediaUrl
                mimeType
              }
              media {
                id
                heading
                text
                mediaUrl
                mimeType
              }
              productCategories {
                id
                name
                heading
                text
                buttonText
                completed
                icon {
                  mediaUrl
                  mimeType
                }
                media {
                  id
                  heading
                  text
                  mediaUrl
                  mimeType
                }
                links {
                  id
                  text
                  url
                  sorting
                  logo {
                    id
                    mediaUrl
                    mimeType
                  }
                }
                products {
                  id
                  name
                  sorting
                  ordered
                  leadId
                  hasPowerOfAttorneyDocument
                  partnerName
                  partnerLogo {
                    mediaUrl
                    filename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export var backendAPI;
(function (backendAPI) {
    backendAPI["localhost"] = "localhost";
    backendAPI["stage"] = "stage";
    backendAPI["live"] = "live";
})(backendAPI || (backendAPI = {}));
const clientLocalhost = new ApolloClient({
    uri: 'http://localhost:8080/external',
    cache: new InMemoryCache(),
    headers: {
        authorization: 'Bearer 4a187441ff064919be440a5b4efe3ac922554bd06faa49f9ba23353bf46ce9329481e13b483c442ba06ca58f0e2f830a6a93b204a0b446d68c3326f1955a9a65',
    },
});
const client = new ApolloClient({
    uri: 'https://api.settle.dk/external',
    cache: new InMemoryCache(),
    headers: {
        authorization: 'Bearer 4a187441ff064919be440a5b4efe3ac922554bd06faa49f9ba23353bf46ce9329481e13b483c442ba06ca58f0e2f830a6a93b204a0b446d68c3326f1955a9a65',
    },
});
const stageClient = new ApolloClient({
    uri: 'https://stage.api.settle.dk/external',
    cache: new InMemoryCache(),
    headers: {
        authorization: 'Bearer 4a187441ff064919be440a5b4efe3ac922554bd06faa49f9ba23353bf46ce9329481e13b483c442ba06ca58f0e2f830a6a93b204a0b446d68c3326f1955a9a65',
    },
});
const Settle = (props) => {
    var _a;
    const { userId, backendApi } = props;
    const [data, setData] = useState(null);
    const [externalUserId, setexternalUserId] = useState('');
    const [registationFlow, setRegistationFlow] = useState(null);
    const [realEstateBrokerId, setRealEstateBrokerId] = useState(null);
    if (props.registationFlow) {
        if (!registationFlow) {
            setRegistationFlow(props.registationFlow);
        }
    }
    if (props.realEstateBrokerId) {
        if (!realEstateBrokerId) {
            setRealEstateBrokerId(props.realEstateBrokerId);
        }
    }
    let myClient = client;
    if (backendApi) {
        switch (backendApi) {
            case backendAPI.live:
                myClient = client;
                break;
            case backendAPI.stage:
                myClient = stageClient;
                break;
            case backendAPI.localhost:
                myClient = clientLocalhost;
                break;
            default:
                myClient = clientLocalhost;
                break;
        }
    }
    if (userId) {
        if (userId != externalUserId) {
            setexternalUserId(userId);
            setData(null);
        }
    }
    useEffect(() => {
        if (data === null) {
            fetchData(); // Fetch data when the component mounts
        }
    });
    const onCreateNewUser = () => {
        alert('create new user');
    };
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (externalUserId !== 'xxx') {
            try {
                const { data } = yield myClient.query({
                    query: GET_DATA,
                    variables: { id: externalUserId },
                    //fetchPolicy: 'network-only',
                    fetchPolicy: 'no-cache',
                });
                // console.log('Raw data: ', data)
                setData(data); // Set the retrieved data to state
                const root = document.documentElement;
                if (data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.mainColor.trim() !=
                    '') {
                    root.style.setProperty('--mainBrandingColor', data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.mainColor);
                }
                if (data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.plannerTop) {
                    const topbannerUrl = data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.plannerTop
                        .mediaUrl;
                    if (topbannerUrl.trim() != '') {
                        datastore.data.topBannerUrl = topbannerUrl;
                    }
                }
                if (data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.logo) {
                    const realEstateBrokerLogoUrl = data.getCustomerByExternalId.realEstateBrokerCases[0].realEstateBrokerOffice.realEstateBroker.logo.mediaUrl;
                    if (realEstateBrokerLogoUrl != '') {
                        datastore.data.realEstateBrokerLogoUrl = realEstateBrokerLogoUrl;
                    }
                }
            }
            catch (error) {
                console.error('error getting data from server:', error);
            }
        }
    });
    const forceReRender = () => {
        fetchData();
    };
    //if (data) {
    //console.log('data.getCustomerByExternalId:', data.getCustomerByExternalId)
    //}
    if (externalUserId == 'xxx') {
        return (React.createElement("div", { className: 'centerText' },
            React.createElement("span", { className: 'gg-arrow-up' }),
            React.createElement("div", null,
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodyMedium', className: 'unknownUserConsent', style: { margin: '0' }, dangerouslySetInnerHTML: {
                        __html: 'Vælg kunde',
                    } }))));
    }
    else {
        if (data) {
            if ((_a = data.getCustomerByExternalId) === null || _a === void 0 ? void 0 : _a.id) {
                return (React.createElement("div", { className: 'flytNemt_bg_color' },
                    React.createElement(ApolloProvider, { client: myClient },
                        React.createElement(Settle_Planner, { userData: data.getCustomerByExternalId, forceReRender: forceReRender }))));
            }
            else {
                return (React.createElement("div", { className: 'flytNemt_bg_color', style: { height: '100vh' } },
                    React.createElement(Unknown_User, { onConcentApproved: null, registationFlow: true, onCreateNewUser: onCreateNewUser })));
            }
        }
        else {
            return (React.createElement("div", { className: 'flytNemt_bg_color' },
                React.createElement("div", { className: 'centerText' },
                    React.createElement("span", { className: 'loader' }),
                    React.createElement("div", null,
                        React.createElement("br", null),
                        React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', className: 'unknownUserConsent', style: { margin: '0' }, dangerouslySetInnerHTML: {
                                __html: 'Øjeblik<br/>Opbygger din personlige flytteplan.',
                            } })))));
        }
    }
};
export default Settle;
