import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      heat {
        meterNo
        meterType
        gasMeterValue
        olieMeterValue
        pillFireMeterValue
        centralHeatingM3MeterValue
        centralHeatingMWHMeterValue
        centralHeatingMKWHMeterValue
        readingDate
        supplier
        notes
        woodTakeOver
        woodTakeOverPrice
        image1Url
        image2Url
        image3Url
      }
    }
  }
`;
const UPDATE_HEAT_INFO_GQL = gql `
  mutation updateHeat($input: UpdateHeatInput!) {
    updateHeat(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_HEAT_INFO_GQL };
