// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React, { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataCard from "./components/DataCard"
import DataSelectorCard from "./components/DataSelectorCard"
import { useQuery, gql } from "@apollo/client";


const BILLING_REPORT_ESTATESYSTEMS = gql`
query BILLING_REPORT_ESTATESYSTEMS($input: BillingReportEstateSystemInput){
    billingReportEstateSystems(input: $input){
        realEstateBrokerOffice{
            name
            bankAccountNumber
            address{
                fullAddress
            }
            phone
            realEstateBroker{
                name
            }
        }
        affiliatePartnerProduct{
            name
        }
        amount
        itemPrice
        totalPrice
    }
    affiliatePartnerProducts{
        id
        name
    }
    realEstateBrokers{
        id
        name
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Ejendomsmægler kontor",
        accessor: "realEstateBrokerOffice.name",
    },
    {
        header: "Ejendomsmæglerkæde",
        accessor: "realEstateBrokerOffice.realEstateBroker.name",
        width: 100
    },
    {
        header: "Produkt",
        accessor: "affiliatePartnerProduct.name",
        width: 100
    },
    {
        header: "Antal",
        accessor: "amount",
        width: 100
    },
    {
        header: "pris. pr. stk.",
        accessor: "itemPrice",
        width: 100
    },
    {
        header: "pris total",
        accessor: "totalPrice",
        width: 100
    },
];


function BillingReportRealEstateSystems() {
    // Chakra color mode
    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
        productIds: [],
        realEstateBrokerIds: []
    });

   
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );


    const { loading, error, data } = useQuery(BILLING_REPORT_ESTATESYSTEMS, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                productIds: requestData.productIds,
                realEstateBrokerIds: requestData.realEstateBrokerIds
            }
        },
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Fakturering til mæglersystemerne"}
                description={'Fakturering rapport, for ejendomsmæglersystemerne. Filteret på dato, produkt'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={3} rowSpan={2} >

                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Total antal produkter'} amount={0} />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Beløb total'} amount={0} />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting stats from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting stats from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, partnerProductSelected, realEstateBrokerSelected) => {
        let productIds = [];
        if (!Array.isArray(partnerProductSelected)) {
            if (partnerProductSelected.indexOf(',')) {
                productIds = partnerProductSelected.split(',')
            } else {
                productIds.push(partnerProductSelected);
            }
        }

        let realEstateBrokerIds = [];
        if (!Array.isArray(realEstateBrokerSelected)) {
            if (realEstateBrokerSelected.indexOf(',')) {
                realEstateBrokerIds = realEstateBrokerSelected.split(',');
            } else {
                realEstateBrokerIds.push(realEstateBrokerSelected);
            }
        }

        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            productIds: productIds,
            realEstateBrokerIds: realEstateBrokerIds
        })
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.billingReportEstateSystems));
    let pricesTotal = 0;
    let productsTotal = 0;
    for (var i = 0; i < tableData.length; i++) {
        tableData[i].id = i + 1;
        pricesTotal = pricesTotal + tableData[i].totalPrice
        productsTotal = productsTotal + tableData[i].amount;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    pricesTotal = numberWithCommas(pricesTotal);

    var csvData = tableData.map(item => (
        {
            Ejendomsmægler: item.realEstateBrokerOffice.name,
            mæglerkæde: item.realEstateBrokerOffice.realEstateBroker.name,
            produkt: item.affiliatePartnerProduct.name,
            antal: item.amount,
            prisPrStk: item.itemPrice,
            prisIalt: item.totalPrice
        }
    ));

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Fakturering til mæglersystemerne"}
                description={'Fakturering rapport, for ejendomsmæglersystemerne. Filteret på dato, produkt'}
            />

            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={3} rowSpan={2} >
                    <DataSelectorCard
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                        partnerProducts={data.affiliatePartnerProducts}
                        partnerProductSelected={requestData.productIds.toString()}
                        realEstateBrokers={data.realEstateBrokers}
                        realEstateBrokerSelected={requestData.realEstateBrokerIds.toString()}
                    />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Total antal produkter'} amount={productsTotal} />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Beløb total'} amount={pricesTotal} />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>
        </Flex>
    );
}

export default BillingReportRealEstateSystems;