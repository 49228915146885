import React, { useState, useEffect } from "react";
// Chakra imports
import { Box, Flex, Button, FormControl, FormLabel, Heading, Input, Text, useColorModeValue } from "@chakra-ui/react";
// Assets

import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

const loginMutationGql = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      firstname
      lastname
      type
      active
      token
    }
  }
`;

function SignIn() {
  const [loginMutate] = useMutation(loginMutationGql);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const history = useHistory();
  const { user, setUser } = useAuth();
  // Chakra color mode
  const titleColor = useColorModeValue("blackAlpha.600", "gray.200");
  const textColor = useColorModeValue("blackAlpha.600", "white");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgotPasswordClicked = () => {
    history.push("/auth/forgotpassword");
  };

  useEffect(() => {
    localStorage.clear();
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();

    loginMutate({ variables: formData }).then((response) => {
      console.log('response:', response);
      if (response.data.login) {
        localStorage.setItem("token", response.data.login.token);

        return setProfile(response);
      } else {
        return setError("Email eller adgangskode kunne ikke genkendes");
      }
    }).catch(() => {
      setError("Email eller adgangskode kunne ikke genkendes");
    });
  };

  const setProfile = (response) => {
    let user = { ...response.data.login };
    user.token = response.data.login.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/dashboard");
  };
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        {user && user.token ? (
          <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
            <div style={{ position: 'absolute', top: '30px', left: '50px', fontSize: '30px', color: '#ff614d' }}>Settle</div>
            <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: "150px", lg: "80px" }}>
              <Heading color={titleColor} fontSize="32px" mb="10px">
                Du er allerede logget ind.
              </Heading>
            </Flex>
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
            <div style={{ position: 'absolute', top: '30px', left: '50px', fontSize: '30px', color: '#ff614d' }}>Settle</div>
            <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: "150px", lg: "80px" }}>
              <Heading color={titleColor} fontSize="32px" mb="10px">
                Velkommen
              </Heading>
              <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
                indtast dine oplysninger
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  placeholder="Din email adresse"
                  size="lg"
                  onChange={handleChange}
                  name="email"
                  value={formData?.email}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Adgangskode
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  type="password"
                  placeholder="Din adgangskode"
                  size="lg"
                  onChange={handleChange}
                  name="password"
                  value={formData?.password}
                />

                <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
                  <Text color="red" marginTop="10px" fontWeight="medium">
                    {error}
                  </Text>
                </Flex>
                <Button
                  onClick={handleSubmit}
                  fontSize="10px"
                  type="submit"
                  bg="blackAlpha.600"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "blackAlpha.500",
                  }}
                  _active={{
                    bg: "blackAlpha.600",
                  }}
                >
                  LOGIN
                </Button>
              </FormControl>
              <p
                onClick={() => {
                  handleForgotPasswordClicked();
                }}
                style={{ cursor: "pointer", color: '#A0AEC0' }}
              >
                <u>Glemt adgangskode</u>
              </p>
            </Flex>
          </Flex>
        )}
        <Box display={{ base: "none", md: "block" }} overflowX="hidden" h="100%" w="40vw" position="absolute" right="0px">
          <Box bgColor={titleColor} w="100%" h="100%" bgSize="cover" bgPosition="50%" position="absolute" borderBottomLeftRadius="20px" className="loginImage">

          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
