import './linkBox.scss';
import React from 'react';
import Typography from '../../../../components/atoms/typography/typography';
const LinkBox = (props) => {
    const handleLinkClicked = () => {
        window.open(props.link.url, '_blank');
    };
    return (React.createElement("div", { className: 'LinkBoxContainer', onClick: () => handleLinkClicked() },
        React.createElement("div", { className: 'LinkBoxLeftContainer' },
            React.createElement("div", { className: 'LinkBoxProductNameContainer' },
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', className: 'linkText', dangerouslySetInnerHTML: { __html: props.link.text } }))),
        React.createElement("div", { className: 'LinkBoxProductLogoContainer' }, props.link.logo && React.createElement("img", { src: props.link.logo.mediaUrl, className: 'LinkBoxProductLogo' }))));
};
export default LinkBox;
