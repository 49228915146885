import { Flex, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import Header from "./../../../components/Headers/Header";
import { useQuery, useMutation } from "@apollo/client";
import MovingPlannerCategoriesContainer from "./MovingPlannerCategoriesContainer.js";
import { GET_PLANNERCATEGORIES, CREATE_CATEGORY_GQL, DELETE_CATEGORY_GQL, UPDATE_CATEGORY_SORTING_GQL, UPDATE_CATEGORY_GQL } from "./graphQL";
import { useParams } from "react-router-dom";

function MovingPlannerCategoriesRenderer() {
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  let { realEstateBrokerId } = useParams();

  if (!realEstateBrokerId){
    realEstateBrokerId = 'default'
  }
  
  const { loading, error, data } = useQuery(GET_PLANNERCATEGORIES, {
    variables: { realEstateBrokerId: realEstateBrokerId },
    fetchPolicy: "network-only",
  });

  const [createCategory, ] = useMutation(CREATE_CATEGORY_GQL, { refetchQueries: [GET_PLANNERCATEGORIES] });

  const [deleteCategory, ] = useMutation(DELETE_CATEGORY_GQL, { refetchQueries: [GET_PLANNERCATEGORIES] });

  const [updateCategorySorting, ] = useMutation(UPDATE_CATEGORY_SORTING_GQL, { refetchQueries: [GET_PLANNERCATEGORIES] });

  const [editCategory] = useMutation(UPDATE_CATEGORY_GQL, { refetchQueries: [GET_PLANNERCATEGORIES] });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting data from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting data from server (2)</p>;
  }

  const updateRealEstateBrokerfn = (realEstateBrokerSelectedId) => {
    document.location.href = '/#admin/flytteplanners/' + realEstateBrokerSelectedId
  };

  const createNewCategoryfn = (categoryName, categoryDescription) => {
    createCategory({
      variables: {
        heading: categoryName,
        subHeading: categoryDescription,
        realEstateBrokerId: realEstateBrokerId,
      },
    });
  };

  const deleteCategoryfn = (categoryId) => {
    deleteCategory({
      variables: {
        id: categoryId,
      },
    });
  };

  const updateSortingfn = (categories) => {
    var result = categories.map((category) => ({ id: category.id, sorting: category.sorting }));
    updateCategorySorting({
      variables: {
        input: result,
      },
    });
  };

  const editCategoryfn = (categoryId, categoryName, categoryDescription) => {
    editCategory({
      variables: {
        id: categoryId,
        heading: categoryName,
        subHeading: categoryDescription,
      },
    });
  };

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Flytte-planner"}
        description={"Flytte-planner pr. ejendomsmægler kæde"}
      />

      <MovingPlannerCategoriesContainer
        data={data}
        selectedRealEstateBroker={realEstateBrokerId}
        updateRealEstateBroker={updateRealEstateBrokerfn}
        updateSorting={updateSortingfn}
        deleteCategory={deleteCategoryfn}
        createNewCategory={createNewCategoryfn}
        editCategory={editCategoryfn}
      ></MovingPlannerCategoriesContainer>
    </Flex>
  );
}

export default MovingPlannerCategoriesRenderer;
