// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Input,
  useToast,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { SingleDatepicker } from "chakra-dayzed-datepicker";

const UPDATE_CUSTOMER_Gql = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const UPDATE_CUSTOMER_MOVING_DETAILS_Gql = gql`
  mutation updateCustomerMovingDetails(
    $input: UpdateCustomerMovingDetailsInput!
  ) {
    updateCustomerMovingDetails(input: $input) {
      id
    }
  }
`;

const LeadCustomerInfo = ({ data, forceRerender }) => {
  const toast = useToast();
  const [updateCustomerInfo] = useMutation(UPDATE_CUSTOMER_Gql);
  const [updateCustomerMovingDetails] = useMutation(
    UPDATE_CUSTOMER_MOVING_DETAILS_Gql
  );
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (customerId, field, data) => {
    const customerMovingDetailsFields = [
      "moveToFullAddress",
      "takeoverDate",
      "customerType",
    ];

    let inputData = {};
    inputData.id = customerId;
    inputData[field] = data;

    if (customerMovingDetailsFields.indexOf(field) > -1) {
      updateCustomerMovingDetails({
        variables: { input: inputData },
      })
        .then(() => {
          forceRerender();

          toast({
            title: "Ændringen er blevet gemt.",
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        })
        .catch((error) => {
          if (error.graphQLErrors[0].message === "Error validating address") {
            toast({
              title: "Opdatering af lead fejlede.",
              description: "Adressen er ikke valid.",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Opdatering af lead fejlede.",
              description: "Det var ikke muligt at opdatere lead´et.",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          }
        });
    } else {
      updateCustomerInfo({
        variables: { input: inputData },
      })
        .then(() => {
          forceRerender();

          toast({
            title: "Ændringen er blevet gemt.",
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        })
        .catch((error) => {
          if (error.graphQLErrors[0].message === "Error validating address") {
            toast({
              title: "Opdatering af lead fejlede.",
              description: "Adressen er ikke valid.",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Opdatering af lead fejlede.",
              description: "Det var ikke muligt at opdatere lead´et.",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          }
        });
    }
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Kunde information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Navn:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="name"
              defaultValue={data.customer.name}
              onBlur={(e) =>
                updateData(data.customer.id, "name", e.target.value)
              }
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Telefon nr:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="phone"
              defaultValue={data.customer.phone}
              onBlur={(e) =>
                updateData(data.customer.id, "phone", e.target.value)
              }
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              E-mail:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="email"
              defaultValue={data.customer.email}
              onBlur={(e) =>
                updateData(data.customer.id, "email", e.target.value)
              }
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              CVR:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="cvr"
              defaultValue={data.customer.cvr}
              onBlur={(e) =>
                updateData(data.customer.id, "cvr", e.target.value)
              }
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Flytter til:{" "}
            </Text>
            <Input
              variant="flushed"
              type="text"
              name="moveToFullAddress"
              defaultValue={data.movingDetails.moveToAddress.fullAddress}
              onBlur={(e) =>
                updateData(
                  data.movingDetails.id,
                  "moveToFullAddress",
                  e.target.value
                )
              }
            />
          </Flex>

          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Overtagelsesdato:{" "}
            </Text>
            <SingleDatepicker
              date={new Date(data.movingDetails.takeoverDate)}
              onDateChange={(value) => {
                updateData(data.movingDetails.id, "takeoverDate", value);
              }}
              configs={{
                dateFormat: "yyyy-MM-dd",
              }}
              propsConfigs={{
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    borderColor: "yellow.300",
                    _hover: {
                      background: "yellow.400",
                    },
                  },
                  isInRangeBtnProps: {
                    background: "yellow.300",
                    color: "black",
                  },
                  selectedBtnProps: {
                    background: "yellow.300",
                    color: "black",
                  },
                },
              }}
            />
          </Flex>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Kunde type:{" "}
            </Text>
            <Select
              multiple={false}
              value={data.movingDetails.customerType}
              onChange={(e) => {
                updateData(
                  data.movingDetails.id,
                  "customerType",
                  e.target.value
                );
              }}
            >
              <option key={"UNKNOWN"} value="UNKNOWN">
                Ukendt
              </option>
              <option key={"BUYER"} value="BUYER">
                Køber
              </option>
              <option key={"SELLER"} value="SELLER">
                Sælger
              </option>
            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default LeadCustomerInfo;
