//import { format } from 'date-fns'
import { da } from 'date-fns/locale';
import Moment from 'moment';
import { usePopper } from 'react-popper';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './date-picker.scss';
import React, { useRef, useState } from 'react';
const DatePicker = (props) => {
    //const [fieldName, setFieldName] = useState<string>(props.fieldName)
    //const [selected, setSelected] = useState<Date | undefined>(props.selected as Date)
    let inputValue = Moment(props.selected).format('DD-MM-yy'); // format(props.selected as Date, 'dd-MM-y')
    //const [inputValue, setInputValue] = useState<string | undefined>(format(props.selected as Date, 'y-MM-dd'))
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const popperRef = useRef(null);
    const buttonRef = useRef(null);
    const [popperElement, setPopperElement] = useState(null);
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    });
    let inputDate = props.selected;
    if (inputValue.indexOf('1970') > -1) {
        inputValue = '';
        inputDate = new Date();
        // setInputValue(undefined)
    }
    const closePopper = () => {
        var _a;
        setIsPopperOpen(false);
        (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const handleInputChange = () => {
        //console.log('handleInputChange', e.currentTarget.value)
    };
    const handleButtonClick = () => {
        if (isPopperOpen) {
            setIsPopperOpen(false);
        }
        else {
            //if (!inputValue) {
            //  inputValue = format(new Date(), 'y-MM-dd')
            // }
            setIsPopperOpen(true);
        }
    };
    const handleDaySelect = (date) => {
        //setSelected(date)
        if (date) {
            inputValue = Moment(date).format('DD-MM-y'); //  format(date, 'dd-MM-y') //'y-MM-dd'
            // setInputValue(format(date, 'y-MM-dd'))
            closePopper();
            props.onSelect(props.fieldName, Moment(date).format('y-MM-DD')); //format(date, 'y-MM-dd')
        }
        else {
            inputValue = '';
            //setInputValue('')
        }
    };
    if (!inputValue) {
        inputValue = '';
    }
    const fieldClass = props.error ? 'dateInputField-error' : 'dateInputField';
    //console.log('Date:', inputDate)
    return (React.createElement("div", null,
        React.createElement("div", { ref: popperRef, className: 'dateInputFieldIconWrapper' },
            React.createElement("div", { className: 'dateInputFieldIcon' },
                React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '28', zoomAndPan: 'magnify', viewBox: '0 0 1125 1124.99995', preserveAspectRatio: 'xMidYMid meet', version: '1.0' },
                    React.createElement("defs", null,
                        React.createElement("clipPath", { id: '0ce12eefa9' },
                            React.createElement("path", { d: 'M 17.441406 135.179688 L 1107.570312 135.179688 L 1107.570312 1071.199219 L 17.441406 1071.199219 Z M 17.441406 135.179688 ', clipRule: 'nonzero' })),
                        React.createElement("clipPath", { id: 'bf527d0e5c' },
                            React.createElement("path", { d: 'M 811 53.808594 L 879 53.808594 L 879 254 L 811 254 Z M 811 53.808594 ', clipRule: 'nonzero' }))),
                    React.createElement("path", { fill: '#58595e', d: 'M 336.613281 172.484375 L 781.839844 172.484375 C 785.949219 172.484375 789.285156 169.15625 789.285156 165.042969 L 789.285156 142.707031 C 789.285156 138.597656 785.949219 135.261719 781.839844 135.261719 L 336.613281 135.261719 C 332.503906 135.261719 329.171875 138.597656 329.171875 142.707031 L 329.171875 165.042969 C 329.171875 169.15625 332.503906 172.484375 336.613281 172.484375 ', fillOpacity: '1', fillRule: 'nonzero' }),
                    React.createElement("g", { clipPath: 'url(#0ce12eefa9)' },
                        React.createElement("path", { fill: '#58595e', d: 'M 991.652344 1033.972656 L 133.363281 1033.972656 C 89.898438 1033.972656 54.667969 998.742188 54.667969 955.277344 L 54.667969 431.429688 L 1070.339844 431.429688 L 1070.339844 955.277344 C 1070.339844 998.742188 1035.109375 1033.972656 991.652344 1033.972656 Z M 991.652344 135.257812 L 908.410156 135.257812 C 904.296875 135.257812 900.964844 138.59375 900.964844 142.707031 L 900.964844 165.039062 C 900.964844 169.152344 904.296875 172.488281 908.410156 172.488281 L 991.652344 172.488281 C 1035.054688 172.488281 1070.339844 207.773438 1070.339844 251.257812 L 1070.339844 394.203125 L 54.667969 394.203125 L 54.667969 251.257812 C 54.667969 207.773438 89.957031 172.488281 133.363281 172.488281 L 210.046875 172.488281 C 214.160156 172.488281 217.492188 169.152344 217.492188 165.039062 L 217.492188 142.707031 C 217.492188 138.59375 214.160156 135.257812 210.046875 135.257812 L 133.363281 135.257812 C 69.484375 135.257812 17.441406 187.300781 17.441406 251.257812 L 17.441406 955.277344 C 17.441406 1019.035156 69.605469 1071.199219 133.363281 1071.199219 L 991.652344 1071.199219 C 1055.410156 1071.199219 1107.574219 1019.035156 1107.574219 955.277344 L 1107.574219 251.257812 C 1107.574219 187.300781 1055.527344 135.257812 991.652344 135.257812 ', fillOpacity: '1', fillRule: 'nonzero' })),
                    React.createElement("path", { fill: '#58595e', d: 'M 273.332031 263.097656 C 291.796875 263.097656 306.835938 248.128906 306.835938 229.59375 L 306.835938 96.546875 C 306.835938 78.011719 291.796875 63.042969 273.332031 63.042969 C 254.792969 63.042969 239.828125 78.011719 239.828125 96.546875 L 239.828125 229.59375 C 239.828125 248.128906 254.792969 263.097656 273.332031 263.097656 ', fillOpacity: '1', fillRule: 'nonzero' }),
                    React.createElement("g", { clipPath: 'url(#bf527d0e5c)' },
                        React.createElement("path", { fill: '#58595e', d: 'M 845.121094 253.9375 C 863.59375 253.9375 878.628906 238.894531 878.628906 220.429688 L 878.628906 87.308594 C 878.628906 68.847656 863.59375 53.8125 845.121094 53.8125 C 826.585938 53.8125 811.625 68.847656 811.625 87.308594 L 811.625 220.429688 C 811.625 238.894531 826.585938 253.9375 845.121094 253.9375 ', fillOpacity: '1', fillRule: 'nonzero' })))),
            React.createElement("input", { disabled: props.disabled, size: 12, type: 'text', className: fieldClass, placeholder: 'V\u00E6lg dato', value: inputValue, onClick: handleButtonClick, onChange: handleInputChange })),
        isPopperOpen && (React.createElement("div", Object.assign({ tabIndex: -1, style: popper.styles.popper, className: 'dialog-sheet' }, popper.attributes.popper, { ref: setPopperElement, role: 'dialog', "aria-label": 'DayPicker calendar' }),
            React.createElement(DayPicker, { mode: 'single', locale: da, defaultMonth: inputDate, selected: inputDate, onSelect: handleDaySelect })))));
};
export default DatePicker;
