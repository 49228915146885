import './videoBox.scss';
import React, { useState, useRef } from 'react';
const VideoBox = (props) => {
    var _a, _b;
    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);
    const overlayRef = useRef(null);
    const handleVideoPreviewClicked = () => {
        setShowVideo(true);
    };
    const handleVideoOverlayClicked = (e) => {
        if (e.target.nodeName != 'VIDEO') {
            setShowVideo(false);
            videoRef.current.pause();
        }
    };
    if (overlayRef.current) {
        if (showVideo) {
            overlayRef.current.style.display = '';
            videoRef.current.play();
        }
        else {
            overlayRef.current.style.display = 'none';
            videoRef.current.pause();
        }
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: 'overlay', style: { display: 'none' }, onClick: (e) => handleVideoOverlayClicked(e), ref: overlayRef },
            React.createElement("div", { className: 'videoBoxPopupContainer' },
                React.createElement("video", { className: 'itemContentVideoElm', preload: 'auto', controls: true, ref: videoRef },
                    React.createElement("source", { src: (_a = props.media) === null || _a === void 0 ? void 0 : _a.mediaUrl, type: 'video/mp4' })))),
        React.createElement("div", { className: 'itemContentVideoPreview', onClick: () => handleVideoPreviewClicked() },
            React.createElement("video", { className: 'itemContentVideoElmPreview', preload: 'auto', controls: false },
                React.createElement("source", { src: (_b = props.media) === null || _b === void 0 ? void 0 : _b.mediaUrl, type: 'video/mp4' })),
            React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '58', height: '58', viewBox: '0 0 58 58', style: { zIndex: '100', position: 'absolute' } },
                React.createElement("g", { id: 'Play', transform: 'translate(-978 -756)' },
                    React.createElement("circle", { id: 'Ellipse_46', "data-name": 'Ellipse 46', cx: '29', cy: '29', r: '29', transform: 'translate(978 756)', className: 'svg-color' }),
                    React.createElement("path", { id: 'Icon_ionic-md-play', "data-name": 'Icon ionic-md-play', d: 'M6.75,3.656V18.834l11.9-7.589Z', transform: 'translate(996.299 773.755)', fill: '#fff' }))))));
};
export default VideoBox;
