import React, { useEffect, useState } from 'react';
import './handoverSession.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Button from '../../../../components/atoms/button/button';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HANDOVER_GQL, UPDATE_SESSION_INFO_GQL } from './graphQL';
const HandoverSession = ({ handoverId, onNext }) => {
    const { loading, error, data } = useQuery(GET_HANDOVER_GQL, {
        variables: { id: handoverId },
    });
    const [updateSessionInfo, { data: updateData, error: updateError }] = useMutation(UPDATE_SESSION_INFO_GQL, {
        refetchQueries: [{ query: GET_HANDOVER_GQL, variables: { id: handoverId } }],
    });
    // const [validate, setValidate] = useState<boolean>(false);
    const [componentData, setComponentData] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        if (!loading && !error && data) {
            setComponentData({
                cleanedProperly: data.getHandover.session.cleanedProperly,
                allKeysHandedOver: data.getHandover.session.allKeysHandedOver,
                allInstructionsForUsageHandedover: data.getHandover.session.allInstructionsForUsageHandedover,
                estateReviewed: data.getHandover.session.estateReviewed,
                notes: data.getHandover.session.notes,
                status: data.getHandover.status,
            });
        }
    }, [loading, error, data]);
    if (loading) {
        return React.createElement("p", null);
    }
    if (error) {
        console.log('get data Error:' + error.message);
        return React.createElement("p", null);
    }
    if (componentData == null) {
        return React.createElement("p", null);
    }
    if (updateError) {
        console.log('update Error:' + updateError.message);
    }
    if (updateData) {
        onNext();
    }
    const handleNextClicked = () => {
        //setValidate(true);
        let isvalid;
        isvalid = true;
        for (const key of Object.keys(componentData)) {
            const fieldValue = componentData[key];
            if (fieldValue === '' &&
                key !== 'notes' &&
                key !== 'imageMimeType' &&
                key !== 'imageFilename' &&
                key !== 'imageUrl' &&
                key !== 'imageBase64') {
                isvalid = false;
                console.log('Validate - Invalid field:', key);
            }
        }
        if (isvalid) {
            const updateObj = {
                handoverId: handoverId,
                cleanedProperly: componentData.cleanedProperly,
                allKeysHandedOver: componentData.allKeysHandedOver,
                allInstructionsForUsageHandedover: componentData.allInstructionsForUsageHandedover,
                estateReviewed: componentData.estateReviewed,
                notes: componentData.notes,
            };
            //setValidate(false);
            updateSessionInfo({
                variables: {
                    input: updateObj,
                },
            });
        }
        else {
            console.log('Validate - Not all fields are valid');
            forceUpdate();
        }
    };
    const updateCheckBoxField = (fieldName, fieldValue) => {
        const tmp = componentData;
        tmp[fieldName] = !fieldValue;
        setComponentData(Object.assign({}, tmp));
    };
    const updateField = (fieldName, fieldValue) => {
        const tmp = componentData;
        tmp[fieldName] = fieldValue;
        setComponentData(Object.assign({}, tmp));
    };
    const clickCheckBox = (e) => {
        e.target.previousElementSibling.firstChild.click();
    };
    const isLocked = componentData.status === 'DONE';
    return (React.createElement(Container, { className: 'handoverSession', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
        React.createElement(React.Fragment, null,
            React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'checkboxsHorizontal' },
                                React.createElement("div", null,
                                    React.createElement("input", { disabled: isLocked, type: 'checkbox', name: 'cleanedProperly', className: 'squareRadio', onClick: () => updateCheckBoxField('cleanedProperly', componentData.cleanedProperly), defaultChecked: componentData.cleanedProperly ? true : false })),
                                React.createElement("div", { className: 'RightCheckboxText', onClick: clickCheckBox }, "Ejendommen er afleveret i ryddeliggjort stand.")),
                            React.createElement("div", { className: 'checkboxsHorizontal' },
                                React.createElement("div", null,
                                    React.createElement("input", { disabled: isLocked, type: 'checkbox', name: 'allKeysHandedOver', className: 'squareRadio', onClick: () => updateCheckBoxField('allKeysHandedOver', componentData.allKeysHandedOver), defaultChecked: componentData.allKeysHandedOver ? true : false })),
                                React.createElement("div", { className: 'RightCheckboxText', onClick: clickCheckBox }, "Alle n\u00F8gler til ejendommen og evt. udhuse er udleveret.")),
                            React.createElement("div", { className: 'checkboxsHorizontal' },
                                React.createElement("div", null,
                                    React.createElement("input", { disabled: isLocked, type: 'checkbox', name: 'allInstructionsForUsageHandedover', className: 'squareRadio', onClick: () => updateCheckBoxField('allInstructionsForUsageHandedover', componentData.allInstructionsForUsageHandedover), defaultChecked: componentData.allInstructionsForUsageHandedover ? true : false })),
                                React.createElement("div", { className: 'RightCheckboxText', onClick: clickCheckBox }, "Brugsanvisninger til fyr, hvidevare mv. er overdraget i det omfang det findes.")),
                            React.createElement("div", { className: 'checkboxsHorizontal' },
                                React.createElement("div", null,
                                    React.createElement("input", { disabled: isLocked, type: 'checkbox', name: 'estateReviewed', className: 'squareRadio', onClick: () => updateCheckBoxField('estateReviewed', componentData.estateReviewed), defaultChecked: componentData.estateReviewed ? true : false })),
                                React.createElement("div", { className: 'RightCheckboxText', onClick: clickCheckBox }, "Ejendommen er gennemg\u00E5et.")),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Bem\u00E6rkninger:"),
                                React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.notes, className: 'fieldinput', onChange: (e) => updateField('notes', e.target.value) }))))),
                React.createElement(Column, { width: { tablet: 4 }, className: 'linksContainer' })),
            React.createElement(Row, null,
                React.createElement(Column, { width: { tablet: 8 }, style: { paddingRight: '38px' }, className: 'linksContainer' },
                    React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                        React.createElement(Button, { text: 'Næste', width: 180, grey: true, onClick: handleNextClicked })))))));
};
export default HandoverSession;
