import React from 'react';
import './Footer.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
//interface IProps {}
const Footer = () => {
    const handleLogout = () => {
        localStorage.removeItem('menuItemCategory');
        localStorage.removeItem('menuRootItem');
        localStorage.removeItem('state');
        window.location.href = '/';
    };
    return (React.createElement(Container, { className: 'rootCollapsibleTimeLine footerContainer', paddingTopReset: true, style: { paddingBottom: '0', position: 'relative' } },
        React.createElement(Row, { className: 'RootCallapsibleRow' },
            React.createElement(Column, { className: '', offset: { mobile: 0, tablet: 1 }, width: { mobile: 8, tablet: 6 } },
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'logo' }, "Settle"),
                    React.createElement("div", { className: 'linkItem' },
                        React.createElement("a", { className: 'link', href: 'https://settle.dk/persondata.html', target: '_blank', rel: 'noreferrer' }, "Persondatapolitik")),
                    React.createElement("div", { className: 'linkItem' },
                        React.createElement("a", { className: 'link', href: 'https://settle.dk/cookiepolitik.html', target: '_blank', rel: 'noreferrer' }, "Cookiepolitik")),
                    React.createElement("div", { className: 'linkItem' },
                        React.createElement("a", { className: 'link', href: 'https://settle.dk/handelsbetingelser.html', target: '_blank', rel: 'noreferrer' }, "Handelsbetingelser")),
                    React.createElement("div", { className: 'linkItem' },
                        React.createElement("a", { className: 'link', href: '#', onClick: () => {
                                handleLogout();
                            } }, "Log ud")),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'hideMobile' }, "CVR 43205307"),
                        React.createElement("div", { className: 'spacer hideMobile' }),
                        React.createElement("div", null,
                            React.createElement("a", { href: 'https://settle.dk', target: '_blank', rel: 'noreferrer' }, "www.settle.dk")),
                        React.createElement("div", { className: 'spacer' }),
                        React.createElement("div", null,
                            React.createElement("a", { href: 'mailto:kontakt@settle.dk' }, "kontakt@settle.dk"))))))));
};
export default Footer;
