import { gql } from "@apollo/client";

export const GET_PLANNER_ITEMS_GQL = gql`
query GET_PLANNER_ITEMS_GQL($id: ID!){
  plannerItemsCategory(id: $id){
    heading
    plannerItems{
      id
      heading
      subHeading
      text
      sorting
    }
  }
}`

export const GET_PLANNER_ITEM_GQL = gql`
query GET_PLANNER_ITEM_GQL($id: ID!){
  plannerItem(id: $id){
      id
      heading
      subHeading
      itemType
      text
      sorting
      icon {
        id
        filename
        mediaUrl
        heading
        text
      }
      media {
        id
        filename
        mediaUrl
        heading
        text
      }
      conditions{
        id
        conditionType
        field{
          id
          name
          fieldType
        }
        operator
        value
      }
  }
  conditionFields{
    id
    name
    fieldType
    values
  }
}`

export const CREATE_ITEM_GQL = gql`
    mutation createPlannerItem($input: CreatePlannerItemInput!){
      createPlannerItem(input:$input){
          id
      } 
    }`

export const UPDATE_ITEM_GQL = gql`
mutation updatePlannerItem($input: UpdatePlannerItemInput!){
    updatePlannerItem(input:$input){
        id
    } 
}`

export const DELETE_ITEM_GQL = gql`
mutation deletePlannerItem($id: ID!){
  deletePlannerItem(id:$id)
}`

export const UPDATE_ITEM_SORTING_GQL = gql`
mutation updatePlannerItemSorting($input: [updatePlannerItemSorting]){
  updatePlannerItemSorting(input:$input)
}`



export const GET_PLANNERCATEGORIES = gql`
query GET_PLANNERCATEGORIES($realEstateBrokerId: ID!){
  plannerItemsCategories(realEstateBrokerId: $realEstateBrokerId){
    id
    heading
    sorting
  }
  realEstateBrokers{
    id
    name
  }
}`

export const CREATE_CATEGORY_GQL = gql`
    mutation createPlannerItemCategory($heading: String!,$realEstateBrokerId: ID!){
      createPlannerItemCategory(heading:$heading, realEstateBrokerId:$realEstateBrokerId){
          id
      } 
    }`

export const DELETE_CATEGORY_GQL = gql`
mutation deletePlannerItemCategory($id: ID!){
  deletePlannerItemCategory(id:$id)
}`

export const UPDATE_CATEGORY_SORTING_GQL = gql`
mutation updatePlannerItemCategorySorting($input: [updatePlannerItemCategorySorting]){
  updatePlannerItemCategorySorting(input:$input)
}`

export const UPDATE_CATEGORY_GQL = gql`
mutation updatePlannerItemCategory($id: ID!, $heading: String!){
  updatePlannerItemCategory(id: $id, heading: $heading){
    id
  }
}`
