// Chakra imports
import { Text, useColorModeValue, Table, Tbody, Th, Thead, Tr, Td } from "@chakra-ui/react";

// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import moment from "moment";

const LeadLog = ({ data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card px="0px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader px="22px" mb="32px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Log
        </Text>
      </CardHeader>

      <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color="gray.400" fontSize="xs" key={"date"}>
                Dato
              </Th>
              <Th color="gray.400" fontSize="xs" key={"activity"}>
                Hændelse
              </Th>
              <Th color="gray.400" fontSize="xs" key={"type"}>
                Type
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.logs.map((log) => (
              <Tr key={log.id}>
                <Td minW={"140px"} maxW={"160px"}>
                  <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {moment(log.created).format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {log.activity}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {log.type}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default LeadLog;
