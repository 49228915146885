// Chakra imports
import { Flex, Icon, Table, Tbody, Text, Th, Thead, Tr, Td, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card.js";
import CardHeader from "./../../../../components/Card/CardHeader.js";
import React from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

const LeadsByEstateBroker = ({ data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const leadCount = data.reduce((accumulator, item) => accumulator + item.count, 0);

  return (
    <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="12px 0px 28px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            Lead fordelt på ejendomsmælerne den sidste måned.
          </Text>
          <Flex align="center">
            <Icon as={IoCheckmarkDoneCircleSharp} color="teal.300" w={8} h={8} pe="3px" />
            <Text>Antal leads ialt: {leadCount}</Text>
          </Flex>
        </Flex>
      </CardHeader>
      <Table variant="simple" color={textColor}>
        <Thead>
          <Tr my=".8rem" ps="0px">
            <Th color="gray.400" key={"CaptionName"} ps={"0px"} textAlign={"left"}>
              Navn
            </Th>
            <Th color="gray.400" key={"CaptionCount"} ps={"0px"} textAlign={"right"}>
              Antal
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => {
            return (
              <Tr key={row.name}>
                <Td minWidth={{ sm: "250px" }} ps="0px">
                  <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
                      {row.name}
                    </Text>
                  </Flex>
                </Td>
                <Td minWidth={{ sm: "250px" }} ps="0px">
                  <Flex align="right" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Text align="right" fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
                      {row.count}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
};

export default LeadsByEstateBroker;
