// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React, { useRef } from "react";
import { gql, useMutation } from "@apollo/client";

const uploadFileGql = gql`
  mutation uploadPlannerItemProductCategoryLinkLogo($input: UploadPlannerItemProductCategoryLinkLogoInput!) {
    uploadPlannerItemProductCategoryLinkLogo(input: $input)
  }
`;

const deleteFileGql = gql`
  mutation deletePlannerItemProductCategoryLinkLogo($input: DeletePlannerItemProductCategoryLinkLogoInput!) {
    deletePlannerItemProductCategoryLinkLogo(input: $input)
  }
`;

const MovingPlannerItemProductCategoryLinkLogoUpload = ({ data, forceRerender }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [uploadFile, { loading: loadingUpload,  data: dataUpload }] = useMutation(uploadFileGql);
  const [deleteFile, { loading: loadingDelete,  data: dataDelete }] = useMutation(deleteFileGql);

  const inputRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");

  if (!loadingUpload && dataUpload?.uploadAffiliatePartnerDoc) {
    setTimeout(() => {
      forceRerender();
    }, 1000);
  }

  if (!loadingDelete && dataDelete) {
    setTimeout(() => {
      forceRerender();
    }, 500);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleDeleteClick = () => {
    onOpen();
  };

  const deleteMedia = () => {
    onClose();

    const inputData = {
      plannerItemProductCategoryLinkId: data.id,
    };

    deleteFile({ variables: { input: inputData } });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  async function handleFileChange(e) {
    if (!e.target.files) {
      return;
    }

    const fileBase64 = await toBase64(e.target.files[0]);

    const inputData = {
      plannerItemProductCategoryLinkId: data.id,
      filename: e.target.files[0].name,
      mimeType: e.target.files[0].type,
      fileContentBase64: fileBase64,
    };

    uploadFile({ variables: { input: inputData } });
  }

  const downloadMedia = (docUrl) => {
    window.open(docUrl);
  };

  let showFile = false;

  if (data.logo) {
    showFile = true;
  }
  return (
    <Card p="16px" my={{ sm: "24px", xl: "24px" }}>
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Logo
        </Text>

        {!showFile ? (
          <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleUploadClick()}>
            Upload
          </Text>
        ) : (
          <Text fontSize="lg" color="gray.400" cursor="pointer" onClick={() => handleDeleteClick()}>
            Slet
          </Text>
        )}
        <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} />
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        {showFile && (
          <Stack direction="column" spacing="24px" w="100%" style={{ paddingTop: "10px" }}>
            <Flex align="center" w="100%">
              <Flex align="center">
                <img
                  src={data.logo.mediaUrl}
                  alt='Ikon for produkt kategorien'
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: "1px solid rgb(207 207 207)",
                    marginRight: "20px",
                  }}
                />

                <Flex direction="column" cursor="pointer" onClick={() => downloadMedia(data.logo.mediaUrl)}>
                  <Text fontSize="sm" fontWeight="bold" color={textColor}>
                    {data.logo.filename}
                  </Text>
                  <Text color="gray.400" fontSize="xs">
                    {data.logo.filename}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Stack>
        )}
      </CardBody>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteMedia}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet ikon
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteMedia} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default MovingPlannerItemProductCategoryLinkLogoUpload;
