// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";


const DataCard = ({ title, amount }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
 
  return (
    <Card p='16px' >
      <CardHeader p='12px 5px' mb='12px' style={{justifyContent:'center',marginBottom:'0px'}}>
        <Text fontSize='lg' color={textColor} fontWeight='bold' textAlign={'center'}>
          {title}: {amount}
        </Text>
      </CardHeader>
    </Card>
  );
};

export default DataCard;