import React, { useState, useRef, useEffect } from 'react';
import Typography from '../../../../components/atoms/typography/typography';
import './productCategoryChooser.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import ProductBox from '../productBox/productBox';
import ItemLinkBox from '../linkBox/linkBox';
import Button from '../../../../components/atoms/button/button';
import VideoBox from '../../../../components/molecules/videoBox/videoBox';
import PowerOfAttorneyInfoOverlay from '../PowerOfAttorneyInfoOverlay/PowerOfAttorneyInfoOverlay';
const ProductCategoryChooser = ({ data, title, onCreateLeads, onCancelLead, onNext, lastItem, customerId, estateCaseId, onProductCategoryChange, }) => {
    var _a;
    const [selectedProductCategory, setselectedProductCategory] = useState(data[0]);
    const [selectedProduct, setselectedProduct] = useState();
    const [showCancelLead, setshowCancelLead] = useState(false);
    //const [showFullText, setShowFullText] = useState<boolean>(false)
    const [showPowerOfAttorneyInfo, setShowPowerOfAttorneyInfo] = useState(false);
    const overlayRef = useRef(null);
    useEffect(() => {
        calulateTextView();
    }, []);
    if (data.length == 0) {
        return null;
    }
    const calulateTextView = () => {
        let addReadMore = false;
        if (document.getElementsByClassName('productsContainer').length == 1) {
            //console.log('Products found')
            const productBoxHeight = document.getElementsByClassName('productsContainer')[0].clientHeight;
            const productTextHeight = document.getElementsByClassName('selectedProductCategoryDescriptionColumn')[0]
                .clientHeight;
            if (productTextHeight > 200 && productTextHeight > productBoxHeight + 50) {
                addReadMore = true;
            }
            if (addReadMore) {
                //console.log('setting height')
                showReadMore();
            }
            else {
                //console.log('remove all')
                resetText();
            }
        }
    };
    const showReadMore = () => {
        const productBoxHeight = document.getElementsByClassName('productsContainer')[0].clientHeight;
        //const productTextHeight = document.getElementsByClassName('selectedProductCategoryDescriptionColumn')[0]
        //  .clientHeight
        const selectedProductCategoryDescriptionColumnElm = document.querySelector('.selectedProductCategoryDescriptionColumn');
        const bodySmallElm = document.querySelector('.selectedProductCategoryDescriptionColumn .bodySmall');
        const textFadeoutElm = document.querySelector('.textFadeout');
        const readMoreElm = document.querySelector('.readMore');
        const readLessElm = document.querySelector('.readLess');
        selectedProductCategoryDescriptionColumnElm.style.height = productBoxHeight + 'px';
        bodySmallElm.style.height = productBoxHeight - 40 + 'px';
        bodySmallElm.style.overflow = 'hidden';
        textFadeoutElm.style.display = '';
        readMoreElm.style.display = '';
        readLessElm.style.display = 'none';
        selectedProductCategoryDescriptionColumnElm.style.paddingBottom = '0px';
    };
    const showReadLess = () => {
        const selectedProductCategoryDescriptionColumnElm = document.querySelector('.selectedProductCategoryDescriptionColumn');
        const bodySmallElm = document.querySelector('.selectedProductCategoryDescriptionColumn .bodySmall');
        const textFadeoutElm = document.querySelector('.textFadeout');
        const readMoreElm = document.querySelector('.readMore');
        const readLessElm = document.querySelector('.readLess');
        selectedProductCategoryDescriptionColumnElm.style.height = '';
        bodySmallElm.style.height = '';
        bodySmallElm.style.overflow = '';
        selectedProductCategoryDescriptionColumnElm.style.paddingBottom = '30px';
        textFadeoutElm.style.display = 'none';
        readMoreElm.style.display = 'none';
        readLessElm.style.display = '';
    };
    const resetText = () => {
        const selectedProductCategoryDescriptionColumnElm = document.querySelector('.selectedProductCategoryDescriptionColumn');
        const bodySmallElm = document.querySelector('.selectedProductCategoryDescriptionColumn .bodySmall');
        const textFadeoutElm = document.querySelector('.textFadeout');
        const readMoreElm = document.querySelector('.readMore');
        const readLessElm = document.querySelector('.readLess');
        selectedProductCategoryDescriptionColumnElm.style.height = '';
        bodySmallElm.style.height = '';
        bodySmallElm.style.overflow = '';
        selectedProductCategoryDescriptionColumnElm.style.paddingBottom = '';
        textFadeoutElm.style.display = 'none';
        readMoreElm.style.display = 'none';
        readLessElm.style.display = 'none';
    };
    const handlePowerOfAttorneyInfoCancelClicked = () => {
        setShowPowerOfAttorneyInfo(false);
    };
    const handleCancelLeadsClicked = (leadId) => {
        if (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) {
            for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i].leadId) == leadId) {
                    setselectedProduct(selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i]);
                    break;
                }
            }
        }
        setshowCancelLead(true);
        //alert('Cancel lead:' + leadId)
    };
    const handleCancelLeadClicked = () => {
        setshowCancelLead(false);
        onCancelLead(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.leadId);
    };
    const handleCancelLeadCloseClicked = () => {
        setshowCancelLead(false);
    };
    const handleCancelLeadOverlayClicked = (e) => {
        if (e.target.className != 'estateSelectorBox' && e.target.className != 'bodyLarge') {
            setshowCancelLead(false);
        }
    };
    const handleCategoryClicked = (categoryId, renderText) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == categoryId) {
                localStorage.setItem('menuItemCategory', categoryId);
                setselectedProductCategory(data[i]);
                break;
            }
        }
        if (renderText) {
            setTimeout(() => {
                resetText();
                calulateTextView();
            }, 100);
        }
    };
    const handleProductClicked = (productId) => {
        //console.log('PRODUCT CLICKED')
        if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) && (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) == 'ORDER') {
            for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                if (selectedProductCategory.products[i].ordered == false) {
                    selectedProductCategory.products[i].selected = false;
                }
                if (selectedProductCategory.products[i].id == productId) {
                    selectedProductCategory.products[i].selected = true;
                }
            }
        }
        if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) &&
            ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) == 'GET_OFFER' || (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) == 'GET_CONTACTED')) {
            for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                if (selectedProductCategory.products[i].id == productId) {
                    selectedProductCategory.products[i].selected = !selectedProductCategory.products[i].selected;
                }
            }
        }
        setselectedProductCategory(Object.assign({}, selectedProductCategory));
    };
    const handleCreateLeadsClicked = () => {
        console.log('CREATE LEADS');
        const productIds = [];
        let product;
        if (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) {
            for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i].selected) && (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i].ordered) == false) {
                    productIds.push(selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i].id);
                    product = selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products[i];
                }
            }
        }
        if (productIds.length > 0) {
            switch (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) {
                case 'ORDER':
                    if (product === null || product === void 0 ? void 0 : product.hasPowerOfAttorneyDocument) {
                        setShowPowerOfAttorneyInfo(true);
                        setselectedProduct(product);
                    }
                    else {
                        onCreateLeads(productIds);
                        console.log('CREATE LEADS 1');
                        onProductCategoryChange(selectedProductCategory.id, true);
                    }
                    break;
                case 'GET_OFFER':
                case 'GET_CONTACTED':
                    onCreateLeads(productIds);
                    console.log('CREATE LEADS 2');
                    onProductCategoryChange(selectedProductCategory.id, true);
                    break;
                default:
                    break;
            }
        }
    };
    const handleLinkFixedClicked = () => {
        onProductCategoryChange(selectedProductCategory.id, true);
        handleNextClicked();
    };
    const handleNextClicked = () => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == selectedProductCategory.id)
                if (i < data.length - 1) {
                    /*
                    localStorage.setItem('menuItemCategory', data[i + 1].id)
                    setselectedProductCategory(data[i + 1])
                    setTimeout(() => {
                      resetText()
                      calulateTextView()
                    }, 100)
                    */
                    break;
                }
                else {
                    onNext();
                }
        }
    };
    const showMedia = () => {
        if (!(selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.media)) {
            return false;
        }
        if (selectedProductCategory.media.mediaUrl == '') {
            return false;
        }
        return true;
    };
    const isVideo = () => {
        var _a, _b, _c;
        if ((_a = selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.media) === null || _a === void 0 ? void 0 : _a.mimeType) {
            if (((_c = (_b = selectedProductCategory.media) === null || _b === void 0 ? void 0 : _b.mimeType) === null || _c === void 0 ? void 0 : _c.indexOf('video')) > -1) {
                return true;
            }
        }
        return false;
    };
    const showNextButton = () => {
        let showButton = true;
        let isLastInCategory = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == selectedProductCategory.id && i == data.length - 1) {
                isLastInCategory = true;
                break;
            }
        }
        if (data.length == 1) {
            isLastInCategory = true;
        }
        if (isLastInCategory && lastItem) {
            showButton = false;
        }
        return showButton;
    };
    const showOrderButton = () => {
        let result = false;
        if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) && (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) == 'ORDER') {
            if (selectedProductCategory) {
                result = true;
                for (let i = 0; i < selectedProductCategory.products.length; i++) {
                    if (selectedProductCategory.products[i].ordered == true) {
                        result = false;
                        break;
                    }
                }
            }
        }
        else {
            if (selectedProductCategory) {
                for (let i = 0; i < selectedProductCategory.products.length; i++) {
                    if (selectedProductCategory.products[i].ordered == false) {
                        result = true;
                        break;
                    }
                }
            }
        }
        return result;
    };
    if (overlayRef.current) {
        if (showCancelLead) {
            overlayRef.current.style.display = '';
        }
        else {
            overlayRef.current.style.display = 'none';
        }
    }
    let buttonText = '';
    switch (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) {
        case 'ORDER':
            buttonText = 'Bestil';
            break;
        case 'GET_OFFER':
            buttonText = 'Få tilbud';
            break;
        case 'GET_CONTACTED':
            buttonText = 'Bliv kontaktet';
            break;
        default:
            buttonText = '';
            break;
    }
    if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) && (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.buttonText) == 'ORDER') {
        let productOrdered = false;
        if (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products) {
            for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                if (selectedProductCategory.products[i].ordered == true) {
                    productOrdered = true;
                }
            }
            if (productOrdered) {
                for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                    if (selectedProductCategory.products[i].ordered == true) {
                        selectedProductCategory.products[i].disabled = false;
                    }
                    else {
                        selectedProductCategory.products[i].disabled = true;
                    }
                }
            }
            else {
                for (let i = 0; i < (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length); i++) {
                    selectedProductCategory.products[i].disabled = false;
                }
            }
        }
    }
    const menuItemCategoryId = localStorage.getItem('menuItemCategory');
    if (menuItemCategoryId) {
        if (selectedProductCategory.id != menuItemCategoryId) {
            handleCategoryClicked(menuItemCategoryId, false);
        }
    }
    const showAllText = () => {
        showReadLess();
        //setShowFullText(true)
    };
    const hideAllText = () => {
        showReadMore();
        //setShowFullText(false)
    };
    const shouldShowProductsContainer = () => {
        if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length) > 0) {
            return true;
        }
        if ((selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.links.length) > 0) {
            return true;
        }
        return false;
    };
    const isProductCategoryCompleted = (productCategoryItem) => {
        if (productCategoryItem.completed) {
            return true;
        }
        for (let i = 0; i < productCategoryItem.products.length; i++) {
            if (productCategoryItem.products[i].ordered) {
                return true;
            }
        }
        return false;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
            data.length > 1 ? (React.createElement(Row, { className: 'productCategoryRow' },
                title.length > 16 && (React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                    React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', className: 'productCategoryTitle', dangerouslySetInnerHTML: { __html: title } }))),
                React.createElement(Column, { className: 'productCategory', width: { tablet: 8 }, style: { paddingTop: '0px', paddingLeft: '0px' } },
                    React.createElement("div", { className: 'productCategoryScroll' }, data.map((productCategoryItem, index) => (React.createElement("div", { key: productCategoryItem.id },
                        React.createElement("div", { className: 'productCategoryContainer', onClick: () => handleCategoryClicked(productCategoryItem.id, true) },
                            isProductCategoryCompleted(productCategoryItem) && (React.createElement("div", { className: 'productCategoryDoneMark' },
                                React.createElement("svg", { width: '18', height: '18', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                                    React.createElement("rect", { width: '32', height: '32', rx: '16', className: 'svg-color' }),
                                    React.createElement("path", { d: 'M24 11.8133L22.12 9.91992L13.3333 18.7066L9.89333 15.2799L8 17.1599L13.3333 22.4799L18.6667 17.1466L24 11.8133Z', fill: 'white' }),
                                    React.createElement("path", { d: 'M22.12 9.91992L24 11.8133L18.6667 17.1466L13.3333 22.4799L8 17.1599L9.89333 15.2799L13.3333 18.7066L22.12 9.91992Z', fill: 'white' })))),
                            productCategoryItem.id == (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.id) ? (React.createElement("div", { className: 'productCategoryIconContainerSelected' }, productCategoryItem.iconMedia != null && (React.createElement("img", { className: 'productCategoryIcon', src: productCategoryItem.iconMedia.mediaUrl })))) : (React.createElement("div", { className: 'productCategoryIconContainer' }, productCategoryItem.iconMedia != null && (React.createElement("img", { className: 'productCategoryIcon', src: productCategoryItem.iconMedia.mediaUrl })))),
                            React.createElement("div", { className: 'productCategoryIconTextContainer' },
                                React.createElement(Typography, { tag: 'p', tagStyle: 'bodyXSmall', dangerouslySetInnerHTML: { __html: productCategoryItem.name } })),
                            productCategoryItem.id == (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.id) && (React.createElement("div", { key: productCategoryItem.id, className: 'productCategoryContainerMarker' }))),
                        React.createElement("div", { className: 'productCategoryArrowContainer' }, index < data.length - 1 && (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '35', zoomAndPan: 'magnify', viewBox: '0 0 1125 1125', height: '35', preserveAspectRatio: 'xMidYMid meet', version: '1.0' },
                            React.createElement("defs", null,
                                React.createElement("clipPath", { id: '36bbb01d89' },
                                    React.createElement("path", { d: 'M 199.304688 393 L 924 393 L 924 732 L 199.304688 732 Z M 199.304688 393 ', clipRule: 'nonzero' }))),
                            React.createElement("g", { clipPath: 'url(#36bbb01d89)' },
                                React.createElement("path", { fill: '#58595e', d: 'M 772.261719 724.273438 L 915.738281 580.792969 C 925.691406 570.839844 925.691406 554.472656 915.738281 544.519531 L 772.261719 400.71875 C 762.308594 390.769531 745.9375 390.769531 735.988281 400.71875 C 726.039062 410.671875 726.039062 427.039062 735.988281 436.992188 L 835.816406 536.816406 L 224.980469 536.816406 C 210.859375 536.816406 199.304688 548.371094 199.304688 562.496094 C 199.304688 576.621094 210.859375 588.175781 224.980469 588.175781 L 835.492188 588.175781 L 735.667969 688 C 730.53125 693.136719 728.285156 699.554688 728.285156 706.296875 C 728.285156 713.039062 730.851562 719.457031 735.667969 724.59375 C 745.9375 734.222656 762.308594 734.222656 772.261719 724.273438 Z M 772.261719 724.273438 ', fillOpacity: '1', fillRule: 'nonzero' })))))))))))) : (React.createElement("div", null,
                React.createElement("br", null),
                React.createElement("br", null))),
            selectedProductCategory && (React.createElement(Column, { width: { tablet: 8 }, className: 'selectedProductCategoryColumn' },
                React.createElement(Row, { className: 'selectedProductCategoryContainer selectedProductCategoryContainerMobile' },
                    selectedProductCategory.heading != '' && (React.createElement(Column, { width: { tablet: 8 } },
                        React.createElement(Typography, { tag: 'h1', tagStyle: 'headlineSmall', dangerouslySetInnerHTML: { __html: selectedProductCategory.heading } }))),
                    React.createElement(Column, { className: 'selectedProductCategoryDescriptionColumn', width: { tablet: 4 } },
                        React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', style: { top: '-15px', position: 'relative' }, dangerouslySetInnerHTML: {
                                __html: selectedProductCategory.text.replaceAll('<p><br></p>', ''),
                            } }),
                        React.createElement("div", { className: 'textFadeout', style: { display: 'none' } }),
                        React.createElement("div", { className: 'readMore', style: { display: 'none' }, onClick: () => showAllText() },
                            React.createElement("p", { style: { margin: 0, fontSize: '12px' } }, "L\u00E6s mere"),
                            React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '14.828', height: '8.414', viewBox: '0 0 14.828 8.414' },
                                React.createElement("path", { id: 'Pil', d: 'M3852,2622l6,6-6,6', transform: 'translate(2635.414 -3850.586) rotate(90)', fill: 'none', stroke: '#58595e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' }))),
                        React.createElement("div", { className: 'readLess', style: { display: 'none' }, onClick: () => hideAllText() },
                            React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '14.828', height: '8.414', viewBox: '0 0 14.828 8.414', className: 'itemCollapsibleIconDown' },
                                React.createElement("path", { id: 'Pil', d: 'M3852,2622l6,6-6,6', transform: 'translate(2635.414 -3850.586) rotate(90)', fill: 'none', stroke: '#58595e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' })),
                            React.createElement("p", { style: { margin: 0, fontSize: '12px' } }, "Vis mindre"))),
                    shouldShowProductsContainer() && (React.createElement(React.Fragment, null,
                        React.createElement(Column, { width: { tablet: 4 }, className: 'productsContainer' },
                            React.createElement("div", null,
                                (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.links.length) > 0 && (React.createElement(React.Fragment, null, selectedProductCategory.links.map((linkObj) => (React.createElement(ItemLinkBox, { link: linkObj, key: linkObj.id }))))),
                                (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length) > 0 && (React.createElement(React.Fragment, null, selectedProductCategory.products.map((productItem) => (React.createElement(ProductBox, { key: productItem.id, id: productItem.id, leadId: productItem.leadId, name: productItem.name, ordered: productItem.ordered, selected: productItem.selected, disabled: productItem.disabled, partnerLogo: productItem.partnerLogo, onCancelClick: handleCancelLeadsClicked, onProductClicked: handleProductClicked })))))),
                            (selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.products.length) > 0 ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'buttonsGroup' },
                                    showOrderButton() && React.createElement(Button, { text: buttonText, onClick: handleCreateLeadsClicked }),
                                    showNextButton() && (React.createElement(React.Fragment, null,
                                        React.createElement("div", { style: { width: '35px' } }, "\u00A0"),
                                        React.createElement(Button, { text: 'Ikke aktuelt nu', grey: true, onClick: handleNextClicked })))))) : (React.createElement("div", { className: 'buttonsGroup' },
                                React.createElement(Button, { text: 'Det er klaret', onClick: handleLinkFixedClicked }),
                                React.createElement("div", { style: { width: '35px' } }, "\u00A0"),
                                React.createElement(Button, { text: 'Ikke aktuelt nu', grey: true, onClick: handleNextClicked })))))),
                    showMedia() && (React.createElement(Column, { className: 'selectedProductCategoryDescriptionColumn', width: { tablet: 5 } },
                        React.createElement(Row, null,
                            React.createElement(Column, { width: { tablet: 5 } }, isVideo() ? (React.createElement(VideoBox, { media: selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.media })) : (React.createElement("img", { src: (_a = selectedProductCategory === null || selectedProductCategory === void 0 ? void 0 : selectedProductCategory.media) === null || _a === void 0 ? void 0 : _a.mediaUrl, className: 'itemContentVideoElm' }))),
                            React.createElement(Column, { width: { tablet: 3 } },
                                selectedProductCategory.media.heading != '' && (React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: selectedProductCategory.media.heading } })),
                                selectedProductCategory.media.text != '' && (React.createElement(Typography, { tag: 'p', tagStyle: 'bodyXSmall', style: { paddingBottom: '32px' }, dangerouslySetInnerHTML: { __html: selectedProductCategory.media.text } }))))))))),
            React.createElement("div", { className: 'overlay', ref: overlayRef, style: { display: 'none' }, onClick: (e) => handleCancelLeadOverlayClicked(e) },
                React.createElement("div", { className: 'estateSelectorContainer' },
                    React.createElement("div", { className: 'cancelBox' },
                        React.createElement("div", { className: 'cancelHeader', dangerouslySetInnerHTML: { __html: 'Fortryd bestilling' } }),
                        React.createElement("div", { className: 'cancelDescription', dangerouslySetInnerHTML: {
                                __html: 'Du er ved at fortryde din bestilling fra ' +
                                    (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                    ', om at de må kontakte dig<br/><br/>Har du endnu ikke været i kontakt med ' +
                                    (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                    ', informerer vi ' +
                                    (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                    ' om, at du ikke længere ønsker at blive kontaktet, og at dit samtykke skal trækkes tilbage.<br/><br/>Har du allerede indgået en aftale med ' +
                                    (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                    ', står aftalen ved magt. Du skal derfor selv kontakte ' +
                                    (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.partnerName) +
                                    ' for at annullere din aftale<br/><br/>Er du sikker?',
                            } }),
                        React.createElement("div", { style: { display: 'flex' } },
                            React.createElement(Button, { text: 'Ja, jeg er sikker', onClick: handleCancelLeadClicked, grey: true }),
                            React.createElement("div", { style: { paddingRight: '10px' } }),
                            React.createElement(Button, { text: 'Nej, behold bestilling', onClick: handleCancelLeadCloseClicked })))))),
        showPowerOfAttorneyInfo && (React.createElement(PowerOfAttorneyInfoOverlay, { product: selectedProduct, customerId: customerId, estateCaseId: estateCaseId, onCancel: handlePowerOfAttorneyInfoCancelClicked }))));
};
export default ProductCategoryChooser;
