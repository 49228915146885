// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useHistory } from "react-router-dom";
import Editor from "./../../../../components/Editor/Editor";

const GET_PLANNER_ITEM_GQL = gql`
  query GET_PLANNER_ITEM_GQL($id: ID!) {
    plannerItem(id: $id) {
      id
      productCategoryText
      productCategories {
        id
        name
        heading
        text
        
        sorting
      }
    }
  }
`;

const UPDATE_ITEM_GQL = gql`
mutation updatePlannerItem($input: UpdatePlannerItemInput!){
    updatePlannerItem(input:$input){
        id
    } 
}`

const CREATE_PlannerItemProductCategoryGql = gql`
  mutation createPlannerItemProductCategory($input: CreatePlannerItemProductCategoryInput!) {
    createPlannerItemProductCategory(input: $input) {
      id
    }
  }
`;

const DELETE_PRODUCT_CATEGORY_GQL = gql`
  mutation deletePlannerItemProductCategory($id: ID!) {
    deletePlannerItemProductCategory(id: $id)
  }
`;

const UPDATE_PRODUCT_CATEGORY_SORTING_GQL = gql`
  mutation updatePlannerItemProductCategorySorting($input: [updatePlannerItemProductCategorySorting]) {
    updatePlannerItemProductCategorySorting(input: $input)
  }
`;

const MovingplannerProductCategories = ({ plannerCategoryId, plannerItemId }) => {
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const [seletedRow, setSeletedRow] = React.useState("");
  const cancelRefCreateDialog = React.useRef();
  const cancelRefDeleteDialog = React.useRef();
  const newProductCategoryNameRef = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const history = useHistory();

  const [createPlannerItemProductCategory] = useMutation(CREATE_PlannerItemProductCategoryGql, { refetchQueries: [GET_PLANNER_ITEM_GQL] });

  const [deletePlannerItemProductCategory] = useMutation(DELETE_PRODUCT_CATEGORY_GQL, { refetchQueries: [GET_PLANNER_ITEM_GQL] });

  const [updatePlannerItemCategorySorting] = useMutation(UPDATE_PRODUCT_CATEGORY_SORTING_GQL, { refetchQueries: [GET_PLANNER_ITEM_GQL] });

  const [updatePlannerItem,] = useMutation(UPDATE_ITEM_GQL, { refetchQueries: [GET_PLANNER_ITEM_GQL] });

  const { loading, error, data } = useQuery(
    GET_PLANNER_ITEM_GQL,
    {
      variables: { id: plannerItemId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting partner products from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting partner products from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Overskrift",
      accessor: "heading",
      onClick: rowClicked,
    },
    {
      header: "Redigere",
      accessor: "1",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: EditClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: DeleteClicked,
    },
  ];

  function rowClicked(obj) {
    EditClicked(obj);
  }

  function EditClicked(obj) {
    history.push("/admin/flytteplanner/" + plannerCategoryId + "/punkt/" + plannerItemId + "/productkategory/" + obj.id);
  }

  function DeleteClicked(obj) {
    setSeletedRow(obj);
    onOpenDeleteDialog();
  }

  const deleteProductCategory = () => {
    deletePlannerItemProductCategory({
      variables: {
        id: seletedRow.id,
      },
    });
    onCloseDeleteDialog();
  };

  const setTableData = (obj) => {
    var tableData = obj(data.plannerItem.productCategories);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((productCategory) => ({ id: productCategory.id, sorting: productCategory.sorting }));
    updatePlannerItemCategorySorting({
      variables: {
        input: result,
      },
    });
  };

  const showCreateModal = () => {
    onOpenCreateDialog();
  };

  const createPlannerItemProductCategoryClicked = () => {
    let inputData = {};
    inputData.name = newProductCategoryNameRef.current.value;
    inputData.heading = "";
    inputData.text = "";
    inputData.plannerItemId = plannerItemId;
    inputData.buttonText = "ORDER";
    inputData.singleProductSelect = false;

    createPlannerItemProductCategory({
      variables: { input: inputData },
    });

    onCloseCreateDialog();
  };

  const updateData = (id, field, data) => {
    let inputData = JSON.parse("{\"" + field + "\":\"\"}");
    inputData[field] = data;
    inputData.id = id;
    updatePlannerItem({ variables: { input: inputData } })

    toast({
      title: 'Ændringen er blevet gemt.',
      description: "",
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }
  return (
<>
   
    <div style={{ width: "100%" }}>
      
      <Card p="16px" my={{ sm: "24px", xl: "20px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Produkt kategorier
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize="lg"
              color="gray.400"
              cursor="pointer"
              mb="6px"
              onClick={() => {
                showCreateModal();
              }}
            >
              Tilføj
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <Flex direction='column' style={{ width: '100%' }}>
            <Flex align='center' mb='18px'>
              <Text fontSize='md' color={textColor} fontWeight='bold' me='10px' whiteSpace={'nowrap'} minWidth={'160px'}>
                Tekst under boksene:{" "}
              </Text>
 
                  <Input
                    variant="flushed"
                    type="text"
                    name="heading"
                    defaultValue={data.plannerItem.productCategoryText}
                    onBlur={(e) => updateData(data.plannerItem.id, "productCategoryText", e.target.value)}
                  />
            </Flex>
            <Flex align='center' mb='18px'>
              <SettleTable data={data.plannerItem.productCategories} columnsData={columns} setDragData={setTableData} />
            </Flex>
          </Flex>
        </CardBody>
      </Card>

      <AlertDialog isOpen={isOpenDeleteDialog} leastDestructiveRef={cancelRefDeleteDialog} onClose={onCloseDeleteDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet produkt kategori.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefDeleteDialog} onClick={onCloseDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteProductCategory} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret ny produkt kategori.
            </AlertDialogHeader>
            <AlertDialogBody>
              Du er ved at oprette en ny produkt kategori.
              <br />
              <br />
              Angiv kategoriens navn:
              <Input ref={newProductCategoryNameRef} type="text" name="heading" defaultValue="" />
              <br />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={createPlannerItemProductCategoryClicked} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
    </>
  );
};

export default MovingplannerProductCategories;
