import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      electricity {
        imageUrl
        kWh
        meterNo
        notes
        readingDate
        supplier
      }
    }
  }
`;
const UPDATE_ELECTRICITY_INFO_GQL = gql `
  mutation updateElectricity($input: UpdateElectricityInput!) {
    updateElectricity(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_ELECTRICITY_INFO_GQL };
