import { __awaiter } from "tslib";
import React, { useEffect, useState, useRef } from 'react';
import './handoverSignature.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Button from '../../../../components/atoms/button/button';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HANDOVER_GQL, UPDATE_SIGNATURE_INFO_GQL } from './graphQL';
import SignatureCanvas from 'react-signature-canvas';
const HandoverSignature = ({ handoverId, onNext }) => {
    const { loading, error, data } = useQuery(GET_HANDOVER_GQL, {
        variables: { id: handoverId },
    });
    const [updateSignatureInfo, { data: updateData, loading: updateProcessing, error: updateError }] = useMutation(UPDATE_SIGNATURE_INFO_GQL, {
        refetchQueries: [{ query: GET_HANDOVER_GQL, variables: { id: handoverId } }],
    });
    const [validate, setValidate] = useState(false);
    const [componentData, setComponentData] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const buyerSignaturePadRef = useRef(null);
    const sellerSignaturePadRef = useRef(null);
    useEffect(() => {
        if (!loading && !error && data) {
            setComponentData({
                buyerHandwrittenSignature: '',
                haveBuyerHandwrittenSignature: data.getHandover.handoverSignature.haveBuyerHandwrittenSignature,
                sellerHandwrittenSignature: data.getHandover.handoverSignature.haveBuyerHandwrittenSignature,
                signedDocUrl: data.getHandover.signedDocument ? data.getHandover.signedDocument.orgDocStorageUrl : '',
                useSellerRepresentative: data.getHandover.handoverSignature.useSellerRepresentative,
                sellerRepresentativeName: data.getHandover.handoverSignature.sellerRepresentativeName,
            });
        }
    }, [loading, error, data]);
    if (loading) {
        return React.createElement("p", null);
    }
    if (error) {
        console.log('get data Error:' + error.message);
        return React.createElement("p", null);
    }
    if (componentData == null) {
        return React.createElement("p", null);
    }
    if (updateProcessing) {
        return (React.createElement(Container, { className: 'handoverSignature', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
            React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", null, "Afslutter n\u00F8gleoverdragelsen og laven en afsluttende rapport."),
                            React.createElement("div", null, "\u00D8jeblik..")))))));
    }
    if (updateError) {
        console.log('update Error:' + updateError.message);
    }
    if (updateData) {
        //onNext()
    }
    const updateField = (fieldName, fieldValue) => {
        const tmp = componentData;
        tmp[fieldName] = fieldValue;
        setComponentData(Object.assign({}, tmp));
    };
    const updateCheckBoxField = (fieldName, fieldValue) => {
        console.log('fieldValue:', fieldValue);
        const tmp = componentData;
        tmp[fieldName] = fieldValue;
        setComponentData(Object.assign({}, tmp));
    };
    const handleGenerateClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        setValidate(false);
        if (buyerSignaturePadRef.current.isEmpty() ||
            sellerSignaturePadRef.current.isEmpty() ||
            (componentData.useSellerRepresentative && componentData.sellerRepresentativeName === '')) {
            setValidate(true);
            forceUpdate();
        }
        else {
            const buyerSignatureBase64 = buyerSignaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
            const sellerSignatureBase64 = sellerSignaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
            const updateObj = {
                handoverId: handoverId,
                buyerHandwrittenSignature: buyerSignatureBase64,
                sellerHandwrittenSignature: sellerSignatureBase64,
                useSellerRepresentative: componentData.useSellerRepresentative,
                sellerRepresentativeName: componentData.sellerRepresentativeName,
            };
            const updataSignatureData = yield updateSignatureInfo({
                variables: {
                    input: updateObj,
                },
            });
            //console.log('updataSignatureData:', updataSignatureData)
            const tmp = componentData;
            tmp['signedDocUrl'] = updataSignatureData.data.updateHandoverSignature.signedDocument.orgDocStorageUrl;
            tmp['buyerHandwrittenSignature'] = buyerSignatureBase64;
            tmp['haveBuyerHandwrittenSignature'] = true;
            tmp['sellerHandwrittenSignature'] = sellerSignatureBase64;
            tmp['haveSellerHandwrittenSignature'] = true;
            tmp['useSellerRepresentative'] = componentData.useSellerRepresentative;
            tmp['sellerRepresentativeName'] = componentData.sellerRepresentativeName;
            setComponentData(Object.assign({}, tmp));
            onNext();
        }
    });
    const clickCheckBox = (e) => {
        e.target.previousElementSibling.firstChild.click();
    };
    const buyerSignaturePadClass = validate && buyerSignaturePadRef.current.isEmpty() ? 'fieldSignatureError' : 'fieldSignature';
    const sellerSignaturePadClass = validate && sellerSignaturePadRef.current.isEmpty() ? 'fieldSignatureError' : 'fieldSignature';
    //console.log('componentData!.useSellerRepresentative:', componentData!.useSellerRepresentative)
    return (React.createElement(Container, { className: 'handoverSignature', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } }, componentData.signedDocUrl !== '' ? (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
            React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", null,
                            "N\u00F8gleoverdragelsen er nu afsluttet og der er sendt en kopi af det underskrevede dokument til k\u00F8ber og s\u00E6lger. ",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            "Dokumentet kan ogs\u00E5 hentes ved at klikke",
                            ' ',
                            React.createElement("a", { href: componentData.signedDocUrl, target: '_blank', rel: 'noreferrer' }, "her")))))))) : (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
            React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'checkboxsHorizontal' },
                            React.createElement("div", null,
                                React.createElement("input", { type: 'checkbox', name: 'allKeysHandedOver', className: 'squareRadio', onClick: (e) => updateCheckBoxField('useSellerRepresentative', e.currentTarget.checked), defaultChecked: componentData.useSellerRepresentative ? true : false })),
                            React.createElement("div", { className: 'RightCheckboxText', onClick: clickCheckBox }, "Signeres af s\u00E6lgers repr\u00E6sentant"))),
                    componentData.useSellerRepresentative && (React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'fieldHeading' }, "Navn p\u00E5 s\u00E6lgers repr\u00E6sentant:"),
                        React.createElement("input", { type: 'text', defaultValue: componentData.sellerRepresentativeName, className: validate && componentData.sellerRepresentativeName == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('sellerRepresentativeName', e.target.value) })))))),
        ' ',
        React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
            React.createElement(Column, { width: { tablet: 8 }, style: { padding: '0px' } },
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'fieldHeading' }, "S\u00E6lgers/ s\u00E6lgers repr\u00E6sentants underskrift:"),
                        React.createElement("div", { className: sellerSignaturePadClass },
                            React.createElement(SignatureCanvas, { penColor: 'black', canvasProps: { className: 'signaturePad' }, ref: sellerSignaturePadRef }))),
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'fieldHeading' }, "K\u00F8bers underskrift:"),
                        React.createElement("div", { className: buyerSignaturePadClass },
                            React.createElement(SignatureCanvas, { penColor: 'black', canvasProps: { className: 'signaturePad' }, ref: buyerSignaturePadRef }))))),
            React.createElement(Column, { width: { tablet: 4 }, className: 'linksContainer' })),
        React.createElement(Row, null,
            React.createElement(Column, { width: { tablet: 8 }, style: { paddingRight: '38px' }, className: 'linksContainer' },
                React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                    React.createElement(Button, { text: 'Afslut overdragelsen', width: 230, grey: true, onClick: handleGenerateClicked }))))))));
};
export default HandoverSignature;
