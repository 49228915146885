// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import moment from "moment";

const LeadInfo = ({ data }) => {
  ///const toast = useToast();
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const translateLeadStatus = (status) => {
    switch (status) {
      case "UPDATED":
        return "Opdateret";
      case "ACCEPTED":
        return "Oprettet";
      case "DECLINED_PROCESSED":
        return "Afvist";
      case "ACCEPTED_PROCESSED":
        return "Accepteret";
      case "ACCEPTED_SENT_TO_PARTNER":
        return "Sendt til partner";
      case "ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL":
        return "Sendt til partner, afventer fortrydelsesret udløb";
      case "ACCEPTED_SENT_TO_PARTNER_ERROR":
        return "Fejlet ved afsendelse til partner";
      case "ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL_ERROR":
        return "Fejlet ved tjek af om fortrydelsesret er udnyttet";
      case "SENT_TO_PARTNER_WAITING_FOR_FEEDBACK":
        return "Sendt til partner, afventer tilbage melding";
      case "SENT_TO_PARTNER_WAITING_FOR_FEEDBACK_NO_RESPONSE_HANDLE_MANUALY":
        return "Fejlet, partner har ikke vendt tilbage på lead´et";
      default:
        return "Ukendt";
    }
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Lead information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Status:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {translateLeadStatus(data.status)}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Produkt:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.affiliatePartnerProduct.name}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              oprettet:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {moment(data.createdAt).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              ændret:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {moment(data.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Antal processeringer:{" "}
            </Text>
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.processingCount}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Næste processering:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {moment(data.nextProcessingDate).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Partner ref:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.externalOrderId}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              Mæglersystem ref:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.externalRef}
            </Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default LeadInfo;
