import React from 'react';
import Typography from '../../../../components/atoms/typography/typography';
import './itemCollapsible.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import { itemType } from '../../../../types';
import HandoverElectricity from '../handoverElectricity/handoverElectricity';
import HandoverWater from '../handoverWater/handoverWater';
import HandoverHeat from '../handoverHeat/handoverHeat';
import HandoverSession from '../handoverSession/handoverSession';
import HandoverSellerAndBuyer from '../handoverSellerAnBuyer/handoverSellerAndBuyer';
import HandoverSignature from '../handoverSignature/handoverSignature';
const ItemCollapsible = ({ children, isEditMode, onChange, onDoneChange, onEditChange, data, marketingConsent, onDisabledItemClicked, onNext, handoverId, }) => {
    var _a;
    const handleFilterOpening = (e) => {
        if (document.body.clientWidth < 768) {
            setTimeout(() => {
                e.target.parentElement.parentElement.parentElement.parentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                }),
                    300;
            });
        }
        if (!data.hasProducts) {
            onChange(data.id, !data.isOpen);
        }
        else {
            if (marketingConsent) {
                onChange(data.id, !data.isOpen);
            }
            else {
                console.log('blured item clicked');
                onDisabledItemClicked();
            }
        }
    };
    const renderItemCollapsibleOpenButtonClass = (isOpen) => {
        let Classes = 'itemCollapsibleIcon itemCollapsibleIconUp';
        if (isOpen) {
            Classes = 'itemCollapsibleIcon itemCollapsibleIconDown';
        }
        return Classes;
    };
    const renderItemCollapsibleContentClass = (isOpen) => {
        let Classes = 'itemCollapsibleContentColumn itemCollapsibleContentColumnClosed';
        if (isOpen) {
            Classes = 'itemCollapsibleContentColumn itemCollapsibleContentColumnOpen';
        }
        return Classes;
    };
    const handleDoneCheckBoxChange = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (data.hasProducts && marketingConsent) {
            onDoneChange(data.id, !data.completed);
        }
        if (!data.hasProducts) {
            onDoneChange(data.id, !data.completed);
        }
    };
    const handleEditChange = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onEditChange(data.id, !data.isRemoved);
    };
    const renderItemCollapsibleEditRowClass = (isEditMode) => {
        let Classes = 'itemCallapsibleEditRow itemCallapsibleEditRowClose';
        if (isEditMode) {
            Classes = 'itemCallapsibleEditRow itemCallapsibleEditRowOpen';
        }
        return Classes;
    };
    let ContainerClass = 'itemCallapsibleContainer';
    if (data.isRemoved && isEditMode == false) {
        ContainerClass = 'itemCallapsibleContainerHidden';
    }
    let itemCallapsibleRowClass = 'itemCallapsibleRow';
    if (data.hasProducts && !marketingConsent) {
        itemCallapsibleRowClass = 'itemCallapsibleRow itemCallapsibleRowDisabled';
    }
    return (React.createElement(Container, { className: ContainerClass, paddingTopReset: true, style: { padding: '0', marginBottom: '30px', display: 'flex', alignItems: 'center' } },
        React.createElement("div", { className: renderItemCollapsibleEditRowClass(isEditMode), onClick: handleEditChange }, data.isRemoved ? (React.createElement("svg", { width: '32', height: '32', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_221_1538)' },
                React.createElement("path", { d: 'M15.9998 2.66675C8.63984 2.66675 2.6665 8.64008 2.6665 16.0001C2.6665 23.3601 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.3601 29.3332 16.0001C29.3332 8.64008 23.3598 2.66675 15.9998 2.66675ZM22.6665 17.3334H17.3332V22.6667H14.6665V17.3334H9.33317V14.6667H14.6665V9.33341H17.3332V14.6667H22.6665V17.3334Z', className: 'svg-color' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_221_1538' },
                    React.createElement("rect", { width: '32', height: '32', fill: 'white' }))))) : (React.createElement("svg", { width: '32', height: '32', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("g", { clipPath: 'url(#clip0_221_1572)' },
                React.createElement("path", { d: 'M15.9998 2.66675C8.63984 2.66675 2.6665 8.64008 2.6665 16.0001C2.6665 23.3601 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.3601 29.3332 16.0001C29.3332 8.64008 23.3598 2.66675 15.9998 2.66675ZM22.6665 17.3334H9.33317V14.6667H22.6665V17.3334Z', fill: '#85878d' })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: 'clip0_221_1572' },
                    React.createElement("rect", { width: '32', height: '32', fill: 'white' })))))),
        React.createElement(Row, { className: itemCallapsibleRowClass },
            React.createElement(Column, { width: { mobile: 7 }, className: 'itemCallapsibleColumn', onClick: (e) => handleFilterOpening(e) },
                React.createElement("div", { className: 'itemCollapsibleCheckBoxContainer', onClick: handleDoneCheckBoxChange }, data.completed ? (React.createElement("svg", { width: '12', height: '12', viewBox: '0 0 32 32', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                    React.createElement("rect", { width: '32', height: '32', rx: '16', className: 'svg-color' }),
                    React.createElement("path", { d: 'M24 11.8133L22.12 9.91992L13.3333 18.7066L9.89333 15.2799L8 17.1599L13.3333 22.4799L18.6667 17.1466L24 11.8133Z', fill: 'white' }),
                    React.createElement("path", { d: 'M22.12 9.91992L24 11.8133L18.6667 17.1466L13.3333 22.4799L8 17.1599L9.89333 15.2799L13.3333 18.7066L22.12 9.91992Z', fill: 'white' }))) : (React.createElement("svg", { width: '12', height: '12', viewBox: '0 0 27 27', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
                    React.createElement("rect", { x: '1', y: '1', width: '0', height: '0', rx: '15', stroke: '#c4c5cb', strokeWidth: '1' })))),
                React.createElement("div", { className: 'prevent-select', style: { display: 'inline-block', verticalAlign: 'top', width: '25px' } },
                    React.createElement("img", { style: { maxWidth: '23px', position: 'relative', left: '-5px' }, src: (_a = data.icon) === null || _a === void 0 ? void 0 : _a.mediaUrl })),
                React.createElement("div", { className: 'prevent-select', style: { display: 'inline-block', width: '85%' } },
                    React.createElement(Typography, { tag: 'h1', tagStyle: 'headlineSmall', style: { margin: '0', whiteSpace: 'normal', paddingBottom: '6px' }, dangerouslySetInnerHTML: { __html: data.heading } }),
                    React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', style: { margin: '0', whiteSpace: 'normal' }, dangerouslySetInnerHTML: { __html: data.subHeading } }))),
            React.createElement(Column, { width: { mobile: 1 }, style: { padding: '0px' }, className: 'itemCollapsibleIconColumn' },
                React.createElement("div", { onClick: (e) => handleFilterOpening(e) },
                    React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '14.828', height: '8.414', viewBox: '0 0 14.828 8.414', className: renderItemCollapsibleOpenButtonClass(data.isOpen) },
                        React.createElement("path", { id: 'Pil', d: 'M3852,2622l6,6-6,6', transform: 'translate(2635.414 -3850.586) rotate(90)', fill: 'none', stroke: '#58595e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2' })))),
            React.createElement(Column, { width: { mobile: 8 }, className: renderItemCollapsibleContentClass(data.isOpen) },
                React.createElement(React.Fragment, null, data.itemType === itemType.NORMAL && React.createElement(React.Fragment, null, data.isOpen && React.createElement("div", null, children))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_ELECTRICITY && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverElectricity, { onNext: onNext, handoverId: handoverId })))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_WATER && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverWater, { onNext: onNext, handoverId: handoverId })))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_HEAT && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverHeat, { onNext: onNext, handoverId: handoverId })))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_SESSION && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverSession, { onNext: onNext, handoverId: handoverId })))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_PERSONS && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverSellerAndBuyer, { onNext: onNext, handoverId: handoverId })))),
                React.createElement(React.Fragment, null, data.itemType === itemType.HANDOVER_SIGNING && (React.createElement(React.Fragment, null, data.isOpen && React.createElement(HandoverSignature, { onNext: onNext, handoverId: handoverId }))))))));
};
export default ItemCollapsible;
