// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../components/header";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";

import AffiliatePartnerInfo from "./../components/affiliatePartnerInfo";
import AffiliatePartnerNotes from "./../components/affiliatePartnerNotes";
import AffiliatePartnerAddress from "./../components/affiliatePartnerAddress";
import AffiliatePartnerProducts from "./../components/affiliatePartnerProducts";
import AffiliatePartnerDocuments from "./../components/affiliatePartnerDocuments";
import AffiliatePartnerLogoUpload from "./../components/affiliatePartnerLogoUpload";
import { GET_AFFILIATEPARTNER } from "./graphQL";

const SENT_WELCOMEMAIL_PARTNER = gql`
  mutation sentAffiliatePartnerWelcomeMail($affiliatePartnerId: ID!) {
    sentAffiliatePartnerWelcomeMail(affiliatePartnerId: $affiliatePartnerId)
  }
`;


function Info() {
  const toast = useToast();

  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { affiliatePartnerId } = useParams();
  const [sentWelcomeMail] = useMutation(SENT_WELCOMEMAIL_PARTNER);
  const { loading, error, data, refetch } = useQuery(
    GET_AFFILIATEPARTNER,
    {
      variables: { id: affiliatePartnerId },
    },
    {
      manual: true,
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting partners from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting partners from server (2)</p>;
  }

  const handleSentWelcomeMailClicked = () => {
    sentWelcomeMail({ variables: { affiliatePartnerId: affiliatePartnerId } }).then(() => {
      toast({
        title: "Der er nu sendt en velkomst mail til partneren.",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, der blev IKKE sendt en velkomst mail til partneren.",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  function forceRerender() {
    refetch();
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.affiliatePartner.name}
        description={"Overblik"}
        view={"info"}
        estateBrokerId={affiliatePartnerId}
        onSentWellcomesMessageClicked={handleSentWelcomeMailClicked}
        loginAsUrl={data.affiliatePartner.loginAsUrl}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap="22px">
        <AffiliatePartnerInfo data={data} />
        <AffiliatePartnerAddress data={data} />
        <AffiliatePartnerNotes data={data} />
        <AffiliatePartnerLogoUpload data={data.affiliatePartner} forceRerender={forceRerender} />
        <AffiliatePartnerDocuments data={data.affiliatePartner} forceRerender={forceRerender} />

        <GridItem w="100%" colSpan={3} rowSpan={0}>
          <AffiliatePartnerProducts affiliatePartnerId={data.affiliatePartner.id} />
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default Info;
