// Chakra imports
import { Select, Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import React, { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";

const DataSelectorCardLeads = ({
  updatefn,
  fromDate,
  toDate,
  status,
  affiliatePartnerProductIds,
  realEstateBrokerIds,
  realEstateBrokerOfficeIds,
  affiliatePartnerProducts,
  realEstateBrokers,
}) => {
  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectedAffiliatePartnerProducts, setSelectedAffiliatePartnerProducts] = useState(affiliatePartnerProductIds);
  const [selectedRealEstateBrokers, setSelectedRealEstateBrokers] = useState(realEstateBrokerIds);
  const [selectedStatus, setSelectedStatus] = useState(status);

  const updateDates = (dates) => {
    if (dates.length === 2 && dates[1] != null) {
      const fromIsoDate = new Date(dates[0].getTime() - dates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(dates[1].getTime() - dates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      updatefn(fromIsoDate, toIsoDate, selectedAffiliatePartnerProducts, selectedRealEstateBrokers, status);
    }
  };

  const updateStatus = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];

      let status = [];
      
      if (event.target.value !== "") {
        if (event.target.value.indexOf(",") > -1) {
          status = event.target.value.split(",");
        } else {
          status.push(event.target.value);
        }
      }

      setSelectedStatus(status);

      updatefn(fromIsoDate, toIsoDate, selectedAffiliatePartnerProducts, selectedRealEstateBrokers, status);
    }
  };

  const updateAffiliatePartnerProduct = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];

      let products = [];
      if (event.target.value !== "") {
        if (event.target.value.indexOf(",") > -1) {
          products = event.target.value.split(",");
        } else {
          products.push(event.target.value);
        }
      }

      setSelectedAffiliatePartnerProducts(products);

      updatefn(fromIsoDate, toIsoDate, products, selectedRealEstateBrokers, selectedStatus);
    }
  };

  const updateRealEstateBroker = (event) => {
    if (selectedDates.length === 2 && selectedDates[1] != null) {
      const fromIsoDate = new Date(selectedDates[0].getTime() - selectedDates[0].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - selectedDates[1].getTimezoneOffset() * 60000).toISOString().split("T")[0];
      let brokers = [];
      if (event.target.value !== "") {
        if (event.target.value.indexOf(",") > -1) {
          brokers = event.target.value.split(",");
        } else {
          brokers.push(event.target.value);
        }
      }
      setSelectedRealEstateBrokers(brokers);

      updatefn(fromIsoDate, toIsoDate, selectedAffiliatePartnerProducts, brokers, selectedStatus);
    }
  };

  let showEstateBroker = true;
  if (realEstateBrokerOfficeIds.length > 0) {
    showEstateBroker = false;
  }

  return (
    <Card p="16px">
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e) => {
              setSelectedDates(e);
              updateDates(e);
            }}
            configs={{
              dateFormat: "yyyy-MM-dd",
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: "yellow.400",
                  },
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
              },
            }}
          />
        </GridItem>

        <GridItem>
          <Text>Vælg status:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select
            multiple={false}
            value={selectedStatus.toString()}
            onChange={(e) => {
              updateStatus(e);
            }}
          >
            <option key={"status_1"} value="">
              Alle
            </option>
            <option key={"status_2"} value="ACCEPTED_SENT_TO_PARTNER">
              Sendt til partner
            </option>
            <option key={"status_3"} value="ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL">
              Sendt til partner, afventer fortrydelses ret
            </option>
            <option key={"status_4"} value="SENT_TO_PARTNER_WAITING_FOR_FEEDBACK">
              Sendt til partner, afventer tilbage melding
            </option>
            <option
              key={"status_5"}
              value="ACCEPTED_SENT_TO_PARTNER_ERROR,ACCEPTED_SENT_TO_PARTNER_AWAITING_RIGHT_OF_WIDTDRAWAL_ERROR,SENT_TO_PARTNER_WAITING_FOR_FEEDBACK_NO_RESPONSE_HANDLE_MANUALY"
            >
              Fejlet
            </option>
            <option key={"status_6"} value="ACCEPTED_PROCESSED">
              Accepteret og gennemført
            </option>
            <option key={"status_7"} value="DECLINED_PROCESSED">
              Afvist og gennemført
            </option>
          </Select>
        </GridItem>
        <GridItem>
          <Text>Vælg product:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select
            multiple={false}
            value={selectedAffiliatePartnerProducts.toString()}
            onChange={(e) => {
              updateAffiliatePartnerProduct(e);
            }}
          >
            <option key={"affiliatePartners_all"} value="">
              Alle
            </option>
            {affiliatePartnerProducts.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
        </GridItem>
        {showEstateBroker ? (
          <GridItem>
            <Text>Vælg mægler:</Text>
          </GridItem>
        ) : (
          <div></div>
        )}
        {showEstateBroker ? (
          <GridItem colSpan={3}>
            <Select
              multiple={false}
              value={selectedRealEstateBrokers.toString()}
              onChange={(e) => {
                updateRealEstateBroker(e);
              }}
            >
              <option key={"affiliatePartners_all"} value="">
                Alle
              </option>
              {realEstateBrokers.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </GridItem>
        ) : (
          <div></div>
        )}
      </Grid>
    </Card>
  );
};
/*
<GridItem>
          <Text>Vælg partner:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select multiple={false} value={selectedAffiliatePartner} onChange={(e) => { setSelectedAffiliatePartner(e.target.value); updateAffiliatePartner(e) }}>
            <option key={'affiliatePartners_all'} value=''>Alle</option>
            {affiliatePartners.map((option) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </Select>
        </GridItem>
        */

export default DataSelectorCardLeads;
