export const datastore = {
    data: {
        userId: '',
        userName: '',
        userAddress: '',
        userZipCode: '',
        userCity: '',
        userEmail: '',
        userPhone: '',
        topBannerUrl: 'https://login.settle.dk/planner_topbanner.jpg',
        realEstateBrokerLogoUrl: '',
        marketingConsentText: 'Jeg vil gerne modtage information og tilbud relateret til min flytning fra Settle ApS, så de kan kontakte mig telefonisk og/eller per e-mail.',
        marketingConsentTextLong: 'Ja tak! Jeg vil gerne modtage information og tilbud relateret til min flytning fra Settle ApS (”Settle”) og deres samarbejdspartnere, så disse kan kontakte mig telefonisk og/eller per e-mail. Jeg er indforstået med at Settle videregiver mine oplysninger til deres samarbejdspartnere. Jeg kan læse nærmere om, hvordan Settle som dataansvarlig behandler mine personoplysninger i persondatapolitikken <a href="https://settle.dk/persondata.html" target="_blank">her</a>.<br/><br/>Jeg kan til enhver tid trække mit samtykke tilbage, eksempelvis via e-mail eller via min profil eller de enkelte samarbejdspartnere. Hvis jeg giver samtykke til at modtage tilbud fra samarbejdspartnerne, modtager Settle og mægler provision.<br/><br/>Samarbejdspartnerne er uafhængige og er selvstændigt ansvarlige for afgivelse af tilbud, kundeservice og levering af de varer og tjenesteydelser, som de tilbyder.',
        marketingConsentIntoText: 'For at Settle kan hjælpe dig godt igennem din flytning, vil vi gerne have lov til at sende dig nyttige påmindelser telefonisk og på mail vedrørende vigtige datoer, frister, services og andre tilbud i forbindelse med din flytning.',
        handover: {
            id: '',
            status: '',
        },
    },
};
