import { __awaiter } from "tslib";
import React from 'react';
import './uploadImageSmall.scss';
import Camera from '../../../../components/icons/Camera';
const UploadImage = ({ imageChanged, uploadTypeName, imageUrl, validateError, disabled }) => {
    const tempID = 'photo_upload_' + Math.random().toString(16).slice(2);
    const photoUploadText = 'Tilføj foto af aflæsningen <b>' + uploadTypeName + '</b>';
    const [imgSrc, setImgSrc] = React.useState(imageUrl);
    let state = 'takePhoto';
    if (imageUrl !== null && imageUrl !== '') {
        state = 'showPhoto';
    }
    const [photoState, setPhotoState] = React.useState(state);
    const addPhotoClick = () => {
        var _a;
        (_a = document.getElementById(tempID)) === null || _a === void 0 ? void 0 : _a.click();
    };
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    const fileInputChange = () => __awaiter(void 0, void 0, void 0, function* () {
        const fileInput = document.getElementById(tempID);
        if ((fileInput === null || fileInput === void 0 ? void 0 : fileInput.files.length) > 0) {
            //console.log('File info:', fileInput.files[0])
            const imgBase64 = yield toBase64(fileInput.files[0]);
            setImgSrc(imgBase64);
            setPhotoState('showPhoto');
            imageChanged(imgBase64, fileInput.files[0].name, fileInput.files[0].type);
            const uploadInput = document.getElementById(tempID);
            if (uploadInput) {
                uploadInput.value = '';
            }
        }
    });
    const removeImageClicked = () => {
        setImgSrc('');
        setPhotoState('takePhoto');
        imageChanged('', '', '');
    };
    const borderClass = () => {
        if (validateError) {
            return 'BorderCorner camaraBorders errorClass';
        }
        return 'BorderCorner camaraBorders';
    };
    return (React.createElement("div", { className: 'uploadImageSmall', style: { padding: '0', marginBottom: '0px' } },
        React.createElement("input", { type: 'file', id: tempID, "data-role": 'none', className: 'photo_upload', accept: 'image/jpeg', onChange: fileInputChange }),
        photoState === 'takePhoto' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: borderClass(), onClick: addPhotoClick },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: photoUploadText } }),
                React.createElement("div", { style: { width: '35px', height: '35px' } },
                    React.createElement(Camera, null))))),
        photoState === 'showPhoto' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'BorderCorner_wrap' },
                React.createElement("div", { className: 'BorderCorner camaraBorders imageview' }, imgSrc && React.createElement("img", { alt: '', src: imgSrc }))),
            !disabled && (React.createElement("div", { className: 'removeImageText', onClick: removeImageClicked }, "Fjern billede")
            /*
            <div className='buttonsGroup' style={{ display: 'block', width: '100%' }}>
              <Button text={'Fjern billede'} fullwidth hideArrow grey={true} onClick={removeImageClicked} />
            </div>
          */
            )))));
};
export default UploadImage;
