// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useHistory } from "react-router-dom";

const GET_AffiliatePartnerProductsGql = gql`
  query GET_AFFILIATEPARTNER($id: ID!) {
    affiliatePartner(id: $id) {
      id
      products {
        id
        apiName
        name
        integrationType
        billingType
      }
    }
  }
`;

const CREATE_AffiliatePartnerProductGql = gql`
  mutation createAffiliatePartnerProduct($input: CreateAffiliatePartnerProduct!) {
    createAffiliatePartnerProduct(input: $input) {
      id
    }
  }
`;

const DELETE_AffiliatePartnerProductGql = gql`
  mutation deleteAffiliatePartnerProduct($id: ID!) {
    deleteAffiliatePartnerProduct(id: $id)
  }
`;

const AffiliatePartnerProducts = ({ affiliatePartnerId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const cancelRefCreateDialog = React.useRef();
  const newPartnerProductNameRef = React.useRef();
  const cancelRefDeleteDialog = React.useRef();
  const { isOpen: isOpenCreateDialog, onOpen: onOpenCreateDialog, onClose: onCloseCreateDialog } = useDisclosure();
  const history = useHistory();
  const [seletedRow, setSeletedRow] = React.useState("");
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();

  const [createAffiliatePartnerProduct] = useMutation(CREATE_AffiliatePartnerProductGql);
  const [deleteAffiliatePartnerProduct] = useMutation(DELETE_AffiliatePartnerProductGql);

  const { loading, error, data, refetch } = useQuery(
    GET_AffiliatePartnerProductsGql,
    {
      variables: { id: affiliatePartnerId },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting partner products from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting partner products from server (2)</p>;
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "NAVN",
      accessor: "name",
      onClick: rowClicked,
    },
    {
      header: "Integration",
      accessor: "integrationType",
      onClick: rowClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: rowDeleteClicked,
    },
  ];

  function rowDeleteClicked(obj) {
    setSeletedRow(obj);
    onOpenDeleteDialog();
  }

  const deleteProduct = () => {
    onCloseDeleteDialog();

    deleteAffiliatePartnerProduct({
      variables: { id: seletedRow.id },
    }).then(() => {
      refetch()
    })
  }

  function rowClicked(obj) {
    history.push("/admin/partner/" + affiliatePartnerId + "/product/" + obj.id);
  }

  const showCreateModal = () => {
    onOpenCreateDialog();
  };

  const createPartnerProduct = () => {
    let inputData = {};
    inputData.name = newPartnerProductNameRef.current.value;
    inputData.affiliatePartnerId = affiliatePartnerId;

    createAffiliatePartnerProduct({
      variables: { input: inputData },
    }).then(() => {
      refetch()
    });

    onCloseCreateDialog();
  };

  return (
    <div>
      <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Produkter
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
              cursor="pointer"
              onClick={() => {
                showCreateModal();
              }}
            >
              Opret nyt produkt
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <SettleTable data={data.affiliatePartner.products} columnsData={columns} />
        </CardBody>
      </Card>

      <AlertDialog isOpen={isOpenCreateDialog} leastDestructiveRef={cancelRefCreateDialog} onClose={onCloseCreateDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opret by partner
            </AlertDialogHeader>

            <AlertDialogBody>
              Du er ved at oprette en ny produkt i systemet.
              <br />
              <br />
              Angiv produktes navn:
              <Input ref={newPartnerProductNameRef} type="text" name="partnerProductName" defaultValue="" />
              <br />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefCreateDialog}
                onClick={() => {
                  onCloseCreateDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={createPartnerProduct} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenDeleteDialog} leastDestructiveRef={cancelRefDeleteDialog} onClose={onCloseDeleteDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet produkt.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefDeleteDialog} onClick={onCloseDeleteDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteProduct} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default AffiliatePartnerProducts;
