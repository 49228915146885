import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthProvider } from "./auth-context/auth.context";
import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import PlannerPreviewLayout from "./views/MovingPlannerPreview/PlannerPreview";
import HandlerLayout from "./layouts/Handler.js";
import "./i18n";

let user = localStorage.getItem("user");
user = JSON.parse(user);

const httpLink = createHttpLink({
  //uri: 'https://api.settle.dk/api',
  //uri: "http://localhost:8080/api",
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: "Bearer " + token,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <AuthProvider userData={user}>
      <HashRouter>
        <Switch>
          <Route path={`/handlers`} component={HandlerLayout} />
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/plannerpreview/:realEstateBrokerId/:customerId`} component={PlannerPreviewLayout} />
          <Redirect from={`/`} to="/admin/overblik" />
        </Switch>
      </HashRouter>
    </AuthProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
