// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Icon,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { FaRegEdit, FaCheck } from "react-icons/fa";
import { GET_BillingItemPricesGql, CREATE_BillingItemPriceGql, UPDATE_BillingItemPriceGql } from "./../AffiliatePartnerProduct/graphQL";
import { useQuery, useMutation } from "@apollo/client";
import { TbTrashX } from "react-icons/tb";

const AffiliatePartnerProductPrices = ({ PartnerProductId }) => {
  const [rowId, setRowId] = useState(0);
  const cancelRef = React.useRef();
  const cancelRefUpdateDataWarning = React.useRef();
  const fromDateRef = React.useRef();
  const toDateRef = React.useRef();
  const realEstateBrokerRef = React.useRef();
  const priceRef = React.useRef();
  const realEstateBrokerEarningRef = React.useRef();
  const realEstateSystemEarningRef = React.useRef();
  const earningRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenUpdateDataWarning, onOpen: onOpenUpdateDataWarning, onClose: onCloseUpdateDataWarning } = useDisclosure();

  const textColor = useColorModeValue("gray.700", "white");

  const [createBillingItemPrice] = useMutation(CREATE_BillingItemPriceGql, { refetchQueries: [GET_BillingItemPricesGql] });
  const [updateBillingItemPrice] = useMutation(UPDATE_BillingItemPriceGql, { refetchQueries: [GET_BillingItemPricesGql] });

  const { loading, error, data } = useQuery(GET_BillingItemPricesGql, {
    variables: { id: PartnerProductId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting prices from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting prices from server (2)</p>;
  }

  const editPrices = (priceId) => {
    setRowId(priceId);
  };

  const dataUpdate = () => {
    onOpenUpdateDataWarning();
  };

  const deletePrice = () => {
    onOpen();
  };

  const deleteRow = () => {
    onClose();
    let inputData = {};
    inputData.id = rowId;
    inputData.deleted = true;

    updateBillingItemPrice({
      variables: { input: inputData },
    });

    setRowId(0);
  };

  const addNewRow = () => {
    let inputData = {};
    inputData.affiliatePartnerProductId = PartnerProductId;

    createBillingItemPrice({
      variables: { input: inputData },
    });
  };

  const updateNewRow = () => {
    let inputData = {};
    inputData.id = rowId;
    inputData.deleted = false;
    inputData.realEstateBrokerId = realEstateBrokerRef.current.value;
    inputData.fromDate = fromDateRef.current.value;
    inputData.toDate = toDateRef.current.value;
    inputData.price = parseInt(priceRef.current.value);
    inputData.realEstateBrokerEarning = parseInt(realEstateBrokerEarningRef.current.value);
    inputData.realEstateSystemEarning = parseInt(realEstateSystemEarningRef.current.value);
    inputData.earning = parseInt(earningRef.current.value);

    updateBillingItemPrice({
      variables: { input: inputData },
    });

    setRowId(0);
    onCloseUpdateDataWarning();
  };

  return (
    <div>
      <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Priser
            </Text>
          </Flex>
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" cursor="pointer" onClick={() => addNewRow()}>
              Tilføj ny pris
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <TableContainer w="100%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Fra</Th>
                  <Th>Til</Th>
                  <Th>Mæglerkæde</Th>
                  <Th isNumeric>Partner betaling</Th>
                  <Th isNumeric>Ejendomsmægler fee</Th>
                  <Th isNumeric>Mæglersystem fee</Th>
                  <Th isNumeric>Fortjeneste</Th>
                  {rowId !== 0 && <Th w="20px">Slet</Th>}
                  <Th w="20px"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.billingItemPrices.map((row) => {
                  if (row.id === rowId) {
                    return (
                      <Tr key={row.id}>
                        <Td>
                          <Input ref={fromDateRef} type="text" name="fromDate" defaultValue={row.fromDate} w="140px" />
                        </Td>
                        <Td>
                          <Input ref={toDateRef} type="text" name="toDate" defaultValue={row.toDate} w="140px" />
                        </Td>

                        <Td>
                          <Select ref={realEstateBrokerRef} multiple={false} defaultValue={row.realEstateBroker?.id}>
                            <option key={null} value="">
                              Ingen
                            </option>
                            {data.realEstateBrokers.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                        </Td>

                        <Td isNumeric>
                          <Input textAlign="right" ref={priceRef} type="text" name="price" defaultValue={row.price} w="70px" />
                        </Td>
                        <Td isNumeric>
                          <Input
                            textAlign="right"
                            ref={realEstateBrokerEarningRef}
                            type="text"
                            name="realEstateBrokerEarning"
                            defaultValue={row.realEstateBrokerEarning}
                            w="70px"
                          />
                        </Td>
                        <Td isNumeric>
                          <Input
                            textAlign="right"
                            ref={realEstateSystemEarningRef}
                            type="text"
                            name="realEstateSystemEarning"
                            defaultValue={row.realEstateSystemEarning}
                            w="70px"
                          />
                        </Td>
                        <Td isNumeric>
                          <Input textAlign="right" ref={earningRef} type="text" name="earning" defaultValue={row.earning} w="70px" />
                        </Td>

                        <Td>
                          <Icon as={TbTrashX} me="10px" style={{ cursor: "pointer" }} onClick={() => deletePrice()} />
                        </Td>
                        <Td>
                          <Icon as={FaCheck} me="10px" style={{ cursor: "pointer" }} onClick={() => dataUpdate()} />
                        </Td>
                      </Tr>
                    );
                  } else {
                    return (
                      <Tr key={row.id}>
                        <Td>{row.fromDate}</Td>
                        <Td>{row.toDate}</Td>
                        {row.realEstateBroker !== null ? <Td>{row.realEstateBroker.name}</Td> : <Td>-</Td>}
                        <Td isNumeric>{row.price}</Td>
                        <Td isNumeric>{row.realEstateBrokerEarning}</Td>
                        <Td isNumeric>{row.realEstateSystemEarning}</Td>
                        <Td isNumeric>{row.earning}</Td>
                        {rowId !== 0 && <Td w="20px">&nbsp;</Td>}
                        {rowId !== 0 ? (
                          <Td w="20px">&nbsp;</Td>
                        ) : (
                          <Td>
                            <Icon as={FaRegEdit} me="10px" style={{ cursor: "pointer" }} onClick={() => editPrices(row.id)} />
                          </Td>
                        )}
                      </Tr>
                    );
                  }
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet pris
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteRow} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenUpdateDataWarning} leastDestructiveRef={cancelRefUpdateDataWarning} onClose={onCloseUpdateDataWarning}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opdatere priser
            </AlertDialogHeader>

            <AlertDialogBody>
              Du er ved at ændre på priserne, som kan have stor indflydelse på afregningen tilbage i tiden.
              <br />
              Som udgangspunkt bør man aldrig ændre priser tilbage i tiden. Men oprette evt. nye priser fremadrettet.
              <br />
              <br />
              Er du sikker på at du vil opdatere?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRefUpdateDataWarning}
                onClick={() => {
                  setRowId(0);
                  onCloseUpdateDataWarning();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={updateNewRow} ml={3}>
                Opdatere
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default AffiliatePartnerProductPrices;
