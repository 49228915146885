import { gql } from "@apollo/client";

export const GET_PRODUCT_CATAGORY_GQL = gql`
  query GET_PLANNER_ITEM_GQL($id: ID!) {
    plannerItemProductCategory(id: $id) {
      id
      name
      heading
      text
      sorting
      buttonText
      media {
        id
        filename
        mediaUrl
        heading
        text
      }
      icon {
        id
        filename
        mediaUrl
      }
      conditions {
        id
        conditionType
        field {
          id
          name
          fieldType
        }
        operator
        value
      }
      products {
        id
        name
      }
    }
    conditionFields {
      id
      name
      fieldType
      values
    }
  }
`;

export const UPDATE_ITEM_GQL = gql`
  mutation updatePlannerItemProductCategory($input: updatePlannerItemProductCategoryInput!) {
    updatePlannerItemProductCategory(input: $input) {
      id
    }
  }
`;
