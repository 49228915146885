import React from 'react';
import Typography from '../../../../components/atoms/typography/typography';
import './itemContent.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import VideoBox from '../../../../components/molecules/videoBox/videoBox';
import Button from '../../../../components/atoms/button/button';
import ItemLinkBox from '../../components/linkBox/linkBox';
const ItemContentVideo = ({ data, children, onNext }) => {
    var _a;
    // const [showVideo, setShowVideo] = useState<boolean>(false)
    const mytext = () => {
        if (data === null || data === void 0 ? void 0 : data.description) {
            return data.description;
        }
        else {
            return '';
        }
    };
    const mediaHeading = () => {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.media) === null || _a === void 0 ? void 0 : _a.heading) {
            return (_b = data === null || data === void 0 ? void 0 : data.media) === null || _b === void 0 ? void 0 : _b.heading;
        }
        else {
            return '';
        }
    };
    const handleNextClicked = () => {
        onNext();
    };
    const mediaText = () => {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.media) === null || _a === void 0 ? void 0 : _a.text) {
            return (_b = data === null || data === void 0 ? void 0 : data.media) === null || _b === void 0 ? void 0 : _b.text;
        }
        else {
            return '';
        }
    };
    const showBlock = () => {
        const rawText = mytext();
        const text = rawText.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
        if (text == '') {
            return false;
        }
        return true;
    };
    const showMedia = () => {
        if (!(data === null || data === void 0 ? void 0 : data.media)) {
            return false;
        }
        if (data.media.mediaUrl == '') {
            return false;
        }
        return true;
    };
    const showLinks = () => {
        if (data === null || data === void 0 ? void 0 : data.links) {
            if ((data === null || data === void 0 ? void 0 : data.links.length) > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    const isVideo = () => {
        var _a, _b, _c;
        if ((_a = data === null || data === void 0 ? void 0 : data.media) === null || _a === void 0 ? void 0 : _a.mimeType) {
            if (((_c = (_b = data === null || data === void 0 ? void 0 : data.media) === null || _b === void 0 ? void 0 : _b.mimeType) === null || _c === void 0 ? void 0 : _c.indexOf('video')) > -1) {
                return true;
            }
        }
        return false;
    };
    const showNextButton = () => {
        let showButton = true;
        if (data) {
            if ((data === null || data === void 0 ? void 0 : data.productCategories.length) > 0) {
                showButton = false;
            }
        }
        return showButton;
    };
    return (React.createElement(Container, { paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
        !showMedia() ? (React.createElement(React.Fragment, null, showBlock() && (React.createElement(React.Fragment, null, showLinks() ? (React.createElement(Row, { className: 'itemContentVideoRow' },
            React.createElement(Column, { width: { tablet: 4 }, className: 'itemContentTextRow', style: { padding: '0px' } },
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: mytext() } })),
            React.createElement(Column, { width: { tablet: 4 }, className: 'linksContainer' },
                React.createElement("div", null, data === null || data === void 0 ? void 0 : data.links.map((link) => (React.createElement(ItemLinkBox, { link: link, key: link.id }))))))) : (React.createElement(Row, null,
            React.createElement(Column, { width: { tablet: 8 }, className: 'itemContentTextRow' },
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: mytext() } })))))))) : (React.createElement(Row, { className: 'itemContentVideoRow' },
            React.createElement(Column, { width: { tablet: 4 }, style: { padding: '0px' } },
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: mytext() } })),
            React.createElement(Column, { width: { tablet: 4 }, className: 'linksContainerVideo' },
                isVideo() ? (React.createElement(VideoBox, { media: data === null || data === void 0 ? void 0 : data.media })) : (React.createElement("img", { src: (_a = data === null || data === void 0 ? void 0 : data.media) === null || _a === void 0 ? void 0 : _a.mediaUrl, className: 'itemContentVideoElm' })),
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', dangerouslySetInnerHTML: { __html: mediaHeading() }, style: { marginBottom: '0px', marginTop: '0px' } }),
                React.createElement(Typography, { tag: 'p', tagStyle: 'bodyXSmall', style: { paddingBottom: '32px', marginTop: '0px' }, dangerouslySetInnerHTML: { __html: mediaText() } }),
                showLinks() && (React.createElement("div", null, data === null || data === void 0 ? void 0 : data.links.map((link) => (React.createElement(ItemLinkBox, { link: link, key: link.id })))))))),
        React.createElement(React.Fragment, null,
            showNextButton() ? (React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 4 }, style: { padding: '0px' } }),
                React.createElement(Column, { width: { tablet: 4 }, className: 'linksContainer' },
                    React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                        React.createElement(Button, { text: 'Ikke aktuelt nu', grey: true, onClick: handleNextClicked }))))) : (React.createElement(React.Fragment, null, children)),
            ' ')));
};
export default ItemContentVideo;
