import React from "react";
import { useAuth } from './auth-context/auth.context';
import { useHistory } from 'react-router-dom';
import { Route } from "react-router-dom";

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  let { user } = useAuth();

  if (!user || !user.token || user.token === "") {
    history.push("/auth/signin")
  }

  return (<>
    <Route {...rest} />
  </>);
};
