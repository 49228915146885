import React, { useState } from 'react';
//import background from './../UnknownUser/bg.jpg'
import './../UnknownUser/Unknown_User.scss';
import Typography from '../../components/atoms/typography/typography';
const Unknown_User = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckBoxClicked = () => {
        setIsChecked(!isChecked);
    };
    const handleNewLoginClicked = () => {
        var _a;
        const consentText = (_a = document.getElementById('consentText')) === null || _a === void 0 ? void 0 : _a.innerText;
        props.onCreateNewUser(consentText);
    };
    const handleGiveConcentClicked = () => {
        var _a;
        const consentText = (_a = document.getElementById('consentText')) === null || _a === void 0 ? void 0 : _a.innerText;
        props.onConcentApproved(consentText);
    };
    return (React.createElement("div", { className: 'unknownUserBody' },
        React.createElement("div", { className: 'centerVertical unknownUser' },
            React.createElement("div", { className: 'unknownUserHeadingContainer' },
                React.createElement("h1", null, "Flyt-Nemt hj\u00E6lper dig nemt og trygt gennem flytteprocessen")),
            React.createElement("div", { className: 'unknownUserSubHeadingContainer' },
                React.createElement("h2", null, "Din online flytte-planner hj\u00E6lper dig, der skal flytte med at huske det vigtigste i netop din flyttesituation.")),
            React.createElement("div", { className: 'unknownUserConsentContainer' },
                React.createElement("div", { className: 'unknownUserConsentTextContainer' },
                    React.createElement("div", { className: 'unknownUserConsentText', onClick: handleCheckBoxClicked },
                        React.createElement("div", { className: 'unknownUserCheckBox' }, isChecked ? (React.createElement("svg", { id: 'chekbox', "data-name": 'chekbox', xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                            React.createElement("path", { id: 'Icon_material-check-box', "data-name": 'Icon material-check-box', d: 'M25.833,4.5H7.167A2.666,2.666,0,0,0,4.5,7.167V25.833A2.666,2.666,0,0,0,7.167,28.5H25.833A2.666,2.666,0,0,0,28.5,25.833V7.167A2.666,2.666,0,0,0,25.833,4.5Zm-12,18.667L7.167,16.5l1.88-1.88,4.787,4.773,10.12-10.12,1.88,1.893Z', transform: 'translate(-4.5 -4.5)', className: 'svg-color' }))) : (React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', viewBox: '0 0 24 24' },
                            React.createElement("g", { id: 'Rectangle_1706', "data-name": 'Rectangle 1706', fill: 'none', stroke: '#000', strokeWidth: '2' },
                                React.createElement("rect", { width: '24', height: '24', rx: '2', stroke: 'none' }),
                                React.createElement("rect", { x: '1', y: '1', width: '22', height: '22', rx: '1', fill: 'none' }))))),
                        React.createElement("div", null,
                            React.createElement(Typography, { tag: 'p', tagStyle: 'bodySmall', className: 'unknownUserConsent', style: { margin: '0' }, dangerouslySetInnerHTML: {
                                    __html: '<b>Jeg giver samtykke til Settle´s vilkår og betingelser.</b>',
                                } }))),
                    React.createElement("div", null,
                        React.createElement(Typography, { tag: 'p', id: 'consentText', className: 'unknownUserConsentDescription', tagStyle: 'bodySmall', style: { margin: '0' }, dangerouslySetInnerHTML: {
                                __html: 'Jeg giver samtykke til Settle´s vilkår og betingelser, herunder at Flyt-Nemt må videregive mine kontaktoplysninger til deres samarbejdspartnere, så disse kan sende mig relevante tilbud relateret til min flytning.',
                            } })),
                    isChecked && (React.createElement(React.Fragment, null, props.registationFlow ? (React.createElement("div", { className: 'unknownUserConsentButton', onClick: handleNewLoginClicked },
                        React.createElement(Typography, { tag: 'p', className: 'unknownUserConsentButtonText', tagStyle: 'bodySmall', dangerouslySetInnerHTML: {
                                __html: '<b>Login</b>',
                            } }))) : (React.createElement("div", { className: 'unknownUserConsentButton', onClick: handleGiveConcentClicked },
                        React.createElement(Typography, { tag: 'p', className: 'unknownUserConsentButtonText', tagStyle: 'bodySmall', dangerouslySetInnerHTML: {
                                __html: '<b>Giv samtykke</b>',
                            } })))))),
                React.createElement("div", { className: 'unknownUserConsentVideoContainer' },
                    React.createElement("div", { className: 'unknownUserConsentVideoInfoContainer' },
                        React.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', width: '58', height: '58', viewBox: '0 0 58 58', style: { zIndex: '100', position: 'absolute' } },
                            React.createElement("g", { id: 'Play', transform: 'translate(-978 -756)' },
                                React.createElement("circle", { id: 'Ellipse_46', "data-name": 'Ellipse 46', cx: '29', cy: '29', r: '29', transform: 'translate(978 756)', className: 'svg-color' }),
                                React.createElement("path", { id: 'Icon_ionic-md-play', "data-name": 'Icon ionic-md-play', d: 'M6.75,3.656V18.834l11.9-7.589Z', transform: 'translate(996.299 773.755)', fill: '#fff' })))),
                    React.createElement(Typography, { tag: 'p', className: 'unknownUserConsentVideoInfoText', tagStyle: 'bodySmall', dangerouslySetInnerHTML: {
                            __html: 'Se hvordan det virker',
                        } }))))));
};
export default Unknown_User;
