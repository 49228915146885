// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React, { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataSelectorCard from "./components/DataSelectorCard"
import { useQuery, gql } from "@apollo/client";


const BILLING_REPORT_DETAILED = gql`
query BILLING_REPORT_DETAILED($input: DetailedBillingReportInput){
    DetailedBillingReport(input: $input){
      chainName
      officeName
      productName
      partnerName
      customerName
      customerEmail
      customerPhone
      takeoverDate
      realEstateBrokerEarning
      realEstateSystemEarning
      receivedFromPartner
      earning
      leadCreatedAt
      partnerRef
      estateSystemRef
      billingDate
      moveToAddress
    }
    affiliatePartnerProducts{
        id
        name
    }
    realEstateBrokers{
        id
        name
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Ejendomsmægler kontor",
        accessor: "officeName",
    },
    {
        header: "Produkt",
        accessor: "productName",
        width: 100
    },
    {
        header: "Kunde",
        accessor: "customerName",
        width: 100
    },
    {
        header: "Modtager fra partner",
        accessor: "receivedFromPartner",
        width: 100
    },
    {
        header: "Betaling til Ejendomsmægler",
        accessor: "realEstateBrokerEarning",
        width: 100
    },
    {
        header: "Betaling til mæglersystem",
        accessor: "realEstateSystemEarning",
        width: 100
    },
    {
        header: "Fortjeneste",
        accessor: "earning",
        width: 100
    }
    
];


function BillingReportDetailed() {
    // Chakra color mode

    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
        productIds: [],
        realEstateBrokerIds: []
    });

    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );



    //const { estateBrokerId } = useParams();
    const { loading, error, data } = useQuery(BILLING_REPORT_DETAILED, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                productIds: requestData.productIds,
                realEstateBrokerIds: requestData.realEstateBrokerIds
            }
        },
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Detaljeret fakturering udtræk"}
                description={'Detaljeret fakturering rapport. Filteret på dato, produkt og ejendomsmægler kæde.'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                   
                </GridItem>
    
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting stats from server, error: {error}</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting stats from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, partnerProductSelected, realEstateBrokerSelected) => {
       

        let productIds = [];
        if (!Array.isArray(partnerProductSelected)) {
            if (partnerProductSelected.indexOf(',')) {
                productIds = partnerProductSelected.split(',')
            } else {
                productIds.push(partnerProductSelected);
            }
        }

        let realEstateBrokerIds = [];
        if (!Array.isArray(realEstateBrokerSelected)) {
            if (realEstateBrokerSelected.indexOf(',')) {
                realEstateBrokerIds = realEstateBrokerSelected.split(',');
            } else {
                realEstateBrokerIds.push(realEstateBrokerSelected);
            }
        }


        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            productIds: productIds, // requestData.productIds,
            realEstateBrokerIds: realEstateBrokerIds // requestData.realEstateBrokerIds
        })
       // refetch();
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.DetailedBillingReport));
    let pricesTotal = 0;
    for (var i = 0; i < tableData.length; i++) {
        tableData[i].id = i + 1;
        pricesTotal = pricesTotal + tableData[i].totalPrice
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    pricesTotal = numberWithCommas(pricesTotal);

    var csvData = tableData.map(item => (
        { 
            MæglerKæde: item.chainName,
            Ejendomsmægler: item.officeName,
            Produkt:item.productName,
            Partner:item.partnerName,
            KundeNavn:item.customerName,
            KundeEmail:item.customerEmail,
            KundeTelefon:item.customerPhone,
            Adresse: item.moveToAddress,
            OvertagelsesDato:item.takeoverDate.split('T')[0],
            PartnerRef:item.partnerRef,
            MæglerSystemRef: item.estateSystemRef,
            BetalingTilEjendomsmægler:item.realEstateBrokerEarning,
            BetalingTilMæglerSystem:item.realEstateSystemEarning,
            BetalingModtagetFraPartner:item.receivedFromPartner,
            Fortjeneste:item.earning,
            LeadOprettelsesDato:item.leadCreatedAt.replace('T',' ').replace('.000Z',''),
            BillingDato: item.billingDate.replace('T',' ').replace('.000Z','')
        }
    ));

  

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Detaljeret fakturering udtræk"}
                description={'Detaljeret fakturering rapport. Filteret på dato, produkt og ejendomsmægler kæde.'}
            />
    
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                    <DataSelectorCard
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                        partnerProducts={data.affiliatePartnerProducts}
                        partnerProductSelected={requestData.productIds.toString()}
                        realEstateBrokers={data.realEstateBrokers}
                        realEstateBrokerSelected={requestData.realEstateBrokerIds.toString()}
                    />
                </GridItem>
                
            </Grid>
            <br />

            
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>


        </Flex>
    );
}

export default BillingReportDetailed;
