// Chakra imports
import { Flex, Text, useColorModeValue, Textarea, useToast } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateAffiliatePartnerInfoGql = gql`
  mutation updateAffiliatePartner($input: UpdateAffiliatePartner!) {
    updateAffiliatePartner(input: $input) {
      id
    }
  }
`;

const AffiliatePartnerNotes = ({ data }) => {
  const toast = useToast();
  const [updateRealEstateBrokerInfo] = useMutation(updateAffiliatePartnerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (estateBrokerId, field, data) => {
    data = data.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = estateBrokerId;
    updateRealEstateBrokerInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const addLinebreaks = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Noter
        </Text>
      </CardHeader>
      <CardBody px="5px" height={"100%"}>
        <Flex direction="column" style={{ width: "100%", height: "100%" }}>
          <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
            <Textarea
              height={"100%"}
              type="text"
              name="name"
              defaultValue={addLinebreaks(data.affiliatePartner.note)}
              onBlur={(e) => updateData(data.affiliatePartner.id, "note", e.target.value)}
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default AffiliatePartnerNotes;
