import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      signedDocument {
        orgDocStorageUrl
      }
      handoverSignature {
        haveBuyerHandwrittenSignature
        haveSellerHandwrittenSignature
        useSellerRepresentative
        sellerRepresentativeName
      }
    }
  }
`;
const UPDATE_SIGNATURE_INFO_GQL = gql `
  mutation updateHandoverSignature($input: UpdateHandoverSignatureInput!) {
    updateHandoverSignature(input: $input) {
      id
      signedDocument {
        orgDocStorageUrl
      }
    }
  }
`;
export { GET_HANDOVER_GQL, UPDATE_SIGNATURE_INFO_GQL };
