import { gql } from "@apollo/client";

export const updateAffiliatePartnerProductInfoGql = gql`
    mutation updateAffiliatePartnerProduct($input: UpdateAffiliatePartnerProduct!){
      updateAffiliatePartnerProduct(input:$input){
        id
        apiName
        name
        description
        integrationType
        billingType
        prices{
          id
          fromDate
          toDate
          price
          realEstateBrokerEarning
          realEstateSystemEarning
          earning
          realEstateBroker{
            name
          }
        }
        primaryEmail
        ccEmail
        bccEmail
        followUpAfterNumberOfDays
        partnerApiUrl
        partnerApiUsername
        partnerApiPassword
        partnerApiProduct
      } 
    }`


export const GET_AFFILIATEPARTNERPRODUCT = gql`
query GET_AFFILIATEPARTNERPRODUCT($id: ID!){
  affiliatePartnerProduct(id:$id){
    id
    apiName
    name
    description
    integrationType
    billingType
    powerOfAttorneyDocument{
      id
      filename
      orgDocStorageUrl   
    }
    prices{
      id
      fromDate
      toDate
      price
      realEstateBrokerEarning
      realEstateSystemEarning
      earning
      realEstateBroker{
        name
      }
    }
    primaryEmail
    ccEmail
    bccEmail
    followUpAfterNumberOfDays
    partnerApiUrl
    partnerApiUsername
    partnerApiPassword
    partnerApiProduct
  }
}`

export const CREATE_BillingItemPriceGql = gql`
    mutation createBillingItemPrice($input: CreateBillingItemPriceInput!){
        createBillingItemPrice(input:$input){
            id
            fromDate
            toDate
            price
            realEstateBrokerEarning
            realEstateSystemEarning
            earning
            realEstateBroker{
                id
                name
            }
        }
    }`

    export const UPDATE_BillingItemPriceGql = gql`
    mutation updateBillingItemPrice($input: UpdateBillingItemPriceInput!){
        updateBillingItemPrice(input:$input){
            id
            fromDate
            toDate
            price
            realEstateBrokerEarning
            realEstateSystemEarning
            earning
            realEstateBroker{
                id
                name
            }
        }
    }`

    export const GET_BillingItemPricesGql = gql`
    query billingItemPrices($id: ID!){
        billingItemPrices(affiliatePartnerProductId:$id){
            id
            fromDate
            toDate
            price
            realEstateBrokerEarning
            realEstateSystemEarning
            earning
            realEstateBroker{
                id
                name
            }
        }
        realEstateBrokers {
            id
            name
        }

    }`



    