import React, { useEffect, useState } from 'react';
import './handoverSellerAndBuyer.scss';
import Column from '../../../../components/utilities/column/column';
import Container from '../../../../components/utilities/container/container';
import Row from '../../../../components/utilities/row/row';
import Button from '../../../../components/atoms/button/button';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HANDOVER_GQL, UPDATE_SELLER_AND_BUYER_INFO_GQL } from './graphQL';
import DawaAutocomplete2 from './../../../../components/molecules/dawa-autocomplete2';
import { datastore } from '../../../../dataStore';
const HandoverSellerAndBuyer = ({ handoverId, onNext }) => {
    const { loading, error, data } = useQuery(GET_HANDOVER_GQL, {
        variables: { id: handoverId },
    });
    const [updateBuyerSellerInfo, { data: updateData, error: updateError }] = useMutation(UPDATE_SELLER_AND_BUYER_INFO_GQL, {
        refetchQueries: [{ query: GET_HANDOVER_GQL, variables: { id: handoverId } }],
    });
    const [validate, setValidate] = useState(false);
    const [componentData, setComponentData] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    useEffect(() => {
        if (!loading && !error && data) {
            setComponentData({
                buyerName: data.getHandover.buyer.name === '' ? datastore.data.userName : data.getHandover.buyer.name,
                buyerAddress: data.getHandover.buyer.address === '' ? datastore.data.userAddress : data.getHandover.buyer.address,
                buyerZipcode: data.getHandover.buyer.zipcode === '' ? datastore.data.userZipCode : data.getHandover.buyer.zipcode,
                buyerCity: data.getHandover.buyer.city === '' ? datastore.data.userCity : data.getHandover.buyer.city,
                buyerEmail: data.getHandover.buyer.email === '' ? datastore.data.userEmail : data.getHandover.buyer.email,
                buyerPhone: data.getHandover.buyer.phone === '' ? datastore.data.userPhone : data.getHandover.buyer.phone,
                sellerName: data.getHandover.seller.name,
                sellerAddress: data.getHandover.seller.address,
                sellerZipcode: data.getHandover.seller.zipcode,
                sellerCity: data.getHandover.seller.city,
                sellerEmail: data.getHandover.seller.email,
                sellerPhone: data.getHandover.seller.phone,
                status: data.getHandover.status,
            });
        }
    }, [loading, error, data]);
    if (loading) {
        return React.createElement("p", null);
    }
    if (error) {
        console.log('get data Error:' + error.message);
        return React.createElement("p", null);
    }
    if (componentData == null) {
        return React.createElement("p", null);
    }
    if (updateError) {
        console.log('update Error:' + updateError.message);
    }
    if (updateData) {
        onNext();
    }
    const handleNextClicked = () => {
        setValidate(true);
        let isvalid;
        isvalid = true;
        for (const key of Object.keys(componentData)) {
            const fieldValue = componentData[key];
            if (fieldValue === '') {
                isvalid = false;
            }
        }
        if (isvalid) {
            const updateObj = {
                handoverId: handoverId,
                sellerName: componentData.sellerName,
                sellerAddress: componentData.sellerAddress,
                sellerZipcode: componentData.sellerZipcode,
                sellerCity: componentData.sellerCity,
                sellerEmail: componentData.sellerEmail,
                sellerPhone: componentData.sellerPhone,
                buyerName: componentData.buyerName,
                buyerAddress: componentData.buyerAddress,
                buyerZipcode: componentData.buyerZipcode,
                buyerCity: componentData.buyerCity,
                buyerEmail: componentData.buyerEmail,
                buyerPhone: componentData.buyerPhone,
            };
            setValidate(false);
            updateBuyerSellerInfo({
                variables: {
                    input: updateObj,
                },
            });
        }
        else {
            console.log('Validate - Not all fields are valid');
            forceUpdate();
        }
    };
    const updateField = (fieldName, fieldValue) => {
        const tmp = componentData;
        tmp[fieldName] = fieldValue;
        setComponentData(Object.assign({}, tmp));
    };
    const handleBuyerAddressChanged = (fulladdress, zipcode, city) => {
        if (fulladdress !== '' && zipcode === '') {
            // do nothing, field allready set with zipcode and city
        }
        else {
            const tmp = componentData;
            tmp['buyerAddress'] = fulladdress;
            tmp['buyerZipcode'] = zipcode;
            tmp['buyerCity'] = city;
            setComponentData(Object.assign({}, tmp));
        }
    };
    const handleSellerAddressChanged = (fulladdress, zipcode, city) => {
        if (fulladdress !== '' && zipcode === '') {
            // do nothing, field allready set with zipcode and city
        }
        else {
            const tmp = componentData;
            tmp['sellerAddress'] = fulladdress;
            tmp['sellerZipcode'] = zipcode;
            tmp['sellerCity'] = city;
            setComponentData(Object.assign({}, tmp));
        }
    };
    const dawaFieldBuyerAddressClass = validate && componentData.buyerAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const dawaFieldSellerAddressClass = validate && componentData.sellerAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const isLocked = componentData.status === 'DONE';
    return (React.createElement(Container, { className: 'handoverSellerAndBuyer', paddingTopReset: true, style: { padding: '0', marginBottom: '0px' } },
        React.createElement(React.Fragment, null,
            React.createElement(Row, { className: 'itemContentVideoRow', style: { marginTop: '0px', paddingTop: '0px' } },
                React.createElement(Column, { width: { tablet: 4 }, style: { padding: '0px' } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "K\u00F8bers navn:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.buyerName, className: validate && componentData.buyerName == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('buyerName', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "K\u00F8bers adresse:"),
                            React.createElement(DawaAutocomplete2, { disabled: isLocked, select: (selected) => {
                                    handleBuyerAddressChanged(selected.tekst, selected.data.postnr, selected.data.postnrnavn);
                                }, blur: (address) => {
                                    handleBuyerAddressChanged(address, '', '');
                                }, inputValue: componentData.buyerAddress, classes: {
                                    root: dawaFieldBuyerAddressClass,
                                    input: 'text-field-dawa',
                                    ul: 'ul',
                                    li: 'li',
                                    selected: 'selected',
                                } })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "K\u00F8bers email:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.buyerEmail, className: validate && componentData.buyerEmail == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('buyerEmail', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "K\u00F8bers telefon nr.:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.buyerPhone, className: validate && componentData.buyerPhone == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('buyerPhone', e.target.value) })))),
                React.createElement(Column, { width: { tablet: 4 } },
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "S\u00E6lgers navn:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.sellerName, className: validate && componentData.sellerName == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('sellerName', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "S\u00E6lgers nye adresse:"),
                            React.createElement(DawaAutocomplete2, { disabled: isLocked, select: (selected) => {
                                    handleSellerAddressChanged(selected.tekst, selected.data.postnr, selected.data.postnrnavn);
                                }, blur: (address) => {
                                    handleSellerAddressChanged(address, '', '');
                                }, inputValue: componentData.sellerAddress, classes: {
                                    root: dawaFieldSellerAddressClass,
                                    input: 'text-field-dawa',
                                    ul: 'ul',
                                    li: 'li',
                                    selected: 'selected',
                                } })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "S\u00E6lgers email:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.sellerEmail, className: validate && componentData.sellerEmail == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('sellerEmail', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "S\u00E6lgers telefon nr.:"),
                            React.createElement("input", { disabled: isLocked, type: 'text', defaultValue: componentData.sellerPhone, className: validate && componentData.sellerPhone == '' ? 'fieldinput-error' : 'fieldinput', onChange: (e) => updateField('sellerPhone', e.target.value) }))))),
            React.createElement(Row, null,
                React.createElement(Column, { width: { tablet: 8 }, style: { paddingRight: '38px' }, className: 'linksContainer' },
                    React.createElement("div", { className: 'buttonsGroup', style: { justifyContent: 'end' } },
                        React.createElement(Button, { text: 'Næste', width: 180, grey: true, onClick: handleNextClicked })))))));
};
export default HandoverSellerAndBuyer;
