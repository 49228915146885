import { gql } from '@apollo/client';
const GET_LEADS_GQL = gql `
  query getLeadByCustomerId($id: ID!) {
    getLeadByCustomerId(id: $id) {
      id
      product {
        id
        name
        partnerName
        partnerLogo {
          mediaUrl
        }
        leadId
      }
    }
  }
`;
export { GET_LEADS_GQL };
