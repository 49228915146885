import { gql } from '@apollo/client';
const GET_HANDOVER_GQL = gql `
  query getHandover($id: ID!) {
    getHandover(id: $id) {
      id
      status
      seller {
        name
        address
        zipcode
        city
        email
        phone
      }
      buyer {
        name
        address
        zipcode
        city
        email
        phone
      }
    }
  }
`;
const UPDATE_SELLER_AND_BUYER_INFO_GQL = gql `
  mutation updateSellerAndBuyer($input: UpdateSellerAndBuyerInput!) {
    updateSellerAndBuyer(input: $input)
  }
`;
export { GET_HANDOVER_GQL, UPDATE_SELLER_AND_BUYER_INFO_GQL };
