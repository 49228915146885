import { useRef } from "react";
// Chakra imports
import { Box, Flex, Button, FormControl, FormLabel, Heading, Input, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
//import signInImage from "./../../assets/img/signInImage.png";
import { gql, useMutation } from "@apollo/client";

const FORGOT_PASSWORD_GQL = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

function ForgotPassword() {
  const loginTextBox = useRef(null);
  const [ForgotPassword, { data }] = useMutation(FORGOT_PASSWORD_GQL);

  const handleForgotPasswordClicked = () => {
    if (loginTextBox.current?.value !== '') {
      ForgotPassword({
        errorPolicy: "all",
        variables: {
          email: loginTextBox.current?.value,
        },
      });
    }
  };

  // Chakra color mode
  const titleColor = useColorModeValue("blackAlpha.600", "gray.200");
  
  const textColor = useColorModeValue("blackAlpha.600", "white");

  return (
    <Flex position="relative" mb="40px" >
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }
        }
      >

        <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "45%" }}>
        <div style={{position: 'absolute', top: '30px', left: '50px', fontSize: '30px', color:'#ff614d'}}>Settle</div>
          <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: "150px", lg: "80px" }}>
            {data && data.forgotPassword ? (
              <>
                <Heading color={titleColor} fontSize="32px" mb="10px" >
                  Glemt adgangskode
                </Heading>
                <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
                  Kontrollér din e-mail for at angive din nye adgangskode.
                </Text>
                <Text mb="36px" ms="4px" color={textColor} fontSize="12px">
                  Hvis du ikke modtager en e-mail inden for få minutter, har du enten angivet en e-mailadresse, der ikke svarer til den, der er
                  tilknyttet din konto, eller e-mailen er blevet opfanget af dit spamfilter.
                </Text>

              </>
            ) : (
              <>
                <Heading color={titleColor} fontSize="32px" mb="10px"  >
                  Glemt adgangskode
                </Heading>

                <FormControl >
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal"  color={textColor}>
                    Email
                  </FormLabel>
                  <Input
                    borderRadius="15px"
                    mb="24px"
                    fontSize="sm"
                    type="text"
                    placeholder="Din email adresse"
                    size="lg"
                    name="email"
                    ref={loginTextBox}
                  />

                  <Button
                    onClick={handleForgotPasswordClicked}
                    fontSize="12px"
                    type="submit"
                    bg="blackAlpha.600"
                    w="100%"
                    h="45"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                      bg: "blackAlpha.500",
                    }}
                    _active={{
                      bg: "blackAlpha.600",
                    }}
                  >
                    Glemt adgangskode
                  </Button>
                </FormControl>
              </>
            )
            }

          </Flex>
        </Flex>

        <Box display={{ base: "none", md: "block" }} overflowX="hidden" h="100%" w="40vw" position="absolute" right="0px" >
          <Box bgColor={titleColor} w="100%" h="100%" bgSize="cover" bgPosition="50%" position="absolute" borderBottomLeftRadius="20px" className="loginImage"> </Box>
        </Box>
      </Flex>
    </Flex >
  );
}

export default ForgotPassword;
