// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React, { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataSelectorDates from "./components/DataSelectorDates"
import { useQuery, gql } from "@apollo/client";


const USER_LOGIN_REPORT_GQL = gql`
query userLoginReport($input: UserLoginReportInput){
    userLoginReport(input: $input){
        name
        phone
        email
        hasConsent
        consentText
        createdAt
        takeoverDate
        lastLogin
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Navn",
        accessor: "name",
    },
    {
        header: "Telefon Nr",
        accessor: "phone",
        width: 100
    },
    {
        header: "E-mail",
        accessor: "email",
        width: 100
    },
    {
        header: "Har samtykket",
        accessor: "hasConsent",
        width: 100
    },
    {
        header: "Oprettet",
        accessor: "createdAt",
        width: 100
    },
];


function BillingReportPartners() {
    // Chakra color mode
    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
    });


    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );


    const { loading, error, data } = useQuery(USER_LOGIN_REPORT_GQL, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate
            }
        },
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Bruger login rapport"}
                description={'Bruger rapport. Filteret på dato'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >

                </GridItem>
                
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting BillingReportPartners from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting BillingReportPartners from server (2)</p>);
    }

    const updatefn = (fromDate, toDate) => {
        setrequestData({
            fromDate: fromDate,
            toDate: toDate
        });
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.userLoginReport));
    tableData.forEach((item, index) => {
        item.id = index;
        item.hasConsent = item.hasConsent ? "Ja" : "Nej";
        item.createdAt = item.createdAt.split('T')[0];
        if (item.lastLogin){
            item.lastLogin = item.lastLogin.split('T')[0];
        }else{
            item.lastLogin = "";
        }
    });

    var csvData = tableData.map(item => (
        {
            Navn: item.name,
            TelefonNr: item.phone,
            Email: item.email,
            HarSamtykket: item.hasConsent,
            SamtykkeTekst: item.consentText,
            Oprettet: item.createdAt,
            OvertagelseDato: item.takeoverDate,
            SidsteLogin: item.lastLogin
        }
    ));

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Bruger login rapport"}
                description={'Bruger rapport. Filteret på dato'}
            />

            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                    <DataSelectorDates
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                    />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>
        </Flex>
    );
}

export default BillingReportPartners;