/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import
// To be changed
// import Tables from "views/Dashboard/Tables.js";

import { HomeIcon, StatsIcon, CreditIcon,  DocumentIcon, SupportIcon } from "./components/Icons/Icons";
import Dashboard from "./views/Dashboard/Dashboard";
import EstateBrokers from "./views/Dashboard/EstateBrokers";
import EstateBrokerInfo from "./views/Dashboard/EstateBrokers/Info";
import EstateBrokerOffices from "./views/Dashboard/EstateBrokers/offices";
import EstateBrokerOfficeInfo from "./views/Dashboard/EstateBrokerOffice/Info";
import MovingPlannerCategoriesRenderer from "./views/Dashboard/MovingPlanner/MovingPlannerCategoriesRenderer";
import MovingPlannerCategoryItemsRenderer from "./views/Dashboard/MovingPlanner/MovingPlannerItem/MovingPlannerItemRenderer";
import MovingPlannerItemEdit from "./views/Dashboard/MovingPlanner/MovingPlannerItem/MovingPlannerItemEdit";
import MovingPlannerItemLinkEdit from "./views/Dashboard/MovingPlanner/components/MovingPlannerItemLinkEdit";
import MovingPlannerItemProductCategoryLinkEdit from "./views/Dashboard/MovingPlanner/components/MovingPlannerItemProductCategoryLinkEdit";
import MovingPlannerItemProductCategoryEdit from "./views/Dashboard/MovingPlanner/MovingPlannerItemProductCategory/MovingPlannerItemProductCategoryEdit";
import Reports from "./views/Dashboard/Reports";
import SignIn from "./views/Auth/SignIn.js";
import ForgotPassword from "./views/Auth/ForgotPassword";
import ChangePassword from "./views/Auth/ChangePassword";
import BillingReportRealEstateBrokers from "./views/Dashboard/Reports/billingReportRealEstateBrokers";
import BillingReportRealEstateSystems from "./views/Dashboard/Reports/billingReportRealEstateSystems";
import BillingReportDetailed from "./views/Dashboard/Reports/billingReportDetailed";
import EarningReport from "./views/Dashboard/Reports/earningReport";
import BillingReportPartner from "./views/Dashboard/Reports/billingReportPartners";
import LeadSearch from "./views/Dashboard/Leads";
import LeadInfo from "./views/Dashboard/Leads/info";
import AffiliatePartner from "./views/Dashboard/AffiliatePartner";
import AffiliatePartnerInfo from "./views/Dashboard/AffiliatePartner/Info/index";
import AffiliatePartnerProductInfo from "./views/Dashboard/AffiliatePartner/AffiliatePartnerProduct/index";
import LeadCanceledConfirmation from "./views/Handlers/LeadCanceledConfirmation";
import RejecteLead from "./views/Handlers/RejecteLead";
import ApproveLead from "./views/Handlers/ApproveLead";
import UserLoginReport from "./views/Dashboard/Reports/userLoginReport";

var dashRoutes = [
  {
    path: "/LeadCanceledConfirmation/:leadId",
    name: "LeadCanceledConfirmation",
    rtlName: "LeadCanceledConfirmation",
    icon: <DocumentIcon color="inherit" />,
    component: LeadCanceledConfirmation,
    layout: "/handlers",
    protected: false,
    show: false,
  },
  {
    path: "/ApproveLead/:leadId",
    name: "ApproveLead",
    rtlName: "ApproveLead",
    icon: <DocumentIcon color="inherit" />,
    component: ApproveLead,
    layout: "/handlers",
    protected: false,
    show: false,
  },
  {
    path: "/RejecteLead/:leadId",
    name: "RejecteLead",
    rtlName: "RejecteLead",
    icon: <DocumentIcon color="inherit" />,
    component: RejecteLead,
    layout: "/handlers",
    protected: false,
    show: false,
  },
  {
    path: "/overblik",
    name: "Overblik",
    key: "overblik",
    rtlName: "Overblik",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    path: "/ejendomsmaeglere",
    name: "Ejendomsmæglere",
    key: "ejendomsmaeglere",
    rtlName: "Ejendomsmæglere",
    icon: <StatsIcon color="inherit" />,
    component: EstateBrokers,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "ejendomsmaegler",
    path: "/ejendomsmaegler",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/ejendomsmaegler/:estateBrokerId/info",
        name: "Ejendomsmaegler",
        key: "ejendomsmaegler",
        rtlName: "ejendomsmaegler",
        icon: <StatsIcon color="inherit" />,
        component: EstateBrokerInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/ejendomsmaegler/:estateBrokerId/offices",
        name: "Ejendomsmaegler_butikker",
        key: "ejendomsmaegler_butikker",
        rtlName: "ejendomsmaegler_butikker",
        icon: <StatsIcon color="inherit" />,
        component: EstateBrokerOffices,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/ejendomsmaegler/:estateBrokerId/office/:estateBrokerOfficeId/info",
        name: "Ejendomsmaegler_butik",
        key: "ejendomsmaegler_butik",
        rtlName: "ejendomsmaegler_butik",
        icon: <StatsIcon color="inherit" />,
        component: EstateBrokerOfficeInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
    ],
  },

  {
    path: "/partnere",
    name: "Partnere",
    key: "partnere",
    rtlName: "partnere",
    icon: <StatsIcon color="inherit" />,
    component: AffiliatePartner,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "partner",
    path: "/partner",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/partner/:affiliatePartnerId/info",
        name: "Partner",
        key: "partner",
        rtlName: "partner",
        icon: <StatsIcon color="inherit" />,
        component: AffiliatePartnerInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/partner/:affiliatePartnerId/product/:affiliatePartnerProductId",
        name: "PartnerProduct",
        key: "partnerProduct",
        rtlName: "partnerProduct",
        icon: <StatsIcon color="inherit" />,
        component: AffiliatePartnerProductInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
    ],
  },

  {
    path: "/leads",
    key: "leads",
    name: "Leads",
    rtlName: "Leads",
    icon: <CreditIcon color="inherit" />,
    component: LeadSearch,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    path: "/leadsoffice/:realestatebrokerofficeid",
    key: "leadsfiltered",
    name: "leadsfiltered",
    rtlName: "leadsfiltered",
    icon: <CreditIcon color="inherit" />,
    component: LeadSearch,
    layout: "/admin",
    protected: true,
    show: false,
  },
  {
    name: "lead",
    path: "/lead",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/lead/:leadId/info",
        name: "Lead",
        key: "lead",
        rtlName: "lead",
        icon: <StatsIcon color="inherit" />,
        component: LeadInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
    ],
  },
  {
    path: "/flytteplanners/:realEstateBrokerId",
    key: "flytteplanners",
    name: "Flytte planner",
    rtlName: "Flytte planner",
    icon: <SupportIcon color="inherit" />,
    component: MovingPlannerCategoriesRenderer,
    layout: "/admin",
    protected: true,
    show: false,
  },
  {
    path: "/flytteplanners",
    key: "flytteplanners",
    name: "Flytte planner",
    rtlName: "Flytte planner",
    icon: <SupportIcon color="inherit" />,
    component: MovingPlannerCategoriesRenderer,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "flytteplanner",
    path: "/flytteplanner",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/flytteplanner/:categoryId/punkt/:itemId/productkategory/:productCategoryId",
        name: "ItemProductCategory",
        key: "ItemProductCategory",
        rtlName: "ItemProductCategory",
        icon: <StatsIcon color="inherit" />,
        component: MovingPlannerItemProductCategoryEdit,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/flytteplanner/:categoryId/punkt/:itemId/productCategory/:productCategoryId/link/:productCategoryLinkId",
        name: "ProductCategoryLink",
        key: "ProductCategoryLink",
        rtlName: "ProductCategoryLink",
        icon: <StatsIcon color="inherit" />,
        component: MovingPlannerItemProductCategoryLinkEdit,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/flytteplanner/:categoryId/punkt/:itemId/link/:itemLinkId",
        name: "ItemLink",
        key: "ItemLink",
        rtlName: "ItemLink",
        icon: <StatsIcon color="inherit" />,
        component: MovingPlannerItemLinkEdit,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/flytteplanner/:categoryId/punkt/:itemId",
        name: "CategoryItem",
        key: "CategoryItem",
        rtlName: "CategoryItem",
        icon: <StatsIcon color="inherit" />,
        component: MovingPlannerItemEdit,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/flytteplanner/:categoryId",
        name: "CategoryItems",
        key: "CategoryItems",
        rtlName: "CategoryItems",
        icon: <StatsIcon color="inherit" />,
        component: MovingPlannerCategoryItemsRenderer,
        layout: "/admin",
        protected: true,
        show: true,
      },
    ],
  },

  /*
  {
    path: "/parnere",
    key: "parnere",
    name: "Partnere",
    rtlName: "Partnere",
    icon: <SupportIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
    protected: true,
    show: true
  },

  */
  {
    path: "/rapporter",
    key: "rapporter",
    name: "Rapporter",
    rtlName: "Rapporter",
    icon: <SupportIcon color="inherit" />,
    component: Reports,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "rapport",
    path: "/rapport",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/rapport/fakturering_ejendomsmaeglere",
        name: "Fakturering_ejendomsmaeglere",
        key: "fakturering_ejendomsmaeglere",
        rtlName: "fakturering_ejendomsmaeglere",
        icon: <StatsIcon color="inherit" />,
        component: BillingReportRealEstateBrokers,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/rapport/fakturering_maeglersystem",
        name: "Fakturering_maeglersystemer",
        key: "Fakturering_maeglersystemer",
        rtlName: "Fakturering_maeglersystemer",
        icon: <StatsIcon color="inherit" />,
        component: BillingReportRealEstateSystems,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/rapport/indtjeningsrapport",
        name: "indtjeningsrapport",
        key: "indtjeningsrapport",
        rtlName: "indtjeningsrapport",
        icon: <StatsIcon color="inherit" />,
        component: EarningReport,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/rapport/partnerrapport",
        name: "partnerrapport",
        key: "partnerrapport",
        rtlName: "partnerrapport",
        icon: <StatsIcon color="inherit" />,
        component: BillingReportPartner,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/rapport/detaljeretrapport",
        name: "detaljeretrapport",
        key: "detaljeretrapport",
        rtlName: "detaljeretrapport",
        icon: <StatsIcon color="inherit" />,
        component: BillingReportDetailed,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/rapport/userloginreport",
        name: "userLoginReport",
        key: "userLoginReport",
        rtlName: "userLoginReport",
        icon: <StatsIcon color="inherit" />,
        component: UserLoginReport,
        layout: "/admin",
        protected: true,
        show: true,
      },

      
    ],
  },
  /*
  {
    path: "/indstillinger",
    key: "indstillinger",
    name: "Indstillinger",
    rtlName: "Indstillinger",
    icon: <SupportIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
    protected: true,
    show: true

  },
  */
  {
    path: "/newpassword/:userId/:token",
    name: "NewPassword",
    rtlName: "NewPassword",
    icon: <DocumentIcon color="inherit" />,
    component: ChangePassword,
    layout: "/auth",
    show: false,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    rtlName: "ForgotPassword",
    icon: <DocumentIcon color="inherit" />,
    component: ForgotPassword,
    layout: "/auth",
    show: false,
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "Login",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    show: false,
  },
  /*
  ,{
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    collapse: true,
    items: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        protected: true,
      },
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
  */
];
export default dashRoutes;
