import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  GridItem,
  Select,
  Grid,
  Input,
  Icon
} from "@chakra-ui/react";
import { FaRegPlayCircle } from "react-icons/fa";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import CardHeader from "./../../../components/Card/CardHeader";
import SettleTable from "./../../../components/Tables/SettleTable";
import React from "react";
import { useHistory } from "react-router-dom";

function MovingPlannerCategoriesContainer({
  data,
  selectedRealEstateBroker,
  updateRealEstateBroker,
  createNewCategory,
  deleteCategory,
  updateSorting,
  editCategory,
}) {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: createCategoryIsOpen, onOpen: createCategoryOnOpen, onClose: createCategoryOnClose } = useDisclosure();
  const { isOpen: editCategoryIsOpen, onOpen: editCategoryOnOpen, onClose: editCategoryOnClose } = useDisclosure();
  const cancelRef = React.useRef();
  const customerSelectRef = React.useRef();
  const inpCategoryNameRef = React.useRef(null);
  const inpCategoryDescriptionRef = React.useRef(null);
  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true,
    },
    {
      header: "",
      accessor: "subHeading",
      hidden: true,
    },
    {
      header: "Overskrift",
      accessor: "heading",
      onClick: rowClicked,
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Redigere",
      accessor: "1",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: EditClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: DeleteClicked,
    },
  ];

  const [seletedRow, setSeletedRow] = React.useState("");
  const [seletedRowId, setSeletedIdRow] = React.useState("");
  const [internalData, setData] = React.useState(data.plannerItemsCategories);
  const textColor = useColorModeValue("gray.700", "white");

  const setTableData = (obj) => {
    var tableData = obj(internalData);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    setData(tmptableData);

    updateSorting(tmptableData);
  };

  function EditClicked(obj) {
    setSeletedRow(obj);
    editCategoryOnOpen();
  }

  function DeleteClicked(obj) {
    setSeletedIdRow(obj.id);
    onOpen();
  }

  function CreateCategoryClicked() {
    createCategoryOnOpen();
  }

  const CreateCategory = () => {
    createNewCategory(inpCategoryNameRef.current.value, inpCategoryDescriptionRef.current.value);
    createCategoryOnClose();
  };

  const DeleteCategory = () => {
    deleteCategory(seletedRowId);
    onClose();
  };

  const EditCategory = () => {
    editCategory(seletedRow.id, inpCategoryNameRef.current.value, inpCategoryDescriptionRef.current.value);
    editCategoryOnClose();
  };

  const handleShopPreview = () => {
    let realestateBroker = customerSelectRef.current.value;

    if (realestateBroker === ''){
      realestateBroker = 'default'
    }

    window.open("#plannerpreview/" + realestateBroker + "/xxx");
  };

  function rowClicked(obj) {
    history.push("/admin/flytteplanner/" + obj.id);
  }

  return (
    <div>
      <Flex direction="column" pt={{ sm: "125px", lg: "0px" }}>
        <Card p="16px">
          <Grid templateColumns="repeat(10, 1fr)" width={"100%"} alignItems={"center"}>
            <GridItem colSpan={2}>
              <Text>Vælg mæglerkæde:</Text>
            </GridItem>
            <GridItem colSpan={7}>
              <Select
                multiple={false}
                ref={customerSelectRef}
                value={selectedRealEstateBroker}
                onChange={(e) => {
                  updateRealEstateBroker(e.target.value);
                }}
              >
                <option key={""} value="">
                  Standard planner
                </option>
                {data.realEstateBrokers.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </GridItem>
            <GridItem colSpan={1}>{selectedRealEstateBroker !== "default" && <div style={{textAlign: 'center', cursor:'pointer'}} onClick={() => handleShopPreview()}><Icon as={FaRegPlayCircle} color={'grey'} w={8} h={8} mt={2}  /></div>}</GridItem>
          </Grid>
        </Card>
        <br />

        {selectedRealEstateBroker !== "" && (
          <Card px="0px">
            <CardHeader px="22px" mb="24px" justifyContent="space-between">
              <Flex direction="column">
                <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
                  Kategorier
                </Text>
                <Text color="gray.400" fontSize="sm" fontWeight="normal">
                  Liste over kategorier.
                </Text>
              </Flex>
              <Flex direction="column">
                <Text
                  fontSize="lg"
                  color="gray.400"
                  cursor="pointer"
                  mb="6px"
                  onClick={() => {
                    CreateCategoryClicked();
                  }}
                >
                  Tilføj
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
            {data.plannerItemsCategories.length > 0 ? (
              <SettleTable data={data.plannerItemsCategories} columnsData={columns} setDragData={setTableData} />
              ):(<div style={{width:'100%'}}>
                <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px" style={{textAlign: 'center'}}>
                  Denne kunde benytter standard planneren.<br/>Klik på tilføj for at oprette en planner til kunden.
                </Text>
              </div>)
            }
            </CardBody>
          </Card>
        )}
      </Flex>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet kategorien og alle underpunkter.
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={DeleteCategory} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal onClose={createCategoryOnClose} size="xl" isOpen={createCategoryIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret ny kategori</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprettet en ny kategori, udfyld nedenstående og klik opret.</Text>
            <br />
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Navn:{" "}
                </Text>
                <Input ref={inpCategoryNameRef} variant="flushed" type="text" name="name" />
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Beskrivelse:{" "}
                </Text>
                <Input ref={inpCategoryDescriptionRef} variant="flushed" type="text" name="description" />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={createCategoryOnClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={CreateCategory} ml={3}>
              Opret
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={editCategoryOnClose} size="xl" isOpen={editCategoryIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Redigere kategori</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at redigere en kategori, ret nedenstående og klik opret.</Text>
            <br />
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Navn:{" "}
                </Text>
                <Input ref={inpCategoryNameRef} variant="flushed" type="text" defaultValue={seletedRow.heading} name="name" />
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Beskrivelse:{" "}
                </Text>
                <Input ref={inpCategoryDescriptionRef} variant="flushed" type="text" defaultValue={seletedRow.subHeading} name="description" />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={editCategoryOnClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={EditCategory} ml={3}>
              Ændre
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default MovingPlannerCategoriesContainer;
