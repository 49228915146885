// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React, { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataCard from "./components/DataCard"
import DataSelectorCardPartnerReport from "./components/DataSelectorCardPartnerReport"
import { useQuery, gql } from "@apollo/client";


const BILLING_REPORT_PARTNERS = gql`
query BILLING_REPORT_PARTNERS($input: BillingReportPartnersInput){
    billingReportpartners(input: $input){
        affiliatePartner{
            name
            email
            cvr
            phone
        }
        affiliatePartnerProduct{
            name
        }
        amount
        itemPrice
        totalPrice
    }
    affiliatePartners{
        id
        name
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Partner",
        accessor: "affiliatePartner.name",
    },
    {
        header: "Produkt",
        accessor: "affiliatePartnerProduct.name",
        width: 100
    },
    {
        header: "Antal",
        accessor: "amount",
        width: 100
    },
    {
        header: "pris. pr. stk.",
        accessor: "itemPrice",
        width: 100
    },
    {
        header: "pris total",
        accessor: "totalPrice",
        width: 100
    },
];


function BillingReportPartners() {
    // Chakra color mode
    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
        affiliatePartnerIds: [],
    });

   
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );


    const { loading, error, data } = useQuery(BILLING_REPORT_PARTNERS, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate,
                affiliatePartnerIds: requestData.affiliatePartnerIds
            }
        },
        manual: true,
    });

    if (loading) return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Fakturering af partnere"}
                description={'Fakturering rapport, til partnere. Filteret på dato, produkt og ejendomsmægler kæde'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={3} rowSpan={2} >

                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Total antal produkter'} amount={0} />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Beløb total'} amount={0} />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting BillingReportPartners from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting BillingReportPartners from server (2)</p>);
    }

    const updatefn = (fromDate, toDate, affiliatePartnerSelected) => {
        
        
        let affiliatePartnerIds = [];
        if (!Array.isArray(affiliatePartnerSelected)) {
            if (affiliatePartnerSelected.indexOf(',')) {
                affiliatePartnerIds = affiliatePartnerSelected.split(',')
            } else {
                affiliatePartnerIds.push(affiliatePartnerSelected);
            }
        }

        
        setrequestData({
            fromDate: fromDate,
            toDate: toDate,
            affiliatePartnerIds:affiliatePartnerIds
        });

        //refetch();
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.billingReportpartners));
    let pricesTotal = 0;
    let productsTotal = 0;
    let lastProductName = '';
    for (var i = 0; i < tableData.length; i++) {
        tableData[i].id = i + 1;
        pricesTotal = pricesTotal + tableData[i].totalPrice

        if (tableData[i].affiliatePartner.name !== lastProductName){
            productsTotal = productsTotal + tableData[i].amount;
            lastProductName = tableData[i].affiliatePartner.name;
        }
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    pricesTotal = numberWithCommas(pricesTotal);

    var csvData = tableData.map(item => (
        {
            Partner: item.affiliatePartner.name,
            Email: item.affiliatePartner.email,
            Cvr: item.affiliatePartner.cvr,
            Telefon: item.affiliatePartner.phone,
            produkt: item.affiliatePartnerProduct.name,
            antal: item.amount,
            prisPrStk: item.itemPrice,
            prisIalt: item.totalPrice
        }
    ));

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Fakturering af partnere"}
                description={'Fakturering rapport, til partnere. Filteret på dato, produkt og ejendomsmægler kæde'}
            />

            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={3} rowSpan={2} >
                    <DataSelectorCardPartnerReport
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                        affiliatePartnerSelected={requestData.affiliatePartnerIds.toString()}
                        affiliatePartners={data.affiliatePartners}
                       
                    />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Total antal produkter'} amount={productsTotal} />
                </GridItem>
                <GridItem w='100%'  >
                    <DataCard title={'Beløb total'} amount={pricesTotal} />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>
        </Flex>
    );
}

export default BillingReportPartners;