import React from 'react';
import './productOrderedOverlay.scss';
import Button from '../../../../components/atoms/button/button';
const ProductOrderedOverlay = (props) => {
    const handlecloseClicked = () => {
        props.closeClicked();
    };
    const products = [...props.products];
    let partnerName = props.products[0].partnerName;
    if (products && products.length == 2) {
        partnerName = products[0].partnerName + ' og ' + products[1].partnerName;
    }
    if (products && products.length == 3) {
        partnerName = products[0].partnerName + ', ' + products[1].partnerName + ' og ' + products[2].partnerName;
    }
    if (products && products.length == 4) {
        partnerName =
            products[0].partnerName +
                ', ' +
                products[1].partnerName +
                ', ' +
                products[2].partnerName +
                ' og ' +
                products[3].partnerName;
    }
    return (React.createElement("div", { className: 'productOrderedOverlay' },
        React.createElement("div", { className: 'overlay', onClick: handlecloseClicked }),
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'box' },
                React.createElement("div", { className: 'header' }, "Tak for din bestilling"),
                React.createElement("div", { className: 'description', id: 'consentText' },
                    "Vi har videregivet de n\u00F8dvendige oplysninger til ",
                    partnerName,
                    ". Du vil inden l\u00E6nge blive kontaktet p\u00E5 mail eller telefon. ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    partnerName,
                    " er uafh\u00E6ngige og er selvst\u00E6ndigt ansvarlige for afgivelse af tilbud, kundeservice og levering af de varer og tjenesteydelser, som de tilbyder."),
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'buttons' },
                            React.createElement(Button, { onClick: handlecloseClicked, text: 'Luk', grey: true }))))))));
};
export default ProductOrderedOverlay;
