// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaRegThumbsDown, FaRegThumbsUp,FaTelegramPlane } from "react-icons/fa";


const LeadInfoHeader = ({
  backgroundHeader,
  backgroundProfile,
  name,
  description,
  declineLead,
  acceptLead,
  sentUpdateLead
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300");

  

  return (
    <Box
      mb={{ sm: "205px", md: "75px", xl: "70px" }}
      borderRadius="15px"
      px="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      align="center"
    >
      <Box
        bgImage={backgroundHeader}
        w="100%"
        h="200px"
        borderRadius="25px"
        bgPosition="50%"
        bgRepeat="no-repeat"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx="1.5rem"
          maxH="330px"
          w={{ sm: "90%", xl: "95%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align="center"
          backdropFilter="saturate(200%) blur(50px)"
          position="absolute"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="2px solid"
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p="24px"
          borderRadius="20px"
          transform={{
            sm: "translateY(45%)",
            md: "translateY(70%)",
            lg: "translateY(70%)",
          }}
        >
          <Flex
            align="center"
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}
          >
            
            <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight="bold"
                ms={{ sm: "8px", md: "0px" }}
              >
                {name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight="semibold"
              >
                {description}
              </Text>
            </Flex>




           


          </Flex>
          



          <Flex
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "33%", lg: "auto" }}
          >
            
            


            <Button
              p="0px"
              bg="transparent"
              _hover={{ bg: "none" }}
              variant="transparent-with-icon"
            >
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() =>
                  declineLead()
                }
              >
                <Icon as={FaRegThumbsDown} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Annullere lead
                </Text>
              </Flex>
            </Button>
            
            <Button
              p="0px"
              bg="transparent"
              _hover={{ bg: "none" }}
              variant="transparent-with-icon"
            >
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() =>
                  acceptLead()
                }
              >
                <Icon as={FaRegThumbsUp} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Godkend lead
                </Text>
              </Flex>
            </Button>
          
            <Button
              p="0px"
              bg="transparent"
              _hover={{ bg: "none" }}
              variant="transparent-with-icon"
            >
              <Flex
                align="center"
                w={{ sm: "100%", lg: "135px" }}
                transition="all .5s ease"
                borderRadius="15px"
                justifyContent="center"
                py="10px"
                cursor="pointer"
                onClick={() =>
                  sentUpdateLead()
                }
              >
                <Icon as={FaTelegramPlane} me="6px" />
                <Text fontSize="xs" color={textColor} fontWeight="bold">
                  Gensend lead til partner
                </Text>
              </Flex>
            </Button>
          </Flex>

          



        </Flex>
      </Box>
    </Box>
  );
};

export default LeadInfoHeader;
