/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";


// Assets
import basic from "./../../assets/img/basic-auth.png";
import React from "react";
import { gql, useMutation } from "@apollo/client";

const updateLeadGql = gql`
    mutation setLeadAsRejected($leadId: ID!){
      setLeadAsRejected(leadId:$leadId)
    }`

function RejecteLead() {
  const { leadId } = useParams();
  const [updateLead, { data, loading, error }] = useMutation(updateLeadGql);

  console.log("loading:", loading);
  console.log("data:", data);
  console.log("error:", error);

  if (error) {
    console.log('GraphQL error:', error);
  } else {
    if (!loading && !data) {
      console.log("ok");
      updateLead({ variables: { leadId: leadId } });
    }
  }

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={basic}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
          Tak, for tilbage meldingen. <br /><br />Lead´et er nu registreret som afvist.
        </Text>
      </Flex>
    </Flex>
  );
}

export default RejecteLead;
