import { __rest } from "tslib";
import classNames from 'classnames';
import React, { forwardRef, useMemo } from 'react';
import './column.scss';
const Column = forwardRef((_a, ref) => {
    var { children, className, fullHeight, noMargin, offset, order, marginBottom, width, noPaddingOnMobile } = _a, rest = __rest(_a, ["children", "className", "fullHeight", "noMargin", "offset", "order", "marginBottom", "width", "noPaddingOnMobile"]);
    const getClass = (name, breakpoint) => {
        if (breakpoint) {
            return classNames(typeof breakpoint.mobile === 'number' && `${name}--${breakpoint.mobile}`, typeof breakpoint.tablet === 'number' && `tablet:${name}--${breakpoint.tablet}`, typeof breakpoint.desktop === 'number' && `desktop:${name}--${breakpoint.desktop}`);
        }
        else {
            return '';
        }
    };
    const columnClasses = useMemo(() => {
        return classNames(getClass('component', width), getClass('offset', offset), getClass('order', order), {
            fullHeight,
            marginBottom,
            noMargin,
            noPaddingOnMobile,
        });
    }, [width, offset, order, fullHeight, noMargin, marginBottom, noPaddingOnMobile]);
    return (React.createElement("div", Object.assign({ ref: ref }, rest, { className: classNames('column', columnClasses, className) }), children));
});
Column.displayName = 'Column';
export default Column;
