import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../../../components/Headers/Header";
import ReportCard from "./components/ReportCard";
import { useHistory } from "react-router-dom";
import imageRealEstateBrokers from "./../../../assets/img/ejendomsmaeglere.webp";
import imageRealEstateBrokerSystems from "./../../../assets/img/imageRealEstateBrokerSystems.jpg";
import imageEarningreport from "./../../../assets/img/earningreport.jpg";
import imagePartnerReport from "./../../../assets/img/Partners.png";
import imageDetailedReport from "./../../../assets/img/detailedReport.png";
import userStats from "./../../../assets/img/userStats.png";

function Reports() {
  const history = useHistory();
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Flex direction="column">
      <Header backgroundHeader={ProfileBgImage} backgroundProfile={bgProfile} name={"Rapporter"} />
      <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
        <ReportCard
          title={"Fakturering til ejendomsmæglerne"}
          description={"Fakturering rapport, for ejendomsmæglerne.\nFilteret på dato, produkt og ejendomsmægler kæde"}
          image={imageRealEstateBrokers}
          onclick={() => {
            history.push("rapport/fakturering_ejendomsmaeglere");
          }}
        />
        <ReportCard
          title={"Fakturering til mæglersystemerne"}
          description={"Fakturering rapport, for ejendomsmæglersystemerne. Filteret på dato, produkt og ejendomsmægler kæde"}
          image={imageRealEstateBrokerSystems}
          onclick={() => {
            history.push("rapport/fakturering_maeglersystem");
          }}
        />
        <ReportCard
          title={"Fakturering af partnere"}
          description={"Fakturering rapport, til partnere. Filteret på dato, produkt og ejendomsmægler kæde"}
          image={imagePartnerReport}
          onclick={() => {
            history.push("rapport/partnerrapport");
          }}
        />
        <ReportCard
          title={"Indtjeningsrapport"}
          description={"Filteret på dato, produkt og ejendomsmægler kæde"}
          image={imageEarningreport}
          onclick={() => {
            history.push("rapport/indtjeningsrapport");
          }}
        />

        <ReportCard
          title={"Detaljeret fakturering udtræk"}
          description={"Detaljeret fakturering rapport. Filteret på dato, produkt og ejendomsmægler kæde."}
          image={imageDetailedReport}
          onclick={() => {
            history.push("rapport/detaljeretrapport");
          }}
        />


        <ReportCard
          title={"Bruger login rapport"}
          description={"Bruger rapport. Filteret på dato."}
          image={userStats}
          onclick={() => {
            history.push("rapport/userloginreport");
          }}
        />
      </Grid>
    </Flex>
  );
}

export default Reports;
