// Chakra imports
import { Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
//import CardBody from "components/Card/CardBody";
//import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker"



const DataSelectorDates = ({ updatefn, fromDate, toDate }) => {
  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);

  const updateDates = (dates) => {
    if (dates.length === 2 && dates[1] != null) {
      const fromIsoDate = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate, toIsoDate);
    }
  }

  return (
    <Card p='16px' >
      <Grid templateColumns='repeat(4, 1fr)' gap={6}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e) => { setSelectedDates(e); updateDates(e); }}

            configs={{
              dateFormat: 'yyyy-MM-dd',
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: 'yellow.400',
                  }
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                }
              }
            }}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};

export default DataSelectorDates;