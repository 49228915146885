import { __rest } from "tslib";
import classNames from 'classnames';
import React from 'react';
import './container.scss';
const Container = (_a) => {
    var { children, className, fullWidth, fullWidthMobile, backgroundColor, paddingTopReset, paddingBottomReset, pushTop } = _a, rest = __rest(_a, ["children", "className", "fullWidth", "fullWidthMobile", "backgroundColor", "paddingTopReset", "paddingBottomReset", "pushTop"]);
    return (React.createElement("div", Object.assign({}, rest, { className: classNames('grid-container', className, {
            'background-white': backgroundColor === 'white',
            'full-width': fullWidth,
            'full-width-mobile': fullWidthMobile,
            'padding-bottom-reset': paddingBottomReset,
            'padding-top-reset': paddingTopReset,
            'push-top': pushTop,
        }) }), children));
};
export default Container;
