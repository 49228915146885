import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// Chakra imports
import { Box, Flex, Button, FormControl, Heading, Input, useColorModeValue } from "@chakra-ui/react";
// Assets
//import signInImage from "./../../assets/img/signInImage.png";
import { gql, useMutation } from "@apollo/client";

const CHANGE_PASSWORD_GQL = gql`
  mutation setNewUserPassword($userId: String!, $password: String!) {
    setNewUserPassword(userId: $userId, password: $password)
  }
`;

function ChangePassword() {
  const [showOldLinkError, setShowOldLinkError] = useState(false);
  const [showError, setShowError] = useState(false);
  const password1TextBox = useRef(null);
  const password2TextBox = useRef(null);
  const [ChangePassword, { data, error }] = useMutation(CHANGE_PASSWORD_GQL);
  const history = useHistory();

  let { userId, token } = useParams();

  const handleChangePasswordClicked = async () => {
    const pw1 = password1TextBox.current?.value;
    const pw2 = password2TextBox.current?.value;

    if (pw1 === pw2 && pw1 !== "") {
      try{
      await ChangePassword({
        errorPolicy: "all",
        variables: {
          password: pw1,
          userId: userId,
        },
        context: {
          headers: {
            authorization: "Bearer " + token,
          },
        },
      });
      history.push("/auth/login");
    }catch(e){
      if ((e + '').indexOf('Received status code 401') > -1){
        setShowOldLinkError(true);
      }
    }
    } else {
      setShowError(true);
    }
  };

  // Chakra color mode
  const titleColor = useColorModeValue("blackAlpha.600", "gray.200");
  //const textColor = useColorModeValue("gray.400", "white");
  console.log('error:',error)
  console.log('data:',data)
  if (data && data.setNewPassword) {
    history.push("/auth/login");
  } else {
    return (
      <Flex position="relative" mb="40px" >
        <Flex
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="space-between"
          mb="30px"
          pt={{ sm: "100px", md: "0px" }
          }
        >

          <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "45%" }}>
          <div style={{position: 'absolute', top: '30px', left: '50px', fontSize: '30px', color:'#ff614d'}}>Settle</div>
            <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: "150px", lg: "80px" }}>

              <>
                <Heading color={titleColor} fontSize="32px" mb="10px" >
                  Skift adgangskode
                </Heading>
               
                {showError && <p className="warning">De indtastede adgangskoder matcher ikke - prøv igen.</p>}
                {showOldLinkError ? (<><p className="warning">Dette link er ikke længere gyldigt, adgangskode er ikke ændret.</p><br/>
                <p><a href="/#" >Klik her for at gå til login siden</a></p></>):(
                <FormControl >
                  <Input
                    id="password1"
                    borderRadius="15px"
                    mb="24px"
                    fontSize="sm"
                    type="password"
                    placeholder="Ny adgangskode"
                    size="lg"
                    name="password1"
                    ref={password1TextBox}
                  />
                  <Input
                    id="password2"
                    borderRadius="15px"
                    mb="24px"
                    fontSize="sm"
                    type="password"
                    placeholder="Bekræft adgangskode"
                    size="lg"
                    name="password2"
                    ref={password2TextBox}
                  />

                  <Button
                    onClick={handleChangePasswordClicked}
                    fontSize="12px"
                    type="submit"
                    bg="blackAlpha.600"
                    w="100%"
                    h="45"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                      bg: "blackAlpha.500",
                    }}
                    _active={{
                      bg: "blackAlpha.600",
                    }}
                  >
                    Skift adgangskode
                  </Button>
                </FormControl>
                )}
              </>
            </Flex>
          </Flex>

          <Box display={{ base: "none", md: "block" }} overflowX="hidden" h="100%" w="40vw" position="absolute" right="0px" >
            <Box bgColor={titleColor} w="100%" h="100%" bgSize="cover" bgPosition="50%" position="absolute" borderBottomLeftRadius="20px" className="loginImage" >
              
            </Box>
          </Box>
        </Flex>
      </Flex >
    );
  }
}

export default ChangePassword;
