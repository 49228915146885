/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import CardHeader from "./../../../components/Card/CardHeader";

import SearchTable1 from "./../../../components/Tables/SearchTable1";
import React from "react";
import { useQuery, gql } from "@apollo/client";


const GET_ESTATEBROKERS = gql`
query GET_ESTATEBROKERS{
  realEstateBrokers{
    id
    active
    address{
        fullAddress
    }
    cvr
    email
    name
  }
  }`

  const columns = [
    {
      Header: "",
      accessor: "id",
    },
    {
      Header: "NAVN",
      accessor: "name",
    },
    {
      Header: "ADDRESSE",
      accessor: "address.fullAddress",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "CVR",
      accessor: "cvr",
    }
  ];

function EstateBrokers() {
  const textColor = useColorModeValue("gray.700", "white");
  const { loading, error, data } = useQuery(GET_ESTATEBROKERS, {
    variables: {},
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting stats from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting stats from server (2)</p>);
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="24px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
              Ejendomsmæglere
            </Text>
            <Text color="gray.400" fontSize="sm" fontWeight="normal">
              Liste over ejendomsmægler kæder.
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable1 tableData={data.realEstateBrokers} columnsData={columns} />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default EstateBrokers;
