import { gql } from "@apollo/client";


export const GET_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL = gql`
query GET_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL($id: ID!){
  plannerItemProductCategoryLink(id: $id){
      id
      text
      url
      sorting
      logo {
        id
        filename
        mediaUrl
        heading
        text
      }
  }
}`

export const UPDATE_PLANNER_ITEM_PRODUCT_CATEGORY_LINK_GQL = gql`
mutation updatePlannerItemProductCategoryLink($input: UpdatePlannerItemProductCategoryLinkInput!){
  updatePlannerItemProductCategoryLink(input:$input){
        id
    } 
}`
