// Chakra imports
import { Select, Flex, Grid, GridItem, useColorModeValue, useToast, Input, Text } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React, { useRef } from "react";
import Header from "../components/movingPlannerCategoryItemHeader";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PRODUCT_CATAGORY_GQL, UPDATE_ITEM_GQL } from "./graphQL";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import Editor from "./../../../../components/Editor/Editor";
import MovingplannerItemProductCategoryProducts from "./../components/movingplannerItemProductCategoryProducts";
import MovingPlannerItemProductCategoryMediaUpload from "./MovingPlannerItemProductCategoryMediaUpload";
import MovingPlannerItemProductCategoryIconUpload from "./MovingPlannerItemProductCategoryIconUpload";
import MovingPlannerItemConditions from "./../components/MovingPlannerItemConditions";
import MovingPlannerItemProductCategoryLinks from "./../components/MovingPlannerItemProductCategoryLinks";

function MovingPlannerItemProductCategoryEdit() {
  // Chakra color mode
  const toast = useToast();
  const editorRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { categoryId, itemId, productCategoryId } = useParams();
  const [updatePlannerItemProductCategory] = useMutation(UPDATE_ITEM_GQL, { refetchQueries: [GET_PRODUCT_CATAGORY_GQL] });
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT_CATAGORY_GQL, {
    variables: { id: productCategoryId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting planner item from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting planner item from server (2)</p>;
  }

  const updateData =  async (id, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = id;
    await updatePlannerItemProductCategory({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  var timeout = null;
  const updateText = (text) => {
    clearTimeout(timeout);

    if (text.trim() !== "") {
      timeout = setTimeout(function () {
        updateData(data.plannerItemProductCategory.id, "text", text);
      }, 2000);
    }
  };

  function forceRerender() {
    refetch();
  }

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.plannerItemProductCategory.heading}
        description={"Redigering af produkt kategorien: " + data.plannerItemProductCategory.heading}
        view={"info"}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)", xl: "repeat(1, 1fr)" }} gap="22px">
        <GridItem w="100%" colSpan={0} rowSpan={0}>
          <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Produkt kategorien
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.plannerItemProductCategory.name}
                    onBlur={(e) => updateData(data.plannerItemProductCategory.id, "name", e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Overskrift:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="heading"
                    defaultValue={data.plannerItemProductCategory.heading}
                    onBlur={(e) => updateData(data.plannerItemProductCategory.id, "heading", e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Tekst:{" "}
                  </Text>
                  <Editor value={data.plannerItemProductCategory.text} ref={editorRef} changed={(e) => updateText(e)} />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"160px"}>
                    Tekst på knap:{" "}
                  </Text>
                  <Select
                    multiple={false}
                    defaultValue={data.plannerItemProductCategory.buttonText}
                    onChange={(e) => updateData(data.plannerItemProductCategory.id, "buttonText", e.target.value)}
                  >
                    <option key={"ORDER"} value="ORDER">
                      Bestil
                    </option>
                    <option key={"GET_OFFER"} value="GET_OFFER">
                      Få tilbud
                    </option>
                    <option key={"GET_CONTACTED"} value="GET_CONTACTED">
                      Bliv kontaktet
                    </option>
                  </Select>
                </Flex>
                
              </Flex>
            </CardBody>
          </Card>

          <MovingPlannerItemProductCategoryIconUpload data={data.plannerItemProductCategory} forceRerender={forceRerender} />

          <MovingPlannerItemProductCategoryMediaUpload data={data.plannerItemProductCategory} forceRerender={forceRerender} />

          <MovingPlannerItemConditions
            conditionFields={data.conditionFields}
            conditions={data.plannerItemProductCategory.conditions}
            forceRerender={forceRerender}
            type={"ProductCategory"}
            refId={data.plannerItemProductCategory.id}
          />

          <MovingPlannerItemProductCategoryLinks plannerCategoryId={categoryId} plannerItemId={itemId} plannerItemProductCategoryId={productCategoryId} />

          <MovingplannerItemProductCategoryProducts
            plannerCategoryId={categoryId}
            plannerItemId={itemId}
            plannerItemProductCategoryId={productCategoryId}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default MovingPlannerItemProductCategoryEdit;
