import { __awaiter } from "tslib";
import React, { useState } from 'react';
import './DataValidator.scss';
import DatePicker from '../../../../components/molecules/date-picker/date-picker';
import DawaAutocomplete2 from '../../../../components/molecules/dawa-autocomplete2';
import { useMutation } from '@apollo/client';
import { updateCustomerCaseInfoGql } from './../../graphQL';
import Button from '../../../../components/atoms/button/button';
import { datastore } from '../../../../dataStore';
const DataValidator = (props) => {
    let takeOverDateInit = new Date('1970-01-01');
    if (props.data.realEstateBrokerCases[0].movingDetails.takeoverDate) {
        if (props.data.realEstateBrokerCases[0].movingDetails.takeoverDate !== '1900-01-01T00:00:00.000Z') {
            //console.log('Estate Takeover date set:', props.data.realEstateBrokerCases[0].movingDetails.takeoverDate)
            takeOverDateInit = new Date(props.data.realEstateBrokerCases[0].movingDetails.takeoverDate);
        }
    }
    let moveToAddressInit = '';
    if (props.data.realEstateBrokerCases[0].movingDetails.moveToAddress) {
        moveToAddressInit = props.data.realEstateBrokerCases[0].movingDetails.moveToAddress.fullAddress;
    }
    let currentAddressInit = '';
    if (props.data.realEstateBrokerCases[0].movingDetails.currentAddress) {
        currentAddressInit = props.data.realEstateBrokerCases[0].movingDetails.currentAddress.fullAddress;
    }
    //console.log('moveToAddressInit:', moveToAddressInit)
    const [updateCustomerCaseInfo] = useMutation(updateCustomerCaseInfoGql, {});
    const missingData = [];
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [takeoverDate, setTakeoverDate] = useState(takeOverDateInit);
    const [currentStep, setCurrentStep] = useState(1);
    const [validateStep1, setValidateStep1] = useState(false);
    const [validateStep2, setValidateStep2] = useState(false);
    //const [validateStep3, setValidateStep3] = useState<boolean>(false)
    const [email, setEmail] = useState(props.data.email);
    const [phone, setPhone] = useState(props.data.phone);
    const [name, setName] = useState(props.data.name);
    const [currentAddress, setCurrentAddress] = useState(currentAddressInit);
    const [moveToAddress, setMoveToAddress] = useState(moveToAddressInit);
    if (!props.data.realEstateBrokerCases[0].movingDetails.currentAddress) {
        missingData.push('currentAddress');
        console.log('Missing currentAddress');
    }
    //else {
    //  console.log('currentAddress:', props.data.realEstateBrokerCases[0].movingDetails.currentAddress.fullAddress)
    //setCurrentAddress(props.data.realEstateBrokerCases[0].movingDetails.currentAddress.fullAddress)
    //}
    //missingData.push('moveToAddress')
    if (!props.data.realEstateBrokerCases[0].movingDetails.moveToAddress) {
        missingData.push('moveToAddress');
        console.log('Missing moveToAddress');
    }
    if (!props.data.realEstateBrokerCases[0].movingDetails.takeoverDate) {
        missingData.push('takeoverDate');
        console.log('Missing takeoverDate');
    }
    if (props.data.email === '') {
        missingData.push('email');
        console.log('Missing email');
    }
    if (props.data.phone === '') {
        missingData.push('phone');
        console.log('Missing phone');
    }
    if (props.data.name === '-' || props.data.name === 'NAVNE & ADRESSEBESKYTTET') {
        missingData.push('name');
        console.log('Missing name');
    }
    /*
    if (!props.data.hasConsent) {
      missingData.push('hasConsent')
      console.log('Missing hasConsent')
    }
    */
    if (missingData.length === 0) {
        //do not show this box
        return React.createElement(React.Fragment, null);
    }
    const updateField = (fieldName, value) => {
        switch (fieldName) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };
    const handleDaySelect = (field, date) => {
        if (date) {
            console.log('field:', field);
            setTakeoverDate(new Date(date));
        }
    };
    const handleMoveToAddressChanged = (fulladdress) => {
        setMoveToAddress(fulladdress);
    };
    const handleCurrentAddressChanged = (fulladdress) => {
        setCurrentAddress(fulladdress);
    };
    /*
    const handleCheckBoxClicked = () => {
      setIsConsentChecked(!isConsentChecked)
    }
    */
    const handleNextClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        //console.log('handleNextClicked')
        switch (currentStep) {
            case 1:
                if (currentAddress == '' ||
                    moveToAddress == '' ||
                    takeoverDate.toString() == new Date('1970-01-01').toString() ||
                    name == '' ||
                    name == '-' ||
                    name == 'NAVNE & ADRESSEBESKYTTET') {
                    setValidateStep1(true);
                }
                else {
                    setCurrentStep(currentStep + 1);
                }
                break;
            case 2:
                if (email == '' || email.indexOf('@') == -1 || phone == '') {
                    setValidateStep2(true);
                }
                else {
                    setCurrentStep(currentStep + 1);
                }
                break;
            case 3:
                /*
                if (!isConsentChecked) {
                  setValidateStep3(true)
                } else {
                    setCurrentStep(currentStep + 1)
                }
                */
                setCurrentStep(currentStep + 1);
                break;
            case 4:
                {
                    const inputData = {};
                    inputData.id = props.data.id;
                    inputData.realEstateBrokerCaseId = props.data.realEstateBrokerCases[0].id;
                    inputData.hasConsent = isConsentChecked;
                    inputData.consentText = datastore.data.marketingConsentText;
                    inputData.moveToFullAddress = moveToAddress;
                    inputData.currentFullAddress = currentAddress;
                    inputData.phone = phone;
                    inputData.email = email;
                    inputData.name = name;
                    inputData.takeoverDate = takeoverDate;
                    yield updateCustomerCaseInfo({ variables: { input: inputData } });
                    document.location.reload();
                }
                break;
            default:
                break;
        }
    });
    //const disposalDate = new Date(estateData[selectedEstateNo].movingDetails.dispositionDate)
    //const moveInDate = new Date(estateData[selectedEstateNo].movingDetails.moveInDate)
    //const moveOutDate = new Date(estateData[selectedEstateNo].movingDetails.moveOutDate)
    //const takeOverDate = new Date(props.data.realEstateBrokerCases[0].movingDetails.takeoverDate)
    //select={(selected) => {
    //  setAddress(selected)
    //}}
    //add warnings to inputs (red border)
    //step 1
    const dawaFieldClass = validateStep1 && moveToAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const dawaFieldCurrentAddressClass = validateStep1 && currentAddress == '' ? 'autocomplete-dawa-container-error' : 'autocomplete-dawa-container';
    const takeoverDateValidateError = validateStep1 && takeoverDate.toString() == new Date('1970-01-01').toString() ? true : false;
    const nameFieldClass = validateStep1 && (name == '' || name == '-' || name == 'NAVNE & ADRESSEBESKYTTET')
        ? 'fieldinput-error'
        : 'fieldinput';
    //step 2
    const eMailFieldClass = validateStep2 && (email == '' || email.indexOf('@') == -1) ? 'fieldinput-error' : 'fieldinput';
    const phoneFieldClass = validateStep2 && phone == '' ? 'fieldinput-error' : 'fieldinput';
    //step 3
    //const consentFieldValidateError = '#000'
    /*
    validateStep3 && !isConsentChecked ? 'red' : '#000'
  */
    console.log('name:' + props.data.name + ':');
    console.log('nameFieldClass:' + nameFieldClass + ':');
    return (React.createElement("div", { className: 'dataValidator' },
        React.createElement("div", { className: 'overlay' }),
        React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'box' },
                currentStep === 1 && (React.createElement("div", { id: 'step1' },
                    React.createElement("div", { className: 'status' }, "1 ud af 3 fuldf\u00F8rt"),
                    React.createElement("div", { className: 'header' }, "Vi er der n\u00E6sten.."),
                    React.createElement("div", { className: 'description' }, "Der mangler kun f\u00E5 informationer for at kunne tilpasse flytteplanneren og give dig den bedste oplevelse."),
                    React.createElement("div", { className: 'fields' },
                        (props.data.name == 'NAVNE & ADRESSEBESKYTTET' || props.data.name == '-' || props.data.name == '') && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Dit navn"),
                                React.createElement("input", { type: 'text', className: nameFieldClass, defaultValue: '', onChange: (e) => updateField('name', e.target.value) })),
                            React.createElement("div", { className: 'field' },
                                React.createElement("div", { className: 'fieldHeading' }, "Din nuv\u00E6rende adresse"),
                                React.createElement(DawaAutocomplete2, { select: (selected) => {
                                        handleCurrentAddressChanged(selected.tekst);
                                    }, blur: (address) => {
                                        handleCurrentAddressChanged(address);
                                    }, inputValue: props.data.realEstateBrokerCases[0].movingDetails.currentAddress.fullAddress.replace('-', ''), classes: {
                                        root: dawaFieldCurrentAddressClass,
                                        input: 'text-field-dawa',
                                        ul: 'ul',
                                        li: 'li',
                                        selected: 'selected',
                                    } })))),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Din nye adresse"),
                            React.createElement(DawaAutocomplete2, { select: (selected) => {
                                    handleMoveToAddressChanged(selected.tekst);
                                }, blur: (address) => {
                                    handleMoveToAddressChanged(address);
                                }, inputValue: props.data.realEstateBrokerCases[0].movingDetails.moveToAddress.fullAddress.replace('-', ''), classes: {
                                    root: dawaFieldClass,
                                    input: 'text-field-dawa',
                                    ul: 'ul',
                                    li: 'li',
                                    selected: 'selected',
                                } })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Indflytningsdato"),
                            React.createElement(DatePicker, { selected: takeoverDate, fieldName: 'takeoverDate', onSelect: handleDaySelect, error: takeoverDateValidateError }))),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'buttons' },
                            React.createElement(Button, { onClick: handleNextClicked, text: 'N\u00E6ste' }))))),
                currentStep === 2 && (React.createElement("div", { id: 'step2' },
                    React.createElement("div", { className: 'status' }, "2 ud af 3 fuldf\u00F8rt"),
                    React.createElement("div", { className: 'header' }, "Kontaktoplysninger"),
                    React.createElement("div", { className: 'description' }, "Disse oplysninger anvendes af Settle og udvalgte samarbejdspartnere."),
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Din email"),
                            React.createElement("input", { type: 'text', className: eMailFieldClass, defaultValue: email, onChange: (e) => updateField('email', e.target.value) })),
                        React.createElement("div", { className: 'field' },
                            React.createElement("div", { className: 'fieldHeading' }, "Dit telefonnummer"),
                            React.createElement("input", { type: 'tel', defaultValue: phone, className: phoneFieldClass, onChange: (e) => updateField('phone', e.target.value) }))),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'buttons' },
                            React.createElement(Button, { onClick: handleNextClicked, text: 'N\u00E6ste' }))))),
                currentStep === 3 && (React.createElement("div", { id: 'step3' },
                    React.createElement("div", { className: 'status' }, "3 ud af 3 fuldf\u00F8rt"),
                    React.createElement("div", { className: 'header' }, "Samtykke til markedsf\u00F8ring"),
                    React.createElement("div", { className: 'consentText', id: 'consentText', dangerouslySetInnerHTML: { __html: datastore.data.marketingConsentTextLong } }),
                    React.createElement("div", { className: 'fields' },
                        React.createElement("div", { className: 'bottom' },
                            React.createElement("div", { className: 'buttons' },
                                React.createElement(Button, { onClick: () => {
                                        setIsConsentChecked(false);
                                        handleNextClicked();
                                    }, text: 'N\u00E6ste', grey: true, width: 150 }),
                                React.createElement("div", { style: { width: '10px' } }, "\u00A0"),
                                React.createElement(Button, { onClick: () => {
                                        setIsConsentChecked(true);
                                        handleNextClicked();
                                    }, text: 'Accepter', width: 150 })))))),
                currentStep === 4 && (React.createElement("div", { id: 'step4' },
                    React.createElement("div", { className: 'header' }, "S\u00E5 er du klar!"),
                    React.createElement("div", { className: 'description' },
                        "Din personlige flytteplanner er nu f\u00E6rdig, og du er klar til at f\u00E5 styr p\u00E5 alt i flytteprocessen.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Rigtig god flytning!")),
                    React.createElement("div", { className: 'bottom' },
                        React.createElement("div", { className: 'buttons' },
                            React.createElement(Button, { onClick: handleNextClicked, text: 'Kom i gang', width: 180 })))))))));
};
export default DataValidator;
